import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Input, Popover } from 'antd';
import { useTranslations } from '@veraio/strank';
import { deleteAddress, updateDefaultAddress, updateAddressInstructions } from '@oneecosystem/dealshaker-core';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';
import useError from 'services/errorHandling/useError';
import {
  addressBox,
  addressTitle,
  infoTextWrapper,
  instructionsParagraph,
  actionButtons,
  inputInstructions,
  popoverWrapper,
} from './addressesStyles';

const AddressCard = ({ address }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { TextArea } = Input;
  const { setError } = useError();
  const [deliveryInstructions, setDeliveryInstructions] = useState(address.deliveryInstructions);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const phoneNumber = Number(address.phoneNumber?.substring(address.phoneNumber.indexOf(' ') + 1));

  const handleOk = () => {
    setIsModalVisible(false);
    handleDeleteAddress(address.id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showInstructionsInput = e => {
    const addressElements = Array.from(e.target.parentElement.parentElement.children);
    addressElements.forEach(element => {
      if (element.classList.contains('instructions')) {
        element.classList.add('hide');
        element.classList.remove('show');
      }
      if (element.classList.contains('input-instructions')) {
        element.classList.add('show');
        element.classList.remove('hide');
      }
    });
  };

  const handleInstructionsChange = async (e, addressId) => {
    const addressElements = Array.from(e.target.parentElement.parentElement.children);
    addressElements.forEach(element => {
      if (element.classList.contains('instructions')) {
        element.classList.add('show');
        element.classList.remove('hide');
      }
      if (element.classList.contains('input-instructions')) {
        element.classList.add('hide');
        element.classList.remove('show');
      }
    });

    const err = await updateAddressInstructions(addressId, deliveryInstructions);
    err && setError(err);
  };

  const deleteDeliveryInstructions = async addressId => {
    const err = await updateAddressInstructions(addressId, null);
    err && setError(err);
  };

  const setAddressAsDefault = async id => {
    const err = await updateDefaultAddress(id);
    err && setError(err);
  };

  const handleDeleteAddress = async id => {
    const err = await deleteAddress(id);
    err && setError(err);
  };

  return (
    <>
      <div className={addressBox(theme)}>
        <div className={addressTitle(theme)}>
          <div>
            {address.name}
            {address.isDefault && <label>{getText('default')}</label>}
          </div>
          <Popover
            content={
              <div className="flex-column">
                {!address.isDefault && (
                  <Button type="link" small onClick={() => setAddressAsDefault(address.id)}>
                    {getText('setAsDefault')}
                  </Button>
                )}
                <Button type="link" small linkTo={`/edit-delivery-address/${address.id}`}>
                  {getText('edit')}
                </Button>
                <Button type="link" small onClick={() => setIsModalVisible(true)}>
                  {getText('delete')}
                </Button>
              </div>
            }
            overlayClassName={popoverWrapper(theme)}
            zIndex={900}
            placement="bottomRight"
            trigger="click"
          >
            <FeatherIcon icon="more-vertical" size={15} />
          </Popover>
        </div>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('country')}:</label>
          <br />
          {address.country}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('city')}:</label>
          <br />
          {address.city}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('stateProvince')}:</label>
          <br />
          {address.state}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('postcode')}:</label>
          <br />
          {address.postcode}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('streetAddress')}:</label>
          <br />
          {address.street}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('phoneNumber')}:</label>
          <br />
          {phoneNumber ? `+ ${address.phoneNumber}` : '-'}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('contactPerson')}:</label>
          <br />
          {address.contactPerson}
        </p>
        {address.deliveryInstructions && (
          <div className={`${instructionsParagraph(theme)} instructions`}>
            <Button type="link" small onClick={() => deleteDeliveryInstructions(address.id)}>
              <FeatherIcon icon="x" size={15} />
            </Button>
            {address.deliveryInstructions}
          </div>
        )}
        <div className={`${inputInstructions(theme)} input-instructions`}>
          <TextArea
            autoSize
            type="text"
            placeholder={getText('deliveryInstructions')}
            value={deliveryInstructions}
            onChange={e => setDeliveryInstructions(e.target.value)}
          />
          <Button type="secondary" small onClick={e => handleInstructionsChange(e, address.id)}>
            {getText('save')}
          </Button>
        </div>
        <div className={actionButtons(theme)}>
          {!address.deliveryInstructions && (
            <Button type="link" small onClick={e => showInstructionsInput(e)}>
              {getText('addDeliveryInstructions')}
            </Button>
          )}
          {address.deliveryInstructions && (
            <Button type="link" small onClick={e => showInstructionsInput(e)}>
              {getText('editDeliveryInstructions')}
            </Button>
          )}
        </div>
      </div>
      <ConfirmationPopup
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        text={getText('sureWantRemoveDeliveryAddress')}
      />
    </>
  );
};

AddressCard.propTypes = {
  address: PropTypes.object,
};

export default AddressCard;
