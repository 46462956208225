import apiRoutes from 'config/apiRoutes';
import { getReq, postReq } from 'services/axios/makeRequest';

export const getPromotionsDealsInfo = async setError => {
  const result = await getReq(apiRoutes.PROMOTIONS_DEALS_COUNT);
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const getPromotionInfo = async (dealId, setError) => {
  const result = await getReq(`${apiRoutes.PROMOTIONS_INFO(dealId)}`);
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const promoteDeal = async (payload, setError) => {
  const result = await postReq(apiRoutes.PROMOTE_DEAL, payload);
  result[1] && setError && setError(result[1]);
  return result[2];
};
