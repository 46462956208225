import { css } from 'emotion';

export const inlineInputsContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginRight: '-1em',

  '& .phone-number': {
    width: '100%',
    fontSize: '0.875rem',
    '& .ant-input-number-handler-wrap': { display: 'none' },
    '& .ant-input-number-input': {
      height: '2rem',
      '&::placeholder': {
        fontSize: '0.75rem'
      },
    }
  },

  '& > *': {
    flex: '1 0 10rem',
    margin: '0 1em 1em 0',

    '&.maximum-input': {
      maxWidth: '20rem',
    },

    '&.small-input': {
      flex: '0 0 5rem',
      minWidth: '8rem',
    },

    '& .ant-select': {
      width: '100%',
    },
  },
});
