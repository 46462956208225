import React from 'react';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { findFiatCurrency, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Dropdown, Button } from 'components/UIExternal';
import { promoCodeType } from 'enums';
import { changeStatusOptions, codeStatuses } from 'enums/MerchantOffice';
import NotifictionIcon from 'assets/images/promocode-icon.svg';
import CodeTag from './CodeTag';
import { status, downLoadPdfBtn } from './styles';

export const codeListColumns = (getText, theme, handleCodeStatus, handleGeneratePdf, changeStatus) => [
  {
    title: '',
    dataIndex: 'icon',
    key: 'icon',
    render: () => <img src={NotifictionIcon} alt="notification" />,
  },
  {
    title: 'Created on',
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: e => <div className="bolder-text">{moment(e).format('DD/MM/YYYY · hh:mm')}</div>,
  },
  {
    title: 'Status',
    dataIndex: 'statusId',
    key: 'statusId',
    render: statusId => (
      <div className={`${status(statusId, theme)}`}>
        <span className="dot" />
        <span>{codeStatuses[statusId]}</span>
      </div>
    ),
  },
  {
    title: 'Used By',
    dataIndex: 'code',
    key: 'code',
    render: (_, render) => <CodeTag {...render} />,
  },
  {
    title: 'Used on',
    dataIndex: 'usedOn',
    key: 'usedOn',
    render: usedOn => <div>{usedOn ? moment(usedOn).format('DD/MM/YYYY') : 'N/A'}</div>,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value, render) => {
      const promoCodeCurrency = findFiatCurrency(render.currencyCode) ?? DEFAULT_FIAT_CURRENCY;

      return (
        <div className="bolder-text">
          {value} {render.typeId === promoCodeType.percent ? '%' : promoCodeCurrency.symbol}
        </div>
      );
    },
  },
  {
    title: 'PDF',
    dataIndex: 'id',
    key: 'id',
    render: id => (
      <Button type="secondary" className={downLoadPdfBtn} onClick={() => handleGeneratePdf(id)}>
        <FeatherIcon icon="download" size={18} />
        <span>PDF</span>
      </Button>
    ),
  },
  {
    title: 'Status Action',
    dataIndex: 'statusId',
    key: 'statusId',
    render: (_, render) => (
      <Dropdown
        options={changeStatusOptions}
        disabled={render.statusId === 3}
        placeholder={getText('changeStatus')}
        small
        noClear
        onChange={async e => {
          await handleCodeStatus(Number(e?.value), render.id);
          changeStatus(prev => !prev);
        }}
      />
    ),
  },
];
