import styled from '@emotion/styled';

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  & > div > div {
    display: inline-block;
    font-size: 1.0625rem;
    line-height: 1;
    padding: 0.3125em;
    margin-left: 0.4375em;
    border-radius: 2px;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.primary_orange};
  }
`;
