import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Select, Skeleton } from 'antd';
import InformationalPopover from 'components/popups/InformationalPopover';
import { Button, Pagination, useUrlParams } from 'components/UIExternal';
import { SearchInput, SortDropdown } from 'components/ui';
import { useUser } from 'stores';
import { Routes } from 'enums';
import DealStatus from 'enums/DealStatus';
import BusinessStatus from 'enums/BusinessStatus';
import useError from 'services/errorHandling/useError';
import { getAllBusinessesInfo } from 'services/api/businessesService';
import { getPromotionsDealsInfo } from 'services/api/promotionService';
import { getAvailableCredits } from 'services/api/creditsService';
import { getMerchantDeals, getDealsLeftForBusiness } from 'services/api/dealsService';
import starBoxImage from 'assets/images/addDeal/star-box.svg';
import emptyBoxImage from 'assets/images/noNotifications.svg';
import { mr } from 'assets/css/globalCSS';
import DealCard from './DealCard';
import { merchantOfficeTab } from './styles';
import {
  filtersWrapper,
  availableCreditsBox,
  noCreditsBox,
  promoteTitle,
  creditsLeftWrapper,
  promotedDealsWrapper,
  noVipDealsWrapper,
  noDealsWrapper,
  noDealsLabels,
  noDealsButtonsWrapper,
  dealCardsWrapper,
  searchBarStyles,
  sortByStyles,
} from './dealsStyles';

const { Option } = Select;

const filterKeys = {
  search: 'searchText',
  status: 'statusId',
  business: 'businessId',
  sorting: 'dealsSorting',
};

const MyDealsTab = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const { queryParams } = useUrlParams();
  const { currentAccount } = useUser();
  const [businesses, setBusinesses] = useState();
  const [activeDealsLeft, setActiveDealsLeft] = useState(0);
  const [promotedDeals, setPromotedDeals] = useState();
  const [availableCredits, setAvailableCredits] = useState();
  const [deals, setDeals] = useState(null);
  const paginationRef = useRef();

  useEffect(() => {
    queryParams[filterKeys.business] &&
      getDealsLeftForBusiness(queryParams[filterKeys.business], setError).then(res =>
        setActiveDealsLeft(res === '' ? getText('infinite') : res),
      );
  }, [queryParams[filterKeys.business]]);

  useEffect(() => {
    getPromotionsDealsInfo(setError).then(res => res && setPromotedDeals(res.promotedDealsCount));
    getAvailableCredits(setError).then(res => res && setAvailableCredits(res.credits));
    fetchAllBusinessesInfo();
  }, []);

  const fetchAllBusinessesInfo = async () => {
    const [res, err] = await getAllBusinessesInfo({
      statusIds: [BusinessStatus.Approved.id, BusinessStatus.Edited.id, BusinessStatus.Pending.id],
    });
    err ? setError(err) : setBusinesses(res);
  };

  const fetchDeals = async options => {
    const [res, err] = await getMerchantDeals(currentAccount.id, options);
    err ? setError(err) : setDeals(res);
    return res;
  };

  const dealsContent =
    deals?.items?.length > 0 ? (
      <div className={dealCardsWrapper}>
        {deals.items?.map((deal, idx) => (
          <DealCard
            key={idx}
            deal={deal}
            reloadPage={fetchDeals}
            setPromotedDeals={setPromotedDeals}
            setAvailableCredits={setAvailableCredits}
            activeDealsLeft={activeDealsLeft}
          />
        ))}
      </div>
    ) : (
      <div className={noDealsWrapper}>
        <img src={emptyBoxImage} alt="Empty box" />
        <div className={noDealsLabels(theme)}>
          <label>{getText('haventBoughtDeals')}</label>
          <p>{getText('publishFirstDeal')}</p>
          <div className={noDealsButtonsWrapper}>
            <Button type="primary" small linkTo={Routes.addDealPage} className={mr({ lg: 20, md: 20 })}>
              {getText('addDeal')}
            </Button>
            <Button type="secondary" small linkTo="/">
              {getText('browseDeals')}
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <div className={merchantOfficeTab}>
      <div className={filtersWrapper}>
        <SearchInput
          className={searchBarStyles}
          placeholder={getText('searchForDeals')}
          defaultValue={queryParams[filterKeys.search]}
          onPressEnter={value => paginationRef.current?.applyFilters({ [filterKeys.search]: value })}
        />
        <Select
          allowClear
          placeholder={getText('selectStatus')}
          onChange={val => paginationRef.current?.applyFilters({ [filterKeys.status]: val })}
          value={queryParams[filterKeys.status]}
        >
          <Option value={DealStatus.Approved.id}>{getText('approved')}</Option>
          <Option value={DealStatus.Pending.id}>{getText('pending')}</Option>
          <Option value={DealStatus.Draft.id}>{getText('drafts')}</Option>
          <Option value={DealStatus.Expired.id}>{getText('expired')}</Option>
          <Option value={DealStatus.Declined.id}>{getText('declined')}</Option>
          <Option value={DealStatus.Closed.id}>{getText('closed')}</Option>
          <Option value={DealStatus.Suspended.id}>{getText('suspended')}</Option>
        </Select>
        <Select
          placeholder={getText('selectBusiness')}
          showSearch
          allowClear
          optionFilterProp="children"
          onChange={val => paginationRef.current?.applyFilters({ [filterKeys.business]: val })}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          value={queryParams[filterKeys.business]}
        >
          {businesses?.map((business, idx) => (
            <Option key={idx} value={business.id}>
              {getDynamicTranslation(business.name)?.name}
            </Option>
          ))}
        </Select>
        <SortDropdown
          allowClear
          onSortChange={val => paginationRef.current?.applyFilters({ [filterKeys.sorting]: val })}
          value={queryParams[filterKeys.sorting]}
          inputClass={sortByStyles}
        />
      </div>
      <Row>
        <Col lg={{ span: 5, order: 2 }} span={24}>
          {availableCredits && availableCredits > 0 ? (
            <div className={availableCreditsBox(theme)}>
              <div className={promoteTitle(theme)}>
                <label>{getText('promoteDeal')}</label>
                <InformationalPopover text={getText('useCreditsToPromoteDeal')} />
              </div>
              <div className={creditsLeftWrapper(theme)}>
                <label>{availableCredits}</label>
                <p>{getText('creditsLeft')}</p>
                <Link to={Routes.buyCredits}>{getText('buyMore')}</Link>
              </div>
              <div className={promotedDealsWrapper}>
                <label>{getText('currentlyPromotedDeals')}</label>
                <label>{promotedDeals}</label>
              </div>
            </div>
          ) : (
            <div className={noCreditsBox}>
              <div className={promoteTitle(theme)}>
                <label>{getText('promoteDeal')}</label>
                <InformationalPopover text={getText('promoteDealsAttractVisits')} />
              </div>
              <div className={noVipDealsWrapper(theme)}>
                <img src={starBoxImage} alt="Box with star" />
                <div className="label-link-wrapper">
                  <label>{getText('dontHaveCredits')}</label>
                  <div className="buying-here-wrapper">
                    <Button type="link" small linkTo={Routes.buyCredits}>
                      {getText('buyingHere')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col lg={{ span: 19, order: 1 }} span={24}>
          <Pagination ref={paginationRef} onChange={fetchDeals} pageSize={10} filterKeys={filterKeys} />
          {isNil(deals) ? <Skeleton count={4} width="24%" height={400} /> : dealsContent}
        </Col>
      </Row>
    </div>
  );
};

export default MyDealsTab;
