import React, { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { codeStatusesFilter, dateSorting } from 'enums/MerchantOffice';
import { Dropdown, Button } from 'components/UIExternal';
import { apiRoutes } from 'services/api/promoCodes';
import { getCacheVal } from 'utils/cacheUtils';
import { usePageNumberPaging } from 'services/axios/paging';
import { merchantOfficeTab } from '../components/styles';
import CreatePromoCode from './components/CreatePromoCode';
import PromoCodesList from './components/PromoCodesList';
import { bodyContainer, header } from './styles';

const PromoCodes = () => {
  const { getText } = useTranslations();
  const accountId = getCacheVal('accountId');
  const [newPromoCode, setNewPromoCode] = useState({ newCodeBlock: false, applyCode: false });
  const [changeCodeStatus, setChangeCodeStatus] = useState(false);
  const pageSize = 5;
  const [pageNumber, setPageNumber] = useState(1);
  const { newCodeBlock, applyCode } = newPromoCode;
  const [filter, setFilter] = useState({
    statusId: null,
    PromoCodeSorting: 0,
  });

  const [promocodes, loadNewPage, loadNewFilter, pagingInfo, isLoading] = usePageNumberPaging(
    apiRoutes.GET_PROMO_CODES_LIST(accountId),
    pageSize,
    filter,
    false,
  );

  useEffect(() => {
    loadNewPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    loadNewFilter(filter);
  }, [filter, applyCode, changeCodeStatus]);

  const handleOnChange = (prop, value) =>
    value !== undefined
      ? setFilter(prev => ({ ...prev, [prop]: value }))
      : setFilter(prev => ({ ...prev, [prop]: null }));

  return (
    <div className={merchantOfficeTab}>
      <div className={header}>
        {!newCodeBlock && (
          <>
            <div className="promo-code-filters">
              <Dropdown
                small
                placeholder={getText('filterByStatus')}
                options={codeStatusesFilter(getText)}
                value={filter.statusId}
                onChange={e => handleOnChange('statusId', e?.value)}
                className={{ width: '13rem' }}
              />
              <Dropdown
                small
                placeholder={getText('filterByDate')}
                options={dateSorting(getText)}
                value={filter.PromoCodeSorting}
                onChange={e => handleOnChange('PromoCodeSorting', e?.value)}
                className={{ width: '12rem' }}
              />
            </div>
            <Button type="info" onClick={() => setNewPromoCode(prev => ({ ...prev, newCodeBlock: true }))}>
              {getText('createPromoCode')}
            </Button>
          </>
        )}
      </div>
      {!newCodeBlock && (
        <div className={bodyContainer}>
          <PromoCodesList
            promoCodesList={promocodes}
            pagingInfo={pagingInfo}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            changeStatus={setChangeCodeStatus}
            isLoading={isLoading}
          />
        </div>
      )}
      {newCodeBlock && <CreatePromoCode accountId={accountId} goBack={setNewPromoCode} />}
    </div>
  );
};

export default PromoCodes;
