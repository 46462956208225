export const splitArrayIntoChunksOfLen = (arr, len) => {
  const chunks = [],
    n = arr.length;
  let i = 0;
  while (i < n) chunks.push(arr.slice(i, (i += len)));

  return chunks;
};

export const toggleItemInArray = (array, item) => {
  const foundIndex = array.indexOf(item);
  if (foundIndex === -1) return [...array, item];
  return array.filter((e, i) => i !== foundIndex);
};

export const toggleObjectItemsInArray = (array, items, itemProp) =>
  items
    .map(item => item[itemProp])
    .reduce((accumulator, currentItem) => toggleItemInArray(accumulator, currentItem), array);

export const toggleItemsInArray = (array, items) =>
  items.reduce((accumulator, currentItem) => toggleItemInArray(accumulator, currentItem), array);
