import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { cryptoSign, useLocations, useCurrencies } from '@oneecosystem/dealshaker-core';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { isNumber, isNil } from '@veraio/core';
import { Row, Col, Input, Select, Form, Tag, Checkbox, Modal, Spin, InputNumber } from 'antd';
import { Button, Dropdown, useDeepEffect } from 'components/UIExternal';
import { CountryDropdown } from 'components/ui';
import { InputsWithTranslations } from 'components/shared';
import { useUser } from 'stores';
import {
  Routes,
  businessType,
  businessTypeSelect,
  merchantImageFilePurpose,
  merchantFileType,
  merchantImageFileType,
} from 'enums';
import CryptoProvider from 'enums/CryptoProvider';
import useError from 'services/errorHandling/useError';
import { addNewBusiness, updateBusiness, getSingleBusiness } from 'services';
import { getCountryPhoneCodeOptions } from 'utils';
import { getCacheVal } from 'utils/cacheUtils';
import { blackTextLink, mb } from 'assets/css/globalCSS';
import { inlineInputsContainer } from 'styles';
import UploadFiles from './BusinessAccount/components/UploadFiles';
import {
  titleForm,
  formNote,
  accountFormWrapper,
  formSectionWrapper,
  formSubTitle,
  languagesFormWrapper,
  merchantTypeTags,
  areaInput,
  agreementCheckboxWrapper,
  acceptLabel,
  formButtons,
  mandatoryStar,
  rulesModal,
  merchantBecomeFree,
  formSubtitleInfo,
} from './accountsStyles';

const BusinessAccountForm = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { businessId } = useParams();
  const countries = useLocations(locationsState => locationsState.countries);
  const fiatCurrencies = useCurrencies(currenciesState => currenciesState?.fiatCurrencies);
  const location = useLocation();
  const history = useHistory();
  const { setError } = useError();
  const { CheckableTag } = Tag;
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { updateCurrentAccountBusinesses } = useUser();
  const currentAccountId = getCacheVal('accountId');
  const [selectedTag, setSelectedTag] = useState(businessType.individual);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [isToCModalVisible, setIsToCModalVisible] = useState(false);
  const [isPolicyModalVisible, setIsPolicyModalVisible] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [business, setBusiness] = useState(null);
  const [mediaFiles, setMediaFiles] = useState({ media: null, avatar: null, coverImage: null });
  const [areaState, setAreaState] = useState(null);
  const translationsSectionRef = useRef();
  const phoneCodeRef = useRef(business?.phoneNumber?.split(' ')[0]);

  const phoneCodes = getCountryPhoneCodeOptions(countries);

  useEffect(() => {
    businessId ? fetchSingleBusiness() : setBusiness({});
  }, []);

  useDeepEffect(() => {
    countries && business && !areaState && setAreaState(countries?.find(el => el?.name === business?.country)?.areas);
  }, [countries, business]);

  const fetchSingleBusiness = async () => {
    const [res, err] = await getSingleBusiness(Number(businessId));
    err && setError(err);
    setBusiness(res);
    setSelectedTag(res?.merchantTypeId);
    return res;
  };

  const handleTagChange = (tag, checked) => checked && setSelectedTag(tag);

  const showTocModal = () => {
    setIsToCModalVisible(true);
  };

  const showPolicyModal = () => {
    setIsPolicyModalVisible(true);
  };

  const handleToCCancel = () => {
    setIsToCModalVisible(false);
  };

  const handlePolicyCancel = () => {
    setIsPolicyModalVisible(false);
  };

  const submitForm = async values => {
    const payload = {
      ...values,
      accountId: currentAccountId,
      merchantTypeId: selectedTag,
      areaId: isNumber(values?.areaId) ? values?.areaId : areaState?.find(el => el.areaName === values?.areaId)?.areaId,
      phone: `${values.phoneCode.value} ${values.phone}`,
      phoneCode: values.phoneCode.value,
      updateDate: moment().format(),
      preferredCryptoProviderId:
        (values.bitpayPreferredMethod && CryptoProvider.Bitpay.id) ||
        (values.coinbasePreferredMethod && CryptoProvider.Coinbase.id) ||
        null,
      acceptCryptoPayments: values.cryptoAccepted,
      ...(!isNil(mediaFiles?.media) && { media: mediaFiles.media }),
      // if the user didn`t upload a new avatar or cover image, we will use the existing one
      avatar: !isNil(mediaFiles?.avatar) ? { ...mediaFiles.avatar[0] } : { id: business?.avatar?.id },
      coverImage: !isNil(mediaFiles?.coverImage) ? { ...mediaFiles.coverImage[0] } : { id: business?.coverImage?.id },
    };

    // Wait for validation of all translation tabs and see if there is error or not
    // If there is error quit from this method
    if (translationsSectionRef.current?.validate()) return;

    setDisableAddButton(true);
    const method = businessId ? updateBusiness : addNewBusiness;
    const params = businessId ? { ...payload, id: businessId } : payload;
    const [, err] = await method(params);
    if (err) return setError(err);

    setDisableAddButton(false);
    toast.success(getText('businessSubmitted'));
    await updateCurrentAccountBusinesses();
    history.push(`${Routes.merchantOffice}${Routes.merchantAccounts}`);
  };

  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  const handleCountryChange = countryId => {
    const foundCountryData = countries?.find(country => country.id === countryId);
    const foundPhoneCode = phoneCodes?.find(code => code.value === foundCountryData.phoneCode);

    setAreaState(foundCountryData?.areas);
    phoneCodeRef.current.changeValue(foundPhoneCode);
    form.setFieldsValue({ areaId: null, phoneCode: foundPhoneCode.value });
  };

  return (
    <div className="content-container">
      <h2 className={titleForm(theme)}>
        {businessId ? getText('editMerchantAccount') : getText('addNewMerchantAccount')}
      </h2>
      <div className={formNote}>
        {location?.state?.message ? (
          <p>{getText('alreadyHaveOneBusinessUnderReview')}</p>
        ) : (
          <div>
            <p className={merchantBecomeFree}>{getText('becomingMerchantFree')}</p>
            <p>{getText('countryManagerReviewNotified5Days')}</p>
          </div>
        )}
      </div>

      {!isNil(business) && countries && phoneCodes ? (
        <>
          <Row>
            <Col lg={12} span={24} className={accountFormWrapper}>
              <Form
                form={form}
                onFinish={submitForm}
                layout="vertical"
                initialValues={{
                  ...business,
                  countryId: countries?.find(country => country.name === business.country)?.id,
                  areaId: business.area,
                  phoneCode: phoneCodes?.find(code => Number(code.value) === Number(business.phone?.split(' ')[0])),
                  phone: business.phone?.substring(business.phone.indexOf(' ') + 1),
                }}
              >
                <div className={formSectionWrapper(theme)}>
                  <p className={formSubTitle}>{getText('generalInformation')}</p>
                  <Form.Item name="businessDetails" className={languagesFormWrapper} data-id="businessDetails">
                    <InputsWithTranslations
                      ref={translationsSectionRef}
                      formInstance={form}
                      inputs={[
                        {
                          inputComponent: Input,
                          inputProps: { placeholder: getText('enterMerchantName') },
                          formItemProps: { name: 'name', label: getText('name') },
                        },
                        {
                          inputComponent: Input.TextArea,
                          inputProps: {
                            placeholder: getText('pleaseDescribeYourBusiness'),
                            autoSize: { minRows: 4 },
                          },
                          formItemProps: {
                            name: 'description',
                            label: getText('description'),
                            tooltip: getText('uploadCoverImageInfo'),
                            rules: [
                              { required: true, message: getText('fieldIsRequired') },
                              { max: 40000, message: getText('descriptionShorterThanSymbolsCount', { count: 40000 }) },
                            ],
                          },
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="intendedDeals"
                    label={getText('dealsToRunDealShaker')}
                    rules={[
                      {
                        max: 1000,
                        message: getText('fieldShouldNotExceedCountSymbols', {
                          field: getText('dealsToRunDealShaker'),
                          count: 1000,
                        }),
                      },
                    ]}
                  >
                    <TextArea type="text" placeholder={getText('pleaseDescribeYourDeal')} />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        <span className={mandatoryStar}>*</span>
                        {getText('merchantType')}
                      </span>
                    }
                    name="merchantType"
                    className={merchantTypeTags(theme)}
                  >
                    {businessTypeSelect.map(tag => (
                      <CheckableTag
                        defaultChecked
                        key={tag.value}
                        checked={selectedTag === tag.value}
                        onChange={checked => handleTagChange(tag.value, checked)}
                      >
                        {getText(tag.label)}
                      </CheckableTag>
                    ))}
                  </Form.Item>
                  {selectedTag === businessType.business && (
                    <div className={inlineInputsContainer}>
                      <Form.Item
                        label={getText('registrationNumber')}
                        name="registrationNumber"
                        rules={[
                          mandatoryRule,
                          {
                            max: 22,
                            message: getText('fieldShouldNotExceedCountSymbols', {
                              field: getText('registrationNumber'),
                              count: 22,
                            }),
                          },
                        ]}
                      >
                        <Input type="text" placeholder={getText('enterRegistrationNumber')} />
                      </Form.Item>
                      <Form.Item
                        label={getText('vatNumber')}
                        name="vatNumber"
                        rules={[
                          {
                            max: 15,
                            message: getText('fieldShouldNotExceedCountSymbols', {
                              field: getText('vatNumber'),
                              count: 15,
                            }),
                          },
                          {
                            min: 8,
                            message: getText('fieldLongerThanCountSymbols', { field: getText('vatNumber'), count: 8 }),
                          },
                        ]}
                      >
                        <Input type="text" placeholder={getText('enterVatNumber')} />
                      </Form.Item>
                    </div>
                  )}
                  <Form.Item label={getText('currency')} name="currencyCode" rules={[mandatoryRule]}>
                    <Dropdown
                      noClear
                      placeholder={getText('currency')}
                      options={fiatCurrencies?.map(el => ({ ...el, label: `${el?.code} ${el?.symbol}` }))}
                      uniqueKey="code"
                      mapValue={el => el?.code}
                    />
                  </Form.Item>

                  <Form.Item name="media">
                    <h4 className={formSubTitle}>{getText('uploadFile')}</h4>
                    <p className={formSubtitleInfo(theme)}>{getText('optimalResultsForUpload')}</p>
                    <p className={formSubtitleInfo(theme)}>
                      <b>{getText('approvedFileExtensions')}</b>
                    </p>
                    <UploadFiles
                      hasDescription
                      setMedia={setMediaFiles}
                      mediaFiles={business?.media ?? []}
                      filePurpose={merchantImageFilePurpose.media}
                      allowedFormat={merchantFileType.allowedFormat}
                    />
                  </Form.Item>
                </div>

                <div className={formSectionWrapper(theme)}>
                  <p className={formSubTitle}>{getText('businessAddress')}</p>
                  <div className={inlineInputsContainer}>
                    <Form.Item label={getText('country')} name="countryId" rules={[mandatoryRule]}>
                      <CountryDropdown noClear placeholder={getText('selectCountry')} onChange={handleCountryChange} />
                    </Form.Item>
                    <Form.Item label={getText('stateProvince')} name="state">
                      <Input type="text" placeholder={getText('selectProvince')} />
                    </Form.Item>
                  </div>

                  {!!areaState?.length && (
                    <div className={areaInput}>
                      <Form.Item label={getText('chooseArea')} name="areaId">
                        <Select
                          showSearch
                          defaultValue={business?.area}
                          placeholder={getText('chooseArea')}
                          optionFilterProp="children"
                          onChange={areaId => form.setFieldsValue({ areaId })}
                        >
                          {areaState?.map(area => (
                            <Option key={area.areaId} value={area.areaId}>
                              {area.areaName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <p className="area-description">{getText('chooseAreaInfo')}</p>
                    </div>
                  )}

                  <div className={inlineInputsContainer}>
                    <Form.Item
                      label={getText('city')}
                      name="city"
                      rules={[
                        mandatoryRule,
                        {
                          max: 80,
                          message: getText('fieldShouldNotExceedCountSymbols', { field: getText('city'), count: 64 }),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || !/\d/.test(getFieldValue('city'))) return Promise.resolve();

                            return Promise.reject(new Error(getText('enterValidCity')));
                          },
                        }),
                      ]}
                    >
                      <Input type="text" placeholder={getText('enterCity')} />
                    </Form.Item>
                    <Form.Item label={getText('postcode')} name="postcode" className="small-input">
                      <Input type="text" placeholder={getText('postcode')} />
                    </Form.Item>
                  </div>
                  <div className={inlineInputsContainer}>
                    <Form.Item
                      label={getText('addressLine')}
                      name="street"
                      rules={[
                        mandatoryRule,
                        {
                          max: 400,
                          message: getText('fieldShouldNotExceedCountSymbols', {
                            field: getText('addressLine'),
                            count: 400,
                          }),
                        },
                      ]}
                    >
                      <Input type="text" placeholder={getText('merchantAddress')} />
                    </Form.Item>
                  </div>
                  <label>
                    <span className={mandatoryStar}>*</span>
                    {getText('phoneNumber')}
                  </label>
                  <div className={inlineInputsContainer}>
                    <Form.Item name="phoneCode" rules={[mandatoryRule]} className="small-input">
                      <Dropdown
                        noClear
                        ref={phoneCodeRef}
                        placeholder={getText('phoneCode')}
                        options={phoneCodes}
                        uniqueKey="value"
                        displayKey="label"
                        withSearch
                        renderSelected={selected => selected && `+ ${selected.value}`}
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      rules={[
                        mandatoryRule,
                        {
                          pattern: /^(?:\d{0,32})$/,
                          message: getText('fieldShouldNotExceedCountSymbols', {
                            field: getText('phoneNumber'),
                            count: 32,
                          }),
                        },
                      ]}
                    >
                      <InputNumber className="phone-number" placeholder={getText('enterPhoneNumber')} />
                    </Form.Item>
                  </div>
                  <div className={inlineInputsContainer}>
                    <Form.Item
                      label={getText('emailAddress')}
                      name="email"
                      rules={[
                        mandatoryRule,
                        {
                          max: 64,
                          message: getText('fieldShouldNotExceedCountSymbols', {
                            field: getText('emailAddress'),
                            count: 64,
                          }),
                        },
                        {
                          type: 'email',
                          message: getText('enterValidEmailAddress'),
                        },
                      ]}
                    >
                      <Input type="text" placeholder={getText('enterEmailAddress')} />
                    </Form.Item>
                    <Form.Item label={getText('website')} name="website">
                      <Input type="text" placeholder={getText('enterMerchantWebsite')} />
                    </Form.Item>
                  </div>
                </div>

                <div className={formSectionWrapper(theme)}>
                  <p className={formSubTitle}>{getText('publicProfile')}</p>
                  <Form.Item name="profilePicture">
                    <UploadFiles
                      singleFile
                      setMedia={setMediaFiles}
                      mediaFiles={business?.avatar ? [business.avatar] : []}
                      filePurpose={merchantImageFilePurpose.avatar}
                      allowedFormat={merchantImageFileType.allowedFormat}
                      recommendation="profilePictureRecommendation"
                    />
                  </Form.Item>
                </div>

                <div className={formSectionWrapper(theme)}>
                  <p className={formSubTitle}>{getText('coverImage')}</p>
                  <p className={formSubtitleInfo(theme)}>{getText('uploadCoverImageInfo')}</p>
                  <Form.Item name="coverImage">
                    <UploadFiles
                      singleFile
                      setMedia={setMediaFiles}
                      mediaFiles={business?.coverImage ? [business.coverImage] : []}
                      filePurpose={merchantImageFilePurpose.coverImage}
                      allowedFormat={merchantImageFileType.allowedFormat}
                      recommendation="coverPictureRecommendation"
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className={agreementCheckboxWrapper(theme)}>
                <Checkbox onChange={e => setTermsAccepted(e.target.checked)} />
                <p>{getText('iAcceptDealshaker')}</p>
                <Button type="link" small className={rulesModal} onClick={showTocModal}>
                  {getText('termsAndConditions')}
                </Button>
                <Modal
                  title={getText('termsAndConditions')}
                  visible={isToCModalVisible}
                  onOk={handleToCCancel}
                  onCancel={handleToCCancel}
                >
                  <p>{getText('termsAndConditionsText', { isCrypto: cryptoSign() })}</p>
                </Modal>
              </div>
              <div className={agreementCheckboxWrapper(theme)}>
                <Checkbox onChange={e => setPolicyAccepted(e.target.checked)} />
                <p>{getText('iAcceptDealshaker')}</p>
                <Button type="link" small className={rulesModal} onClick={showPolicyModal}>
                  {getText('privacyPolicy')}
                </Button>
                <Modal
                  title={getText('privacyPolicy')}
                  visible={isPolicyModalVisible}
                  onOk={handlePolicyCancel}
                  onCancel={handlePolicyCancel}
                >
                  <p>{getText('privacyPolicyText')}</p>
                </Modal>
              </div>
              {(!policyAccepted || !termsAccepted) && (
                <label className={acceptLabel(theme)}>{getText('acceptTocPrivacyPolicy')}</label>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12} span={24} className={formButtons}>
              <Button type="link" small className={blackTextLink(theme)} onClick={() => history.goBack()}>
                {getText('goBack')}
              </Button>
              <Button
                type="primary"
                small
                disabled={!termsAccepted || !policyAccepted || disableAddButton}
                onClick={() => form.submit()}
              >
                {businessId ? getText('editMerchantAccount') : getText('addNewMerchantAccount')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default BusinessAccountForm;
