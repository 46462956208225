import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import FeatherIcon from 'feather-icons-react';
import { Dropdown, Button } from 'components/UIExternal';
import PromoteDealBox from 'components/PromoteDealBox';
import { useUser } from 'stores';
import { materials } from 'enums/BrandMaterials';
import DealStatus from 'enums/DealStatus';
import { selectAllOption } from 'utils';
import useError from 'services/errorHandling/useError';
import { getDeals } from 'services/api/brandMaterialService';
import GalleryMaterials from './slider';
import { selectTemplate } from '../materialsTemplates';
import {
  wrapper,
  imgContainer,
  mainImgStyles,
  mainImgDownLoadBtn,
  free,
  materialTitle,
  materialSubtitle,
  dropDownBusiness,
  bodyContainer,
  actionContainer,
  imageContainer,
  selectSection,
  headerSection,
} from './styles';

const Material = () => {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const { getText, getDynamicTranslation } = useTranslations();
  const { currentAccountBusinesses } = useUser();
  const { setError } = useError();
  const [qrCode, setQrCode] = useState({ src: null });
  const [selectedBusiness, setSelectedBusiness] = useState(location?.state?.business ?? null);
  const [businessInfo, setBusinessInfo] = useState();
  const [material, setMaterial] = useState(materials.find(x => x.id === Number(params.id)));
  const [labelsDeals, setLabelsDeals] = useState({
    list: location?.state?.deals ?? [],
    selectedDeals: [],
  });
  const businessOptions = currentAccountBusinesses.map(el => ({
    value: el.id,
    label: getDynamicTranslation(el.name)?.name?.currentValue,
  }));

  useEffect(() => {
    generateQR(`${window.location.host}/merchant/${selectedBusiness?.value}/overview`);
    setBusinessInfo(
      currentAccountBusinesses
        .map(el => ({ ...el, name: getDynamicTranslation(el.name)?.name }))
        .find(business => business.id === selectedBusiness?.value),
    );
  }, [selectedBusiness]);

  const changeBusiness = async business => {
    if (isNil(business)) {
      setSelectedBusiness(null);
      return;
    }

    setLabelsDeals(prev => ({ ...prev, selectedDeals: [] }));
    const [res, err] = await getDeals({
      businessId: business?.value,
      dealsSorting: 0,
      pageSize: 100,
    });

    err && setError(err);

    setSelectedBusiness(business);
    setLabelsDeals(prev => ({
      ...prev,
      list: [
        { title: 'All', id: 0 },
        ...res?.items
          ?.filter(deal => deal.businessId === business?.value && deal.statusId === DealStatus.Approved.id)
          .map(el => ({
            ...el,
            title: getDynamicTranslation(el.dealDetails)?.title,
          })),
      ],
    }));
  };

  const handleLabelDeal = currentSelection =>
    setLabelsDeals(prev => ({
      ...prev,
      selectedDeals: selectAllOption(prev.list, prev.selectedDeals, currentSelection),
    }));

  const generateQR = async text => {
    const res = await QRCode.toDataURL(text);
    res && setQrCode(prev => ({ ...prev, src: res }));
  };

  const downloadImg = async () => {
    const image = await fetch(material.imgOriginal);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = material.title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={wrapper}>
      <div className={headerSection}>
        <p className={free(theme)}>{material?.isFree && getText('free')}</p>
        <h1 className={materialTitle}>{getText(material?.title)}</h1>
        <p className={materialSubtitle(theme)}>
          {getText('theFileIsInFormatSize', { format: 'pdf', size: material?.sizeInfo })}
        </p>
      </div>

      <div className={bodyContainer}>
        <div className={imageContainer}>
          <div className={imgContainer}>
            <img className={mainImgStyles} src={material?.imgReduced} alt="" />
            <div className={`${mainImgDownLoadBtn(theme)} overlay`}>
              <Button type="link" small onClick={downloadImg}>
                <FeatherIcon icon="download" size={20} />
                {getText('downloadImage')}
              </Button>
            </div>
          </div>
          <GalleryMaterials materialGallery={material?.gallery ?? []} changeMainImage={setMaterial} />
        </div>
        <div className={actionContainer}>
          <PromoteDealBox />
          <div className={selectSection}>
            {material.merchantInfo && (
              <Dropdown
                onChange={changeBusiness}
                className={dropDownBusiness}
                placeholder={getText('selectBusiness')}
                value={selectedBusiness?.value}
                options={businessOptions}
              />
            )}
            {material.businessInfo && (
              <Dropdown
                onChange={handleLabelDeal}
                className={dropDownBusiness}
                disabled={!selectedBusiness || labelsDeals?.list?.length <= 1}
                multiSelect
                displayKey="title"
                uniqueKey="id"
                placeholder={getText('selectDeal')}
                value={labelsDeals.selectedDeals}
                options={labelsDeals?.list}
              />
            )}

            <PDFDownloadLink
              document={selectTemplate(material?.id, qrCode.src, businessInfo, labelsDeals.selectedDeals)}
              fileName="brand-material"
            >
              {() => (
                <Button
                  type="secondary"
                  fullWidth
                  {...(material.merchantInfo && { disabled: isNil(selectedBusiness) })}
                >
                  {getText('download')}
                </Button>
              )}
            </PDFDownloadLink>
            <div className="font-download">
              <span>{getText('fontWorkRoboto')}</span>
              <Button type="link" small linkTo="https://fonts.google.com/share?selection.family=Roboto:wght@500;700">
                {getText('downloadHere')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Material;
