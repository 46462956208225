import { css } from 'emotion';

export const table = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
  });

export const codeValue = css({
  '& .ant-btn': {
    border: 'none',
  },
});

export const status = (statusId, theme) => {
  const statusColor = {
    1: theme.green2,
    2: theme.red,
    3: theme.dark_gray,
  };

  return css({
    '& .dot': {
      marginRight: '0.4375em',
      marginBottom: '0.1875em',
      borderRadius: '5px',
      height: '0.3125rem',
      width: '0.3125rem',
      display: 'inline-block',
      background: statusColor[statusId],
    },
  });
};

export const usedByCode = css({
  '& p': {
    margin: 0,
    padding: 0,
  },
  '& div svg': {
    margin: '0.3125em 0 0 0.3125em',
  },
});

export const downLoadPdfBtn = css({
  fontSize: '0.8125rem',
  padding: '0 0.625em',
  minHeight: '1.875rem',
  '& span': {
    marginLeft: '0.3125em',
  },
});
