import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isFunction } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Popover, Switch } from 'antd';
import { toast } from 'react-hot-toast';
import { Button } from 'components/UIExternal';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';
import { useUser } from 'stores';
import { Routes, businessTypeInverse } from 'enums';
import BusinessStatus from 'enums/BusinessStatus';
import useError from 'services/errorHandling/useError';
import { formatDescriptiveDateTime } from 'utils/valueFormatter';
import { deleteBusiness, resetBusinessBin, getBusinessOverview } from 'services/api/businessesService';
import merchantDefaultAvatar from 'assets/images/merchant-no-avatar.svg';
import publicMerchantProfileCover from 'assets/images/defaultAvatar-business-card.svg';
import MerchantQRCodeBlock from './MerchantQR';
import { popoverWrapper, versionSwitchWrapper, profileImage, coverImage, previewButton } from './styles';
import {
  accountBox,
  accountTitle,
  statusBinWrapper,
  messageWrapper,
  commentMessage,
  infoTextWrapper,
  email,
  accountPopover,
  more,
} from './accountsStyles';

const BusinessAccountCard = ({ account, reloadAllLoadedPages }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const { updateCurrentAccountBusinesses, currentAccountBusinesses } = useUser();
  const businessStatusName = BusinessStatus.getById(account.status)?.value;
  const history = useHistory();
  const [activeDealsCount, setActiveDealsCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBinModalVisible, setIsBinModalVisible] = useState(false);
  const [isActiveVersion, setIsActiveVersion] = useState(true);

  const handleOk = async () => {
    setIsModalVisible(false);
    const [, err] = await deleteBusiness(account.id);
    if (err) setError(err);
    toast.success(getText('businessSubmitted'));
    await updateCurrentAccountBusinesses();
    isFunction(reloadAllLoadedPages) && reloadAllLoadedPages();
    currentAccountBusinesses.length === 1 && history.push('/');
  };

  const handleCancel = () => setIsModalVisible(false);

  const handleBinOk = async () => {
    setIsBinModalVisible(false);
    const [, err] = await resetBusinessBin(account.id);
    if (err) return setError(err);
    isFunction(reloadAllLoadedPages) && reloadAllLoadedPages();
  };

  const handleBinCancel = () => setIsBinModalVisible(false);

  const handleDelete = async () => {
    setIsModalVisible(true);
    updateCurrentAccountBusinesses();
    const [res, err] = await getBusinessOverview(account.id);
    err ? setError(err) : setActiveDealsCount(res.activeDealsCount);
  };

  const accountDescription = getDynamicTranslation(account.description)?.description?.currentValue;
  const accountName = getDynamicTranslation(account.name)?.name?.currentValue;
  const accountDescriptionPending = getDynamicTranslation(account.description)?.description?.pendingValue;
  const accountNamePending = getDynamicTranslation(account.name)?.name?.pendingValue;

  const generalTabContent = (
    <>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('description')}:</label>
        <br />
        {accountDescription}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('merchantType')}:</label>
        <br />
        {getText(businessTypeInverse[account.merchantTypeId?.currentValue])}
      </p>
      <p title={account.email?.currentValue} className={`${infoTextWrapper(theme)} ${email}`}>
        <label>{getText('businessEmail')}:</label>
        <br />
        {account.email?.currentValue || ''}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('country')}:</label>
        <br />
        {account.country?.currentValue || ''}
      </p>
      {account.area?.currentValue && (
        <p className={infoTextWrapper(theme)}>
          <label>{getText('area')}:</label>
          <br />
          {account.area?.currentValue}
        </p>
      )}
      <p className={infoTextWrapper(theme)}>
        <label>{getText('city')}:</label>
        <br />
        {account.city?.currentValue || ''}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('streetAddress')}:</label>
        <br />
        {account.street?.currentValue || ''}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('phoneNumber')}:</label>
        <br />
        {account.phone?.currentValue ? `+${account.phone?.currentValue}` : ''}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('currency')}:</label>
        <br />
        {account.currencyCode?.currentValue || ''}
      </p>
      <MerchantQRCodeBlock
        link={`${window.location.host}/merchant/${account.id}/overview`}
        businessName={accountName}
      />
      <p className={infoTextWrapper(theme)}>
        <label>{getText('website')}:</label>
        <br />
        {account.website?.currentValue || ''}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('dealsToRunDealShaker')}:</label>
        <br />
        {account.intendedDeals?.currentValue || ''}
      </p>

      <p className={infoTextWrapper(theme)}>
        <label>{getText('profileImage')}:</label>
        <br />
        <img src={account.avatar?.url ?? merchantDefaultAvatar} alt="profile" className={profileImage} />
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('coverImage')}:</label>
        <br />
        <img src={account.coverImage?.url ?? publicMerchantProfileCover} alt="cover" className={coverImage} />
      </p>
      <Button fullWidth small type="info" linkTo={`/merchant/${account.id}`} className={previewButton}>
        {getText('previewPublicProfile')}
      </Button>
    </>
  );

  const generalTabContentPending = (
    <>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('description')}:</label>
        <br />
        {accountDescriptionPending || accountDescription}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('merchantType')}:</label>
        <br />
        {getText(businessTypeInverse[account.merchantTypeId?.pendingValue ?? account.merchantTypeId.currentValue])}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('businessEmail')}:</label>
        <br />
        {account.email?.pendingValue ? account.email?.pendingValue : account.email?.currentValue}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('country')}:</label>
        <br />
        {account.country?.pendingValue ? account.country?.pendingValue : account.country?.currentValue}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('provinceCity')}:</label>
        <br />
        {account.city?.pendingValue ? account.city?.pendingValue : account.city?.currentValue}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('streetAddress')}:</label>
        <br />
        {account.street?.pendingValue ? account.street?.pendingValue : account.street?.currentValue}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('phoneNumber')}:</label>
        <br />+ {account.phone?.pendingValue ? account.phone?.pendingValue : account.phone?.currentValue}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('currency')}:</label>
        <br />
        {account.currencyCode?.pendingValue || ''}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('website')}:</label>
        <br />
        {account.website?.pendingValue ? account.website?.pendingValue : account.website?.currentValue}
      </p>
      <p className={infoTextWrapper(theme)}>
        <label>{getText('dealsToRunDealShaker')}:</label>
        <br />
        {account.intendedDeals?.pendingValue
          ? account.intendedDeals?.pendingValue
          : account.intendedDeals?.currentValue}
      </p>
    </>
  );

  return (
    <>
      <div className={accountBox(theme)}>
        <div className={accountTitle(theme)}>
          <div>{!isActiveVersion && accountNamePending ? accountNamePending : accountName}</div>
          <Popover
            content={
              <div className="flex-column">
                <Button type="link" small className={accountPopover(theme)} onClick={() => setIsBinModalVisible(true)}>
                  {getText('resetBin')}
                </Button>
                <Button
                  type="link"
                  small
                  className={accountPopover(theme)}
                  linkTo={{
                    pathname: Routes.bankDetails,
                    account,
                  }}
                >
                  {getText('bankDetails')}
                </Button>
                <Button
                  type="link"
                  small
                  className={accountPopover(theme)}
                  linkTo={`/edit-merchant-account/${account.id}`}
                >
                  {getText('edit')}
                </Button>
                <Button type="link" small className={accountPopover(theme)} onClick={handleDelete}>
                  {getText('delete')}
                </Button>
              </div>
            }
            overlayClassName={popoverWrapper(theme)}
            zIndex={900}
            placement="bottomRight"
            trigger="click"
          >
            <FeatherIcon icon="more-vertical" className={more(theme)} size={30} />
          </Popover>
        </div>
        <div className={statusBinWrapper(theme)}>
          <p>
            {`${getText('status')}: ${businessStatusName}`}
            {businessStatusName === 'Approved' ? <span className="icon-Tick" /> : ''}
          </p>
          <p>{`${getText('bin')}: ${account.bin ? account.bin : 'N/A'}`}</p>
        </div>
        {account.status === BusinessStatus.Edited.id && (
          <div className={versionSwitchWrapper(theme)}>
            <label>{getText('showActiveVersion')}</label>
            <Switch
              size="small"
              onChange={checked => {
                checked ? setIsActiveVersion(false) : setIsActiveVersion(true);
              }}
            />
            <label>{getText('showVersionUnderRevision')}</label>
          </div>
        )}
        {account.countryManagerComment && (
          <div className={messageWrapper(theme)}>
            <p>
              <span className="icon-Info" size={15} />
              {`${getText('updatedOnBy', {
                date: formatDescriptiveDateTime(account.countryManagerUpdateDate),
                by: account.countryManagerUsername,
              })}`}
            </p>
            <Button type="link" small onClick={() => setShowMessage(prev => !prev)}>
              {showMessage ? getText('hideMessage') : getText('viewMessage')}
            </Button>
            {showMessage && <p className={commentMessage}>{account.countryManagerComment}</p>}
          </div>
        )}
        {isActiveVersion ? generalTabContent : generalTabContentPending}
      </div>
      <ConfirmationPopup
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        confirmDisabled={!!activeDealsCount}
        text={
          activeDealsCount === 0
            ? getText('youCurrentlyHaveActiveDeals', { activeDealsCount })
            : getText('sureRemoveThisBusiness')
        }
      />
      <ConfirmationPopup
        isModalVisible={isBinModalVisible}
        handleOk={handleBinOk}
        handleCancel={handleBinCancel}
        text={getText('sureResetBin')}
        btnText="reset"
      />
    </>
  );
};

BusinessAccountCard.propTypes = {
  account: PropTypes.object,
  reloadAllLoadedPages: PropTypes.func,
};

export default BusinessAccountCard;
