import envConfig from './envConfig';

const address = envConfig.apiAddress;
const messagingCenterAddress = envConfig.messagingCenterApi;
export const basicUser = 'dGVzdFVzZXIyMDIx';
export const basicSecret = 'RGVhbFNoYWtlcjEyM0A=';

export default {
  TRANSLATIONS: `${address}/api/cms/translations`,
  SAVE_LISTING_FILTER: `${address}/api/SavedFilters/Create`,
  SAVED_FILTERS_GET: `${address}/api/SavedFilters/Get`,
  SAVED_FILTERS_DELETE: id => `${address}/api/SavedFilters/Delete/${id}`,
  DEALS_LIST: `${address}/api/Deals/List`,
  DEALS_LIST_PRICE_RANGES: `${address}/api/Deals/GetPriceRanges`,
  DEALS_LEFT_BUSINESS: id => `${address}/api/Deals/GetBusinessDealsLeft/${id}`,
  SEND_TO_EMAIL_COUPON: id => `${address}/api/PdfGenerator/SendToEmail/${id}`,
  GET_REFUND_REQUEST: couponId => `${address}/api/Refunds/GetRefundRequest/${couponId}`,
  CREATE_REFUND: `${address}/api/Refunds/Create`,
  CREDITS_PACKS_LIST: `${address}/api/CreditPacks/List`,
  CREDITS_AVAILABLE: `${address}/api/Accounts/GetAvailableCredits`,
  CREDITS_BUY: `${address}/api/CreditPurchases/Buy`,
  PROMOTIONS_DEALS_COUNT: `${address}/api/Promotions/GetPromotedDealsCount`,
  PROMOTIONS_INFO: dealId => `${address}/api/Promotions/GetPromotionInfo/${dealId}`,
  PROMOTE_DEAL: `${address}/api/Promotions/PromoteDeal`,
  MEDIA_ADD_DEAL_IMAGE: dealId => `${address}/api/Media/AddImage/${dealId}`,
  MEDIA_ADD_IMAGE: `${address}/api/Media/AddImage`,
  MEDIA_ADD_VIDEO: dealId => `${address}/api/Media/AddVideo/${dealId}`,
  MEDIA_ADD_YOUTUBE_VIDEO: dealId => `${address}/api/Media/AddYoutubeVideo/${dealId}`,
  CHAT_LIST_CHATS: `${messagingCenterAddress}/api/messaging-center/Chat/ListChats`,
  CHAT_LIST_GROUP_CHATS: `${messagingCenterAddress}/api/messaging-center/Chat/ListGroupChats`,
  CHAT_LIST_MODERATOR_CHATS: `${messagingCenterAddress}/api/messaging-center/Chat/ListModeratorChats`,
  CHAT_GET: chatId => `${messagingCenterAddress}/api/messaging-center/Chat/Get/${chatId}`,
  CHAT_GET_ALL_UNREAD: `${messagingCenterAddress}/api/messaging-center/Chat/AllUnread`,
  CHAT_GET_METADATA: chatId => `${messagingCenterAddress}/api/messaging-center/Chat/GetMetadata/${chatId}`,
  CHAT_GET_METADATA_FOR_USER_AND_DEAL: `${messagingCenterAddress}/api/messaging-center/Chat/GetMetadata`,
  CHAT_GET_MODERATOR: `${messagingCenterAddress}/api/messaging-center/Chat/GetModerator`,
  CHAT_MARK_AS_ARCHIVED: chatId => `${messagingCenterAddress}/api/messaging-center/Chat/MarkAsArchived/${chatId}`,
  CHAT_START: `${messagingCenterAddress}/api/messaging-center/Chat/StartChat`,
  CHAT_START_WITH_MESSAGE: `${messagingCenterAddress}/api/messaging-center/Chat/StartChatWithMessage`,
  CHAT_ADD_PARTICIPANT: `${messagingCenterAddress}/api/messaging-center/Chat/AddParticipant`,
  CHAT_RENAME: `${messagingCenterAddress}/api/messaging-center/Chat/Rename`,
  CHAT_SEND_MESSAGE: `${messagingCenterAddress}/api/messaging-center/Chat/SendMessage`,
  CHAT_SEARCH_USERS: usernameText =>
    `${messagingCenterAddress}/api/messaging-center/Chat/SearchUsers?usernameText=${usernameText}`,
  CHAT_LEAVE: chatId => `${messagingCenterAddress}/api/messaging-center/Chat/LeaveChat/${chatId}`,
};
