import { css } from 'emotion';
import styled from '@emotion/styled';

export const popup = theme =>
  css({
    '& .ant-modal-footer': {
      border: 'none',
      display: 'flex',
      paddingLeft: '1.25em',
      paddingBottom: '1.25em',
    },
    '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)': {
      backgroundColor: theme.gray600,
      color: theme.gray100,
      border: 'none',
    },
    '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover': {
      backgroundColor: theme.gray600,
      color: theme.gray100,
    },
    '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active': {
      backgroundColor: theme.gray600,
      color: theme.gray100,
    },
    '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within': {
      boxShadow: 'none',
    },
    '& .ant-radio-button-wrapper': {
      fontSize: '0.75rem',
      border: 'none',
      color: theme.gray200,
      width: '100%',
      height: 'auto',
    },
    '& .ant-radio-button-wrapper:hover': {
      backgroundColor: theme.gray600,
      color: theme.gray100,
    },
    '& .ant-radio-button-wrapper:first-of-type': {
      border: 'none',
    },
    '& .ant-radio-button-wrapper:not(:first-of-type)::before': {
      display: 'none',
    },
    '& .ant-modal-content': {
      borderRadius: '8px',
    },
  });

export const popupContent = {
  display: 'flex',
  padding: '2em',
};

export const columnContainer = css({
  margin: '1em 0',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const columnTitle = css({
  display: 'inline-block',
  marginBottom: '1em',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: '500',
});

export const columnListOptionsContainer = css({
  maxHeight: '17.5rem',
  overflowY: 'scroll',
  flex: 1,
});

export const VerticalSeparator = styled.div`
  width: 0.0625rem;
  background-color: ${props => props.theme.gray500};
  margin-left: 1.25em;
  margin-right: 1.25em;
`;

export const languageOption = css({
  display: 'block',
});
