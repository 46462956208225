import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const form = css({
  '& h6': {
    fontSize: '1.125rem !important',
    fontWeight: 700,
  },
  '& label': {
    fontSize: '0.8125rem !important',
    fontWeight: 500,
  },
});
export const uploadedVideoWrapper = theme =>
  css({
    '& .ant-btn': {
      position: 'absolute !important',
      top: '-0.625em !important',
      right: '0px !important',
      opacity: 0.2,
      color: `${theme.gray100} !important`,
    },
    '&:hover': {
      '& .ant-btn': {
        opacity: 1,
      },
    },
  });

export const imagesPreview = css({
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
    margin: '0.3125em',
  },
  '& .ant-upload-list-item-image': {
    objectFit: 'cover !important',
  },
  '& .ant-upload-list-item-info': {
    borderRadius: 3,
  },
  '& .ant-upload-list-picture-card-container': {
    borderRadius: '4px !important',
    [Desktop]: { width: '6.25rem', height: '6.25rem' },
    [Mobile]: { width: '6.25rem', height: '5rem' },
  },
  '& .ant-upload-draggable-list-item': {
    borderLeft: '2px dashed transparent',
    borderRight: '2px dashed transparent',
    '&.drop-over-upward': {
      borderLeftColor: '#40AAFB',
    },
    '&.drop-over-downward': {
      borderRightColor: '#40AAFB',
    },
  },
});
export const tabs = theme =>
  css({
    '& .ant-tabs-tab': {
      background: 'none !important',
      border: 'none !important',
      paddingLeft: '0px !important',
      paddingRight: '2em !important',
      [Mobile]: { paddingRight: '0.75em !important' },
    },
    '& input': {
      [Mobile]: { marginBottom: '1em' },
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      borderBottom: `2px solid ${theme.primary_yellow}`,
      color: 'black',
    },
    '& .ant-tabs-nav::before': {
      borderBottom: 'none !important',
    },
  });

export const video = isYouTube =>
  css({
    width: '100% !important',
    height: '100% !important',
    display: 'flex',
    '& video': {
      minHeight: '9.375rem',
    },
    '& iframe': {
      width: '100%',
      height: isYouTube ? '75%' : '100%',
    },
  });

export const videoWrapper = () =>
  css({
    width: '70%',
    marginRight: '1.8125em',
  });
export const videoDragger = video =>
  css({
    [Mobile]: { display: video && 'none' },
  });

export const mt = (desktop, responsive) =>
  css({
    [Desktop]: { marginTop: desktop },
    [Mobile]: { marginTop: responsive },
  });
export const imageUploadDragDrop = css({
  maxHeight: '9.0625rem',
});
