import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const merchantOfficeTab = css({
  backgroundColor: 'white',
  marginTop: '1em',
  '& .reviews-selectBusiness-searchForDeal': {
    display: 'flex',
    margin: '1em 0',
    gap: '1em',
    '@media (max-width: 500px)': {
      flexWrap: 'wrap',
    },
  },
});

export const title = css({
  fontWeight: 700,
  fontSize: '1.4375rem',
  margin: '1.5em 0px 1em 0px',
});

export const titleWrapper = css({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1em',
});

export const versionSwitchWrapper = theme =>
  css({
    marginBottom: '0.75em',
    '& label': {
      color: theme.gray100,
    },
    '& button': {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    '& .ant-switch': {
      background: theme.green3,
    },
    '& .ant-switch.ant-switch-checked': {
      background: theme.green2,
    },
  });

// TODO: REMOVE
export const loadMoreBtn = css({
  [Mobile]: {
    margin: 'auto',
    marginBottom: '2.1875em',
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
  marginBottom: '2.5em',
});

export const popoverWrapper = theme =>
  css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '0px !important',
    backgroundColor: theme.white,
    borderRadius: 4,
    '& .ant-popover-inner-content': {
      padding: 0,
    },
  });

// TODO: REMOVE
export const bussinessDropdown = css({
  display: 'flex !important',
  alignItems: 'center',
  '& .ant-select.ant-select-single': {
    width: '100%',
  },
  '& .ant-select-selector': {
    borderRadius: '36px !important',
    width: '100% !important',
  },
});

// TODO: REMOVE
export const searchDropdown = css({
  display: 'flex !important',
  alignItems: 'center',
  '& .ant-select.ant-select-single': {
    width: '100%',
    [Mobile]: {
      marginTop: '0.9375em',
    },
  },
  '& input.ant-input': {
    paddingLeft: '0px !important',
  },
});

export const reviewsContentWrapper = css({
  marginTop: '2.5em',
  marginBottom: '2.875em',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginTop: '0em',
    },
  },
  '& h5': {
    fontSize: '1.4375em',
    fontWeight: 700,
  },
});

export const commentsWrapper = theme =>
  css({
    marginBottom: '3.125em',
    marginTop: '2.5em',
    '& > span:first-child': {
      color: theme.gray200,
      display: 'inline-block',
      marginBottom: '2.5em',
    },
    [Mobile]: {
      marginTop: '1.6875em',
      marginBottom: '3.9375em',
    },
  });

export const dealSelectWrapper = theme =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '12.5rem',
    },
    '& label': {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: theme.gray200,
    },
    [Mobile]: {
      flexDirection: 'column',
      '& label': {
        marginTop: '0.9375em',
      },
    },
  });

export const accountTitle = theme =>
  css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.gray100,
    paddingBottom: '0.3125em',
    fontSize: '1.1875rem',
    fontWeight: 700,
    borderBottom: `1px solid ${theme.gray500}`,
    '& button': {
      border: 'none',
      background: theme.white,
      marginLeft: 'auto',
    },
    '& button:hover': {
      background: theme.ui_hover,
    },
  });

export const infoTextWrapper = theme =>
  css({
    marginTop: '0.625em',
    marginBottom: 0,
    color: theme.gray100,
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
    '& .icon-Info-2:before': {
      fontSize: '0.75rem',
      marginLeft: '0.3125em',
      verticalAlign: 'baseline',
    },
  });

export const paginationStyles = css({
  marginLeft: '50%',
  marginBottom: '3.125em',
});

export const profileImage = css({
  borderRadius: '50%',
  height: '4rem',
  width: '4rem',
  marginTop: '1rem',
});

export const coverImage = css({
  borderRadius: 5,
  width: '10rem',
  height: 'rem',
  marginTop: '1rem',
});

export const previewButton = css({
  marginTop: '2rem',
});
