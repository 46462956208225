import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import { setStrankConfig } from '@veraio/strank';
import { setAuthConfig, AUTH_EVENTS_TYPES } from '@oneecosystem/authenticate';
import { setDealshakerCoreConfig } from '@oneecosystem/dealshaker-core';
import { UserProvider, ShoppingCartProvider } from 'stores';
import envConfig from 'config/envConfig';
import { trackGeoLocation } from 'services/api/oneLifeApi';
import * as request from 'services/axios/makeRequest';
import initGlobalStyles from 'assets/css/globalCSS';
import { Theme, Toaster } from './components/ui';
import BaseApp from './screens/App';

setAuthConfig({
  identity: { domain: envConfig.keycloakUrl, clientId: 'DealShaker' },
  oneLifeDomain: envConfig.oneLifeApiUrl,
  [AUTH_EVENTS_TYPES.SET_TOKEN]: token => {
    axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: () => {
    trackGeoLocation();
    window.location.reload();
  },
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = '/';
  },
});

setDealshakerCoreConfig({
  dealshakerPortalApiDomain: envConfig.apiAddress,
  oneLifeApiDomain: envConfig.oneLifeApiUrl,
  storage: window.sessionStorage,
  requestInstance: {
    get: (url, config) => request.getReq(url, undefined, config),
    post: request.postReq,
    put: request.putReq,
    patch: request.patchReq,
    delete: request.deleteReq,
  },
});

setStrankConfig({ parseHtml: true, environment: envConfig.strankEnvironment, storage: window.localStorage });

const App = () => (
  <Router>
    <Theme initGlobalStyles={initGlobalStyles}>
      <Toaster />
      <UserProvider>
        <ShoppingCartProvider>
          <BaseApp />
        </ShoppingCartProvider>
      </UserProvider>
    </Theme>
  </Router>
);

const container = document.getElementById('deal-shaker-root');
const root = createRoot(container);
root.render(<App />);
