import React, { useRef } from 'react';
import { pick, isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Button, Tag, Modal, Skeleton } from 'components/UIExternal';
import { useOrders, changeOrderStatus } from 'stores';
import { orderStatus, orderStatusColor, orderStatusInverse } from 'enums';
import { formatDefaultDate } from 'utils';
import OrderDetailsSummaryPdf from './SummaryPdf';
import { orderDetailsHeaderContainer, orderDetailsCancelModal } from './styles';

export const OrderDetailsHeader = () => {
  const { getText } = useTranslations();
  const { orderId, statusId, createdDate, editable } = useOrders(ordersState => ({
    orderId: ordersState?.selectedOrder?.id,
    ...pick(ordersState?.selectedOrder, ['statusId', 'createdDate']),
    editable: ordersState?.editable,
  }));
  const modelRef = useRef();

  return (
    <>
      <section className={orderDetailsHeaderContainer}>
        <div>
          <div className="order-details-status-container">
            {isNil(orderId) ? <Skeleton height={30} /> : <h3>#{orderId}</h3>}
            {isNil(statusId) ? (
              <Skeleton height={20} />
            ) : (
              <Tag type={orderStatusColor[statusId]} className="order-details-status">
                {getText(orderStatusInverse[statusId])}
              </Tag>
            )}
          </div>
          {isNil(createdDate) ? (
            <Skeleton marginTop=".7em" height={10} />
          ) : (
            <p className="gray">{getText('placedOnDate', { date: formatDefaultDate(createdDate) })}</p>
          )}
        </div>
        <div className="order-details-actions-container">
          {/* TODO: hidden until refunds being fixed
          {isNil(statusId) ? (
            <Skeleton height={40} />
          ) : (
            editable && (
              <Button type="danger" onClick={() => modelRef?.current?.open()}>
                {getText(editable ? 'decline' : 'cancel')}
              </Button>
            )
          )} */}
          {!editable && (isNil(statusId) ? <Skeleton /> : <OrderDetailsSummaryPdf />)}
          {isNil(statusId) ? (
            <Skeleton height={40} />
          ) : (
            editable &&
            statusId === orderStatus.new && (
              <Button type="secondary" onClick={() => changeOrderStatus(orderId, orderStatus.completed)}>
                {getText('fulfillOrder')}
              </Button>
            )
          )}
        </div>
      </section>
      <Modal ref={modelRef} modalTitle={getText('cancelOrderId', { id: orderId })} className={orderDetailsCancelModal}>
        <h5>{getText('sureWantToCancel')}</h5>
        <div className="order-details-cancel-modal-actions">
          <Button small type="secondary" onClick={() => modelRef?.current?.close()}>
            {getText('cancel')}
          </Button>
          <Button small type="danger" onClick={() => changeOrderStatus(orderId, orderStatus.canceled)}>
            {getText('confirm')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
