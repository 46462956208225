/* eslint-disable default-case */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { sub, mul } from 'exact-math';
import { isNumber } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, printPrice, getDealFinalPrices } from '@oneecosystem/dealshaker-core';
import { couponStatus } from 'enums';
import { priceContainer, priceStyles, beforeDiscountStyles, savingStyles } from './styles';

export const DealPrice = ({ deal, showSavings, showFinalPrice, showTotalFiatOnly = false, className, dataId }) => {
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const { getText } = useTranslations();
  const theme = useTheme();

  const hasFiatPrice = isNumber(deal?.priceFiat);
  const hasDiscount = isNumber(deal?.discountedPriceFiat) || isNumber(deal?.discountedPriceCrypto);
  const applyQuantity = price =>
    isNumber(deal?.quantity) && isNumber(price) && deal.quantity > 1 ? mul(deal.quantity, price) : price;
  const priceFiat = hasFiatPrice && applyQuantity(deal.priceFiat);
  const priceCrypto = applyQuantity(deal?.priceCrypto);
  const discountedPriceFiat = hasDiscount && applyQuantity(deal?.discountedPriceFiat);
  const discountedPriceCrypto = applyQuantity(deal?.discountedPriceCrypto);
  const finalPrice = getDealFinalPrices(deal);
  const savingFiat = hasDiscount && sub(deal.price, deal.discountedPrice);
  const redeemedCouponsCount = deal?.coupons?.filter(el => el.statusId === couponStatus.redeemed).length;
  const quantity = redeemedCouponsCount ?? deal.quantity;
  const fiatOriginCurrency = deal?.currencyCode;

  const priceComponent = () => {
    switch (true) {
      case showFinalPrice:
        return (
          <p className={priceStyles(theme)} data-id="deal-price-amount" data-fiat={priceFiat} data-crypto={priceCrypto}>
            {printPrice({
              fiat: finalPrice.priceFiat,
              fiatOriginCurrency,
              fiatCrypto: finalPrice.priceCrypto,
              percentage: deal?.percentRatio,
            })}
            {!!quantity && <span className="quantity"> x {quantity}</span>}
          </p>
        );
      case hasDiscount:
        return (
          <>
            <p
              className={beforeDiscountStyles(theme)}
              data-id="deal-price-amount"
              data-fiat={priceFiat}
              data-crypto={priceCrypto}
            >
              {printPrice({
                fiat: priceFiat,
                fiatOriginCurrency,
                fiatCrypto: priceCrypto,
                percentage: deal?.percentRatio,
              })}
            </p>
            <p
              className={`${priceStyles(theme)} ${className ?? ''}`}
              data-id="deal-price-discounted-amount"
              data-discounted-fiat={discountedPriceFiat}
              data-discounted-crypto={discountedPriceCrypto}
            >
              {printPrice({
                fiat: discountedPriceFiat,
                fiatOriginCurrency,
                fiatCrypto: discountedPriceCrypto,
                percentage: deal?.percentRatio,
              })}
            </p>
          </>
        );
      default:
        return (
          <p className={priceStyles(theme)} data-id="deal-price-amount" data-fiat={priceFiat} data-crypto={priceCrypto}>
            {printPrice({
              fiat: priceFiat,
              fiatOriginCurrency,
              fiatCrypto: priceCrypto,
              percentage: deal?.percentRatio,
            })}
          </p>
        );
    }
  };

  return (
    <div
      className={priceContainer(className)}
      data-id={dataId ?? 'deal-price-panel'}
      data-deal-id={deal?.id}
      data-price={deal?.price}
      data-has-discount={!!deal?.discountedPrice}
      data-discount={deal?.discount}
      data-quantity={redeemedCouponsCount ?? deal?.quantity}
      data-percent={deal?.percentRatio}
    >
      {priceComponent()}

      {showSavings && hasDiscount && (
        <p className={savingStyles(theme)} data-id="save-approximately-price" data-savings={savingFiat}>
          {getText('saveApproximatelyPrice', { price: printPrice({ fiat: savingFiat }) })}
        </p>
      )}
    </div>
  );
};

DealPrice.propTypes = {
  deal: PropTypes.object,
  showSavings: PropTypes.bool,
  showFinalPrice: PropTypes.bool,
  className: PropTypes.string,
  dataId: PropTypes.string,
  showTotalFiatOnly: PropTypes.bool,
};

export default DealPrice;
