import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { useHistory } from 'react-router-dom';
import { BorderlessShadowlessGrayBox, Tabs, TabPane } from 'components/ui';
import { Routes } from 'enums';
import { ProfileInformationTab, MyWishlistTab, MessagesTab, MyAddressesTab } from './components';
import { MyProfileOrders } from './Orders';
import { MyProfilePage, title } from './styles';

const MyProfile = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(history.location.pathname.split('/')[2]);

  const onTabClickHandler = e => {
    setActiveTab(e);
    history.push(`${Routes.myProfile}/${e}`);
  };

  return (
    <MyProfilePage theme={theme}>
      <BorderlessShadowlessGrayBox theme={theme}>
        <div className="content-container">
          <h1 className={`${title(theme)}`}>{getText('myProfile')}</h1>
        </div>
      </BorderlessShadowlessGrayBox>
      <div className="content-container">
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          onTabClick={onTabClickHandler}
          theme={theme}
        >
          <TabPane tab={getText('orders')} key="orders">
            <MyProfileOrders />
          </TabPane>
          <TabPane tab={getText('profileInformation')} key="profile-information">
            <ProfileInformationTab />
          </TabPane>
          <TabPane tab={getText('myWishlist')} key="wishlist">
            <MyWishlistTab />
          </TabPane>
          <TabPane tab={getText('yourMessages')} key="messages">
            <MessagesTab />
          </TabPane>
          <TabPane tab={getText('myAddresses')} key="addresses">
            <MyAddressesTab />
          </TabPane>
        </Tabs>
      </div>
    </MyProfilePage>
  );
};

export default MyProfile;
