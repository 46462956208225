import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Col } from 'antd';
import { useTranslations } from '@veraio/strank';
import { mainContainer, title, workingHoursLabel } from './styles';

const MerchantAndAddresses = ({ address }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { name, street, city, postcode, country, phoneNumber, workingDays, workingHours } = address;

  return (
    <Col lg={8} className="gutter-row">
      <div className={mainContainer(theme)}>
        <h2 className={title(theme)}>{getDynamicTranslation(name)?.name}</h2>
        <p>{street}</p>
        <p>{`${city} ${postcode}, ${country}`}</p>
        <p>{phoneNumber}</p>
        <p className={workingHoursLabel(theme)}>{getText('workingHours')}</p>
        <p>{workingDays}</p>
        <p>{workingHours}</p>
      </div>
    </Col>
  );
};

MerchantAndAddresses.propTypes = {
  address: PropTypes.object,
};

export default MerchantAndAddresses;
