import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, useLocations } from '@oneecosystem/dealshaker-core';
import LocalizationPopup from 'components/popups/LocalizationPopup';
import LocationPopup, { locationsModalRef } from 'components/popups/LocationPopup';
import RenderToolTip from '../HeaderPanel/components/toolTip';
import { LocationSettingsLabel, CustomHeaderActionButton, localizationPanelStyles } from './styles';

const Localization = ({ isMobileView }) => {
  const { shippingFrom, shippingTo } = useLocations();
  const selectedCurrency = useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const { getText, language } = useTranslations();
  const [isLocalizationPopupOpen, setIsLocalizationPopupOpen] = useState(false);

  const toggleLocalizationPopup = () => setIsLocalizationPopupOpen(prev => !prev);

  return (
    <div className={localizationPanelStyles(isMobileView)}>
      <CustomHeaderActionButton onClick={toggleLocalizationPopup} type="text">
        <label>{`${language?.code?.toUpperCase() ?? ''} / ${selectedCurrency?.symbol ?? ''} ${selectedCurrency?.code ??
          ''}`}</label>
      </CustomHeaderActionButton>
      <CustomHeaderActionButton className="btn-topNavBar" onClick={() => locationsModalRef?.open()} type="text">
        <span className={`icon-Location-small ${shippingFrom?.length || shippingTo?.length ? 'filtered' : ''}`} />
        <LocationSettingsLabel>{getText('locationSettings')}</LocationSettingsLabel>
        <RenderToolTip />
      </CustomHeaderActionButton>
      <LocalizationPopup open={isLocalizationPopupOpen} closePopup={toggleLocalizationPopup} />
      <LocationPopup />
    </div>
  );
};

Localization.propTypes = {
  isMobileView: PropTypes.bool,
};

export default Localization;
