import queryString from 'query-string';
import moment from 'moment';
import { isNil, isNumber, pick } from '@veraio/core';

export const getOrdersListParams = options => {
  const { statusId, searchText, dateFrom } = options;

  const dateRange = !isNil(dateFrom) && {
    dateFrom: moment()
      .subtract(dateFrom, 'days')
      .format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  };

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...dateRange,
      ...(isNumber(statusId) && { statusId }),
      ...(!!String(searchText)?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};
