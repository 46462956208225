import React, { useRef, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { Table, Button, useUrlParams } from 'components/UIExternal';
import { toast } from 'react-hot-toast';
import { notificationType } from 'enums';
import useError from 'services/errorHandling/useError';
import { getAllNotifications, markNotificationsAsRead, markNotificationAsSeen } from 'services/api/notifications';
import noNotificationsImg from 'assets/images/noNotifications.svg';
import { tableColumns } from './config';
import {
  notificationsPage,
  title,
  noNotificationsContainer,
  noNotificationImage,
  borderlessShadowlessGrayBox,
} from './styles';

const Notifications = props => {
  const theme = useTheme();
  const { history } = props;
  const { getText } = useTranslations();
  const { setError } = useError();
  const [selected, setSelected] = useState([]);
  const { queryParams } = useUrlParams();
  const tableRef = useRef();

  const getNotifications = async options => {
    const res = await getAllNotifications({ ...options, ...queryParams });
    res?.at(0)?.items?.length && markNotificationAsSeen(res?.at(0)?.items?.map(el => el.id));
    return res;
  };

  const markSelectedAsRead = async data => {
    const hasBeenRead = data?.filter(el => !el.isRead);
    if (hasBeenRead.length) {
      const [, err] = await markNotificationsAsRead(hasBeenRead?.map(el => el.id));
      err ? setError(err) : tableRef.current?.getData();
    }
    setSelected([]);
    toast.success(getText('successfullyRead'));
  };

  const handleRowClick = notification => {
    markSelectedAsRead([notification]);
    history.push(notificationType[notification.type]?.url(notification.additionalData));
  };

  return (
    <div className={`${notificationsPage} notifications-page`}>
      <div className={`${borderlessShadowlessGrayBox(theme)} custom-gray-box`}>
        <div className="content-container notifications-page-header-btn">
          <h1 className={title(theme)}>{getText('notifications')}</h1>
          <Button small disabled={!selected?.length} type="secondary" onClick={() => markSelectedAsRead(selected)}>
            {getText('markAsRead')}
          </Button>
        </div>
      </div>
      <div className="content-container notifications-container">
        <Table
          ref={tableRef}
          hasSelection
          onRowClick={handleRowClick}
          hasPagination
          pageSize={10}
          getDataMethod={getNotifications}
          columns={tableColumns(getText, theme)}
          onSelect={setSelected}
          emptyMessage={
            <div className={noNotificationsContainer(theme)}>
              <img className={noNotificationImage} src={noNotificationsImg} alt="Logo" />
              <h4>{getText('youDontHaveNotifications')}</h4>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Notifications;
