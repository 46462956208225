import { paymentMethodsInverse, paymentMethods } from 'enums';

export const paymentTypeBoxesConfig = {
  [paymentMethods.cashOnDelivery]: {
    icon: 'las la-wallet',
    title: paymentMethodsInverse[paymentMethods.cashOnDelivery],
    label: 'toBePaidByUserOnDelivery',
  },
  [paymentMethods.bankTransfer]: {
    icon: 'las la-university',
    title: paymentMethodsInverse[paymentMethods.bankTransfer],
    label: 'toBePaidByUserThroughBank',
  },
  [paymentMethods.cashWallet]: {
    icon: 'las la-wallet',
    title: paymentMethodsInverse[paymentMethods.cashWallet],
    label: 'paidByDuringCheckout',
  },
  [paymentMethods.oneWallet]: {
    icon: 'las la-wallet',
    title: paymentMethodsInverse[paymentMethods.oneWallet],
    label: 'paidByDuringCheckout',
  },
  [paymentMethods.oespWallet]: {
    icon: 'las la-coins',
    title: paymentMethodsInverse[paymentMethods.oespWallet],
    label: 'paidByDuringCheckout',
  },
};

export const bankDetailsInfo = [
  {
    label: 'merchantFullName',
    value: 'fullName',
  },
  {
    label: 'bankName',
    value: 'bankName',
  },
  {
    label: 'bankAccountNumber',
    value: 'iban',
  },
  {
    label: 'bicSwiftCode',
    value: 'bic',
  },
  {
    label: 'address',
    value: 'address',
  },
  {
    label: 'preferredCurrency',
    value: 'currency',
  },
];
