import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { printPrice, useCurrencies, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Row, Col, Select, Spin } from 'antd';
import { toPng } from 'html-to-image';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DatePicker } from 'components/ui';
import { Button } from 'components/UIExternal';
import { statisticsPeriodSelect } from 'enums';
import { formatDefaultDate } from 'utils';
import useError from 'services/errorHandling/useError';
import { getTopCategories } from 'services/api/category';
import { getStatsOverview, getGraphs, getDailySales } from 'services/api/statisticsService';
import { getAllBusinessesInfo } from 'services/api/businessesService';
import { mb } from 'assets/css/globalCSS';
import AreaChartComponent from './components/AreaChartComponent';
import PaymentPieChart from './components/PaymentPieChart';
import BarChartComponent from './components/BarChartComponent';
import CouponsPieChart from './components/CouponsPieChart';
import DailySalesDeal from './components/DailySalesDeal';
import PdfTemplate from './components/PdfTemplate';
import {
  businessSelectWrapper,
  datePickersContainer,
  overviewBox,
  borderBox,
  chartsFiltersWrapper,
  dailySalesTitleWrapper,
  soldLabel,
  dropdownContentWrapper,
  sectionHeader,
} from './styles';

const StatisticsOverview = () => {
  const currency = useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const { Option } = Select;
  const chartsReference = useRef();
  const [reportPdf, setReportPdf] = useState(null);
  const [businesses, setBusinesses] = useState();
  const [statsOverview, setStatsOverview] = useState();
  const [chartsData, setChartsData] = useState();
  const [dailySales, setDailySales] = useState();
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [fromDateOverview, setFromDateOverview] = useState(null);
  const [toDateOverview, setToDateOverview] = useState(null);
  const [fromDateSales, setFromDateSales] = useState(formatDefaultDate(new Date()));
  const [toDateSales, setToDateSales] = useState(formatDefaultDate(new Date()));
  const [graphsCategories, setGraphsCategories] = useState();
  const [selectedGraphsPeriod, setSelectedGraphsPeriod] = useState(0);
  const [selectedGraphsCategory, setSelectedGraphsCategory] = useState(null);

  useEffect(() => {
    currentBusiness &&
      getStatsOverview(currentBusiness, fromDateOverview, toDateOverview, setError).then(res => {
        res[0] ? setStatsOverview(res[0]) : setError(res[1]);
      });

    currentBusiness && fetchGraphData();

    currentBusiness &&
      getDailySales(currentBusiness, fromDateSales, toDateSales, setError).then(res => {
        res[0] ? setDailySales(res[0]) : setError(res[1]);
      });
  }, [currentBusiness]);

  useEffect(() => {
    getStatsOverview(currentBusiness, fromDateOverview, toDateOverview, setError).then(res => {
      res[0] ? setStatsOverview(res[0]) : setError(res[1]);
    });
  }, [fromDateOverview, toDateOverview]);

  useEffect(() => {
    getDailySales(currentBusiness, fromDateSales, toDateSales, setError).then(res => {
      res[0] ? setDailySales(res[0]) : setError(res[1]);
    });
  }, [fromDateSales, toDateSales]);

  useEffect(() => {
    fetchGraphData();
  }, [selectedGraphsPeriod, selectedGraphsCategory]);

  useEffect(() => {
    fetchTopCategories();
    fetchAllBusinessesInfo();
  }, []);

  const fetchTopCategories = async () => {
    const [res, err] = await getTopCategories(false, false);
    err ? setError(err) : setGraphsCategories(res);
  };

  const fetchAllBusinessesInfo = async () => {
    const [res, err] = await getAllBusinessesInfo();
    err ? setError(err) : setBusinesses(res);
  };

  const onButtonClick = async () => {
    if (isNil(chartsReference.current)) return;

    const response = await toPng(chartsReference.current);

    setReportPdf(response);
  };

  const fetchGraphData = async () => {
    const [res, err] = await getGraphs({
      businessId: currentBusiness,
      statisticsPeriodId: selectedGraphsPeriod,
      categoryId: selectedGraphsCategory,
      currencyId: currency?.currency,
    });
    err ? setError(err) : setChartsData(res);
  };

  const { totalSales, totalRevenue, soldCoupons, redeemedCoupons } = (!isNil(statsOverview) && statsOverview) || {};

  const { totalSalesCount, paymentMethods, topSellingCategories, coupons, couponsCount } =
    (!isNil(chartsData) && chartsData) || {};

  return (
    <>
      <section className={sectionHeader}>
        <PDFDownloadLink
          document={
            <PdfTemplate
              imageSrc={reportPdf}
              {...statsOverview}
              fromDateSales={fromDateSales}
              toDateSales={toDateSales}
            />
          }
          fileName={`${getText('dailySalesReport')}-${moment().format('yyyy-mm-DD')}`}
        >
          {({ loading }) => (
            <Button type="secondary" small onClick={onButtonClick}>
              {loading ? getText('generateReport') : getText('downloadReport')}
            </Button>
          )}
        </PDFDownloadLink>
        <div className={businessSelectWrapper}>
          <Select
            placeholder={getText('selectBusiness')}
            showSearch
            allowClear
            optionFilterProp="children"
            onChange={e => {
              e ? setCurrentBusiness(e) : setCurrentBusiness(null);
            }}
          >
            {businesses?.map(business => (
              <Option key={business.id} value={business.id}>
                {getDynamicTranslation(business.name)?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className={datePickersContainer(theme)}>
          <DatePicker
            onChange={e => {
              e ? setFromDateOverview(formatDefaultDate(e)) : setFromDateOverview(null);
            }}
          />
          <label>{getText('to')}</label>
          <DatePicker
            disabledDate={e => e && e > Date.now()}
            onChange={e => {
              e ? setToDateOverview(formatDefaultDate(e)) : setToDateOverview(null);
            }}
          />
        </div>
      </section>
      {statsOverview && chartsData && dailySales ? (
        <>
          <Row
            gutter={[
              { xs: 20, sm: 20, md: 20, lg: 0 },
              { xs: 20, sm: 20, md: 20, lg: 0 },
            ]}
          >
            <Col lg={5} span={12} className="gutter-row">
              <div className={overviewBox(theme)}>
                <h3>{totalSales}</h3>
                <p>{getText('totalSales')}</p>
              </div>
            </Col>
            <Col lg={{ offset: 1, span: 6 }} span={12} className="gutter-row">
              <div className={overviewBox(theme)}>
                <h3>
                  {printPrice({ fiat: totalRevenue, fiatDesiredCurrency: DEFAULT_FIAT_CURRENCY.code })} /{' '}
                  {printPrice({ fiat: totalRevenue })}
                </h3>
                <p>{getText('totalRevenue')}</p>
              </div>
            </Col>
            <Col lg={{ offset: 1, span: 5 }} span={12} className="gutter-row">
              <div className={overviewBox(theme)}>
                <h3>{soldCoupons}</h3>
                <p>{getText('couponsSold')}</p>
              </div>
            </Col>
            <Col lg={{ offset: 1, span: 5 }} span={12} className="gutter-row">
              <div className={overviewBox(theme)}>
                <h3>{redeemedCoupons}</h3>
                <p>{getText('couponsEedeemed')}</p>
              </div>
            </Col>
          </Row>
          <Row className={chartsFiltersWrapper}>
            <Col>
              <div className="label-and-select">
                <label className="first-label-statistics">{getText('viewingStatisticsFor')}&nbsp;</label>
                <Select
                  dropdownClassName={dropdownContentWrapper}
                  defaultValue={getText('allTime')}
                  onChange={e => setSelectedGraphsPeriod(e)}
                >
                  {statisticsPeriodSelect.map(period => (
                    <Option key={period.value} value={period.value}>
                      {getText(period.label)}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="label-and-select">
                <label className="second-label-statistics">{getText('for')}&nbsp;</label>
                <Select
                  dropdownClassName={dropdownContentWrapper}
                  defaultValue={getText('allDeals')}
                  onChange={e => setSelectedGraphsCategory(e)}
                >
                  <Option value="0">{getText('allDeals')}</Option>
                  {graphsCategories?.map(category => (
                    <Option key={category.id} value={category.id}>
                      {getText(category.name)}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row ref={chartsReference}>
            <Col lg={18} span={24} className={borderBox(theme)}>
              <AreaChartComponent data={chartsData.totalSales} totalSales={totalSalesCount} />
            </Col>
            <Col lg={{ offset: 1, span: 5 }} span={24} className={borderBox(theme)}>
              <PaymentPieChart data={paymentMethods} />
            </Col>
            <Col lg={12} span={24} className={borderBox(theme)}>
              <BarChartComponent data={topSellingCategories} />
            </Col>
            <Col lg={{ offset: 1, span: 11 }} span={24} className={borderBox(theme)}>
              <CouponsPieChart data={coupons} total={couponsCount} />
            </Col>
          </Row>
          <Row className={dailySalesTitleWrapper(theme)}>
            <Col lg={11} span={24}>
              <label>{getText('dailySalesReports')}</label>
            </Col>
            <Col lg={{ offset: 3, span: 7 }} span={24}>
              <DatePicker
                onChange={e => setFromDateSales(e ? formatDefaultDate(e) : null)}
                defaultValue={formatDefaultDate(new Date())}
              />
              <label>{getText('to')}</label>
              <DatePicker
                disabledDate={e => e && e > Date.now()}
                onChange={e => setToDateSales(e ? formatDefaultDate(e) : null)}
                defaultValue={formatDefaultDate(new Date())}
              />
            </Col>
          </Row>
          <Row>
            <label className={`${soldLabel} desktop-only`}>{getText('soldItems')}</label>
            <Col lg={24} span={24} className={mb({ lg: 80, span: 30 })}>
              {dailySales.map((deal, i) => (
                <DailySalesDeal key={i} deal={deal} />
              ))}
            </Col>
          </Row>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default StatisticsOverview;
