import { css } from 'emotion';
import { Mobile } from '../../../assets/css/breakpoints';

export const tableContainer = (className, theme) =>
  css(
    {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.white,
      borderRadius: 3,
      padding: '1em',
    },
    className,
  );

export const containerBody = css({
  marginBottom: '0.9375em',
});

export const filterBarContainer = css({
  position: 'relative',
  padding: '1em',
});

export const tableHead = css({
  display: 'flex',
  flexFlow: 'row nowrap',
  [Mobile]: {
    // flexFlow: 'row wrap',
    wordBreak: 'break-word',
    backgroundColor: 'rgba(230,237,242)',
    gap: '1em',
  },
});

export const tableRow = (theme, hasOnClick, selectRow) =>
  css(
    {
      display: 'flex',
      alignItems: 'center',
      color: theme.black100,
      transition: 'background-color .3s ease-in-out',
      borderBottom: `1px solid ${theme.gray500}`,

      '&:hover': {
        backgroundColor: theme.gray700,

        '& i': {
          color: theme.skyblue_hover,
        },
      },

      '& i': {
        color: theme.gray600,
      },
    },
    hasOnClick && {
      cursor: 'pointer',
    },
    selectRow && {
      backgroundColor: theme.skyblue_hover,
      '&:hover': {
        backgroundColor: theme.skyblue_hover,
      },
    },
  );

export const tableCell = ({ width, flex, sm, md, lg, xl, justify }) =>
  css(
    {
      fontSize: '1rem',
      lineHeight: 1.46,
      letterSpacing: '0.0219em',
      flex: flex ?? (width ? `0 1 ${width}px` : '1 0 3.75em'),
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      padding: '1em 0.375em',
      outline: 'none',
    },
    justify && {
      justifyContent: 'center',
    },
    sm && {
      '@media (max-width: 575px)': {
        display: 'none',
      },
    },
    md && {
      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
    lg && {
      '@media (max-width: 991px)': {
        display: 'none',
      },
    },
    xl && {
      '@media (max-width: 1199px)': {
        display: 'none',
      },
    },
  );

export const tableHeadCell = (options, theme) =>
  css(
    tableCell(options),
    {
      fontSize: '0.875rem',
      color: theme.gray300,
      backgroundColor: theme.ui_hover_rgba,
      padding: '1em 0.5em',

      '& i': {
        color: theme.gray400,
      },

      '& > p': {
        marginBottom: 0,
      },

      '&:hover': {
        '& i': {
          color: theme.primary_blue,
        },
      },
    },
    options?.sortable && {
      cursor: 'default',
      '& b': {
        cursor: 'pointer',
        '&:hover + i': {
          opacity: 1,
        },
      },
    },
  );

export const tableHeadIcon = (isAsc, isDesc) =>
  css({
    fontSize: '1.125em',
    opacity: isAsc || isDesc ? 1 : 0,
    transform: `rotate(${isDesc ? 0 : 180}deg)`,
    transition: 'transform 300ms',
  });

export const tableCellContent = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '& span': {
    display: 'inline',
  },
});

export const tableCellEmpty = theme =>
  css({
    width: '2rem',
    height: '0.25rem',
    backgroundColor: theme.gray700,
    borderRadius: '12px',
    margin: '0 auto',
  });

export const tableCellCheckButton = css({
  '& i': {
    margin: 0,
  },
});

export const noResultsContainer = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5em 1.25em',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.gray400,
  });

export const noResultsIcon = css({
  width: '4rem',
  height: '2.75rem',
  marginBottom: '1em',
});

export const noResultsTitle = css({
  fontWeight: 500,
  marginBottom: '0.75em',
});

export const noResultsSubtitle = theme =>
  css({
    color: theme.black100,
  });

export const tableTitleHeadline = css({
  marginBottom: '3em',
  fontWeight: 600,
});
