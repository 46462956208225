import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Row, Col } from 'antd';
import { isMobile } from 'react-device-detect';
import { Button, Pagination, Skeleton } from 'components/UIExternal';
import { Routes } from 'enums';
import { getAllBusinessAddresses } from 'services/api/businessAddress';
import useError from 'services/errorHandling/useError';
import NoAddressesImg from 'assets/images/noAddresses.svg';
import BusinessAddressCard from './BusinessAddressCard';
import { merchantOfficeTab, titleWrapper } from './styles';
import { addressesList, noAddressesContainer, noAddressesText } from './addressesStyles';

const AddressBookTab = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [addresses, setAddresses] = useState(null);

  const fetchAllBusinessAddresses = async options => {
    const [res, err] = await getAllBusinessAddresses(options);
    err ? setError(err) : setAddresses(res);
    return res;
  };

  return (
    <div className={merchantOfficeTab}>
      <div className={titleWrapper}>
        <Pagination onChange={fetchAllBusinessAddresses} pageSize={3} />
        <Button type="info" small linkTo={Routes.addBusinessAddress}>
          {getText('addNew')}
        </Button>
      </div>
      {isNil(addresses) ? (
        <Row className={addressesList} gutter={[24, 24]} justify="space-around">
          <Skeleton count={3} height={400} width="32%" />
        </Row>
      ) : !addresses?.items?.length ? (
        <div className={`${noAddressesContainer} ${isMobile ? 'flex-column' : 'flex'}`}>
          <img src={NoAddressesImg} alt="No addresses logo" />
          <div className={noAddressesText(theme)}>
            <label>{getText('doNotHaveAddressesYet')}</label>
          </div>
        </div>
      ) : (
        <Row className={addressesList} gutter={[24, 24]}>
          {addresses?.items?.map(address => (
            <Col key={address.id} className="gutter-row" lg={8} span={24}>
              <BusinessAddressCard address={address} reloadAllLoadedPages={fetchAllBusinessAddresses} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default AddressBookTab;
