import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { AutoComplete, Avatar, Col, Input, message, Row, Spin, Upload } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { debounce, isEmpty } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import ImgCrop from 'antd-img-crop';
import { getToken } from '@oneecosystem/authenticate';
import { BorderlessShadowedBox, NestedCol } from 'components/ui';
import { Button } from 'components/UIExternal';
import { BaseCheckbox } from 'components/ui/Checkboxes';
import ListingDeal from 'components/deal/ListingDeal';
import apiRoutes from 'config/apiRoutes';
import { Routes, businessStatus } from 'enums';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import {
  getChatModerator,
  getMetaDataForUserAndDeal,
  searchUsers,
  startChatWithMessage,
} from 'services/api/chatService';
import useError from 'services/errorHandling/useError';
import { removeMedia } from 'services/api/mediaService';
import startConversationImg from 'assets/images/chat/start-conversation.png';
import {
  search,
  searchResultWrapper,
  noMessagesBox,
  uploadBtn,
  participantSelector,
  imagesPreview,
  startChatBtnDisabled,
  backButton,
} from './styles';

const StartChat = () => {
  const theme = useTheme();
  const history = useHistory();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [images, setImages] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [deal, setDeal] = useState({});
  const [messageText, setMessageText] = useState('');
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [isModeratorAdded, setIsModeratorAdded] = useState(false);
  const { dealId } = queryString.parse(history.location.search);
  const { userId } = queryString.parse(history.location.search);
  const isUserOrDealPreloaded = dealId || userId;
  const today = new Date();
  const date = `${getText('today')}, ${today.getDate()} ${today.toLocaleString('default', { month: 'long' })}`;

  useEffect(() => {
    userId !== 'undefined' &&
      userId !== 'null' &&
      userId?.length &&
      getMetaDataForUserAndDeal([userId], dealId, setError).then(res => {
        if (res) {
          setParticipants(Object.values(res?.participants));
          setDeal(res?.dealDetails);
        }
      });
  }, [userId, dealId]);

  const onSearch = debounce(e => {
    e?.length > 2
      ? searchUsers(e, setError).then(
          res => res && setSearchResult(res.map(user => ({ label: user.username, value: user.id, ...user }))),
        )
      : setSearchResult('');
  }, 1500);

  const onSelect = participant => {
    if (!participants.find(part => part.id === participant.id)) {
      const temp = [...participants];
      temp.push(participant);
      setParticipants(temp);
    }
  };

  const onSendMessage = () => {
    const request = {
      text: messageText,
      media: images.map(img => img.id),
      participants: participants.map(part => part.id),
    };
    if (dealId) request.dealId = Number(dealId);

    (messageText.length || images.length) &&
      startChatWithMessage(request, setError).then(res => res && history.push(`chat?chatId=${res}`));
    setMessageText('');
  };

  const onRemoveImage = async id => {
    setDeleteInProgress(true);
    const [, err] = await removeMedia(id);
    err && setError(err);
    setImages(images.filter(img => img.id !== id));
    setDeleteInProgress(false);
  };

  const getMapImages = () =>
    images.map(image => ({
      uid: image.id,
      url: image.url,
      thumbUrl: image.thumbnailUrl,
      name: image.id,
      status: 'done',
    }));

  const mapParticipants = () =>
    participants.map((participant, i) => (
      <div key={i} className="flex space-between wrapper">
        <div className="flex flex-center">
          <Avatar src={participant?.avatarThumbnailUrl}>{participant?.firstName?.charAt(0)}</Avatar>
          <p>{`${participant?.firstName} ${participant?.lastName}`}</p>
        </div>
        {!isUserOrDealPreloaded && (
          <BaseCheckbox
            onChange={() => {
              setParticipants(participants.filter(part => part?.id !== participant?.id));
              isModeratorAdded && setIsModeratorAdded(false);
            }}
            checked
            theme={theme}
          />
        )}
      </div>
    ));

  const participantSelectSection = (
    <>
      <Button type="link" linkTo={`${Routes.myProfile}${Routes.messages}`} className={backButton(theme)}>
        <FeatherIcon icon="arrow-left" size={20} />
        {getText('allMessages')}
      </Button>
      {!isUserOrDealPreloaded && !isModeratorAdded && (
        <AutoComplete
          style={{ width: '100%' }}
          className={search}
          dropdownClassName={searchResultWrapper}
          notFoundContent={getText('noResultsFound')}
          options={searchResult}
          onSearch={onSearch}
          onSelect={(val, option) => onSelect(option)}
        />
      )}
      {!isEmpty(deal) && (
        <ListingDeal
          isVertical
          declinedBusiness={deal?.businessStatus === businessStatus.declined}
          mainDealName={deal.name}
          deal={deal}
          isMerchant={deal?.isOwner}
        />
      )}
      {participants?.length > 0 && (
        <BorderlessShadowedBox className={participantSelector} theme={theme}>
          {mapParticipants()}
        </BorderlessShadowedBox>
      )}
      <Button
        type="secondary"
        small
        fullWidth
        disabled={participants.length < 1}
        style={{ marginBottom: 88 }}
        onClick={onSendMessage}
        className={participants.length < 1 ? startChatBtnDisabled : ''}
      >
        {getText('startChat')}
      </Button>
      {!isModeratorAdded && (
        <Button
          type="link"
          small
          onClick={() =>
            getChatModerator(dealId || null, setError).then(r => {
              setParticipants(r ? [r] : [{ firstName: getText('noModeratorsFound'), lastName: '', id: 0 }]);
              setIsModeratorAdded(true);
            })
          }
        >
          {getText('orMessageModerator')}
        </Button>
      )}
    </>
  );

  const imgUploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    accept: MediaTypesEnum.Image.allowedTypes.join(', '),
    action: apiRoutes.MEDIA_ADD_IMAGE,
    headers: { Authorization: `Bearer ${getToken().access_token}` },
    beforeUpload: file => {
      if (file.size > MediaTypesEnum.Image.allowedSize) {
        message.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
        return Upload.LIST_IGNORE;
      }
      if (!MediaTypesEnum.Image.allowedTypes.includes(file.type)) {
        message.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') setImages([...images, info.file.response]);
      if (status === 'done') message.success(`${info.file.name} file uploaded successfully.`);
      else if (status === 'error') message.error(`${info.file.name} file upload failed.`);
    },
  };

  const chatSection = (
    <Row gutter={24}>
      <NestedCol totalWidth={17} span={24} lg={17} className={noMessagesBox}>
        <label className="date">{date}</label>
        <hr />
        <img src={startConversationImg} alt="Start chat" />
        <p className="start">{getText('startConversation')}</p>
        <p className="ask-anything">{getText('askAnything')}</p>
        <hr />
      </NestedCol>
      <NestedCol lg={11} span={24} totalWidth={17}>
        <Input.TextArea
          value={messageText}
          onChange={e => setMessageText(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSendMessage();
            }
          }}
          autoSize
          style={{ minHeight: 170, marginBottom: 16 }}
        />
        <Row style={{ marginBottom: 120, display: 'flex', justifyContent: 'space-between' }} gutter={24}>
          <NestedCol lg={5} span={24} totalWidth={11}>
            <Upload {...imgUploadProps}>
              <Button type="link" small className={uploadBtn(theme)}>
                <FeatherIcon icon="paperclip" size={20} />
                <div>
                  <p className="click-here-lbl">{getText('clickHereToUploadAttachment')}</p>
                  <p className="attach-text-lbl">{getText('youCanAttachJpg')}</p>
                </div>
              </Button>
            </Upload>
          </NestedCol>
          <NestedCol lg={5} span={24} totalWidth={11}>
            <Button
              type="secondary"
              small
              fullWidth
              disabled={!participants?.length || (!messageText.length && !images.length) || participants[0].id === 0}
              onClick={onSendMessage}
            >
              {getText('messageUsers', { messageUsers: participants?.length })}
            </Button>
          </NestedCol>
        </Row>
      </NestedCol>
      <NestedCol lg={6} span={24} totalWidth={17}>
        {deleteInProgress ? (
          <div className="content-container">
            <Spin size="large" />
          </div>
        ) : (
          <ImgCrop>
            <Upload
              className={imagesPreview}
              onPreview={null}
              listType="picture-card"
              fileList={getMapImages()}
              onRemove={e => onRemoveImage(e.uid)}
            />
          </ImgCrop>
        )}
      </NestedCol>
    </Row>
  );

  return (
    <div className="content-container">
      <Row>
        <Col lg={6} span={24}>
          {participantSelectSection}
        </Col>
        <Col lg={{ span: 17, offset: 1 }} span={24}>
          {chatSection}
        </Col>
      </Row>
    </div>
  );
};

export default StartChat;
