import { createStore, useStore } from '@veraio/core';
import { cloneDeep } from 'lodash-es';
import { orderStatus } from 'enums';
import {
  setError,
  getSoldOrders,
  getBoughtOrders,
  updateOrderDeliveryStatus,
  updateOrderCashPaymentStatus,
  updateOrderStatus,
} from 'services';
import {
  orderDeliveryDetails,
  orderPaymentDetails,
  editOrderDeliveryStatus,
  editOrderCashPaymentStatus,
  editOrderStatus,
  orderDeliveryAddress,
} from 'utils';

const initState = {
  editable: false,
  orderList: null,
  selectedOrder: null,
};

const ordersStore = createStore(initState);

export const initOrdersStore = state => ordersStore.setState(prev => ({ ...prev, ...(state ?? {}) }));

export const resetOrdersStore = () => ordersStore.setState(initState);

export const fetchOrders = async options => {
  const { editable } = ordersStore.getState();

  // In the merchant office we have property is editable true so call one endpoint instead call the other
  const method = editable ? getSoldOrders : getBoughtOrders;
  const [res, err] = await method(options);
  if (err) return setError(err);

  ordersStore.setState(prev => ({
    ...prev,
    orderList: res.items,
    selectedOrder: prev?.selectedOrder ?? res.items?.at(0),
  }));

  return res;
};

export const selectOrder = newOrder => ordersStore.setState(prev => ({ ...prev, selectedOrder: cloneDeep(newOrder) }));

export const changeOrderDeliveryStatus = async (orderId, statusId) => {
  const [, err] = await updateOrderDeliveryStatus(orderId, statusId);
  if (err) return setError(err);

  ordersStore.setState(prev => ({
    ...prev,
    orderList: prev.orderList.map(el => (el.id === orderId ? editOrderDeliveryStatus(el, statusId) : el)),
    selectedOrder: editOrderDeliveryStatus(prev?.selectedOrder, statusId),
  }));

  completeOrder();
};

export const changeOrderCashPaymentStatus = async (orderId, statusId) => {
  const [, err] = await updateOrderCashPaymentStatus(orderId, statusId);
  if (err) return setError(err);

  ordersStore.setState(prev => ({
    ...prev,
    orderList: prev.orderList.map(el => (el.id === orderId ? editOrderCashPaymentStatus(el, statusId) : el)),
    selectedOrder: editOrderCashPaymentStatus(prev?.selectedOrder, statusId),
  }));

  completeOrder();
};

export const changeOrderStatus = async (orderId, statusId) => {
  const [, err] = await updateOrderStatus(orderId, statusId);
  if (err) return setError(err);

  ordersStore.setState(prev => ({
    ...prev,
    orderList: prev.orderList.map(el => (el.id === orderId ? editOrderStatus(el, statusId) : el)),
    selectedOrder: editOrderStatus(prev?.selectedOrder, statusId),
  }));
};

export const selectedOrderDeliveryDetails = () => {
  const { selectedOrder } = ordersStore.getState();
  const { delivery } = selectedOrder ?? {};

  return orderDeliveryDetails(delivery);
};

export const selectedOrderPaymentDetails = () => {
  const { selectedOrder } = ordersStore.getState();
  const { payment } = selectedOrder ?? {};

  return orderPaymentDetails(payment);
};

export const selectedOrderDeliveryAndPaymentDetails = () => {
  const { selectedOrder } = ordersStore.getState();
  const { delivery, payment } = selectedOrder ?? {};

  return { ...orderDeliveryDetails(delivery), ...orderPaymentDetails(payment) };
};

export const completeOrder = () => {
  const { isPaymentCompleted, isDeliveryCompleted } = selectedOrderDeliveryAndPaymentDetails();
  // If the user complete delivery and cash payment then we should automatically mark the order as completed
  isPaymentCompleted &&
    isDeliveryCompleted &&
    changeOrderStatus(ordersStore.getState()?.selectedOrder?.id, orderStatus.completed);
};

export const selectedOrderDeliveryAddress = () => {
  const { selectedOrder } = ordersStore.getState();
  const { delivery } = selectedOrder ?? {};

  return orderDeliveryAddress(delivery);
};

export const useOrders = callback => useStore(ordersStore, callback);
