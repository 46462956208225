import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const accountsList = css({
  marginTop: '1.875em',
  marginBottom: '5em',
  [Mobile]: {
    marginBottom: '2.5em',
  },
});

export const accountBox = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1em',
    border: `2px solid ${theme.gray500}`,
  });

export const accountTitle = theme =>
  css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '0.25em',
    color: theme.gray100,
    fontSize: '1.1875rem',
    fontWeight: 700,
  });

export const accountPopover = theme =>
  css({
    color: theme.gray100,
    justifyContent: 'left',
  });

export const statusBinWrapper = theme =>
  css({
    display: 'flex',
    '& p': {
      color: theme.gray300,
      fontSize: '0.8125rem',
    },
    '& p:first-child': {
      marginRight: '1em',
      display: 'flex',
      alignItems: 'center',
    },
    '& span': {
      marginLeft: '0.25em',
      borderRadius: '45px',
      fontSize: '0.3125rem',
      display: 'flex',
      width: '0.625rem',
      height: '0.625rem',
      backgroundColor: theme.green3,
      color: theme.white,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

export const messageWrapper = theme =>
  css({
    background: theme.ui_hover_rgba,
    padding: '0.5625em',
    textAlign: 'center',
    marginBottom: '1.0625em',
    width: '100%',
    '& p': {
      display: 'flex',
      fontSize: '0.75rem',
      marginBottom: '0.25em',
      textAlign: 'left',
    },
    '& span': {
      fontSize: '0.875rem',
      marginRight: '0.4375em',
    },
    '& button': {
      fontSize: '0.75rem',
      textDecoration: 'underline',
      color: theme.blue2,
      background: 'transparent',
      border: 'none',
    },
    '& button:hover': {
      textDecoration: 'underline',
      color: theme.primary_blue_hover,
    },
  });

export const commentMessage = css({
  fontSize: '0.8125rem !important',
  fontWeight: 500,
});

export const accountTabs = css({
  width: '100%',
  '& .ant-tabs-ink-bar': {
    display: 'none',
  },
  '& .ant-tabs-tab': {
    paddingBottom: '0.625em',
    fontSize: '1rem',
  },
  '& .ant-tabs-tab.ant-tabs-tab-active': {
    fontWeight: 500,
  },
});

export const infoTextWrapper = theme =>
  css({
    marginTop: 0,
    marginBottom: '0.625em',
    color: theme.gray100,
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
    '& .icon-Info-2:before': {
      fontSize: '0.75rem',
      marginLeft: '0.3125em',
      verticalAlign: 'baseline',
    },
  });

export const bankDetailsForm = css({
  '& label': {
    fontSize: '0.8125rem !important',
    fontWeight: 500,
  },
  '& .ant-input::placeholder, .ant-select-selection-placeholder': {
    fontSize: '0.75rem',
  },
  '& .ant-form-item-label': {
    paddingBottom: '0 !important',
  },
  '& .ant-form-item': {
    marginBottom: '1em',
  },
});

export const email = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '15.625rem',
  whiteSpace: 'nowrap',
});

export const titleForm = theme =>
  css({
    fontWeight: 500,
    fontSize: '2.375rem',
    color: theme.background100,
    marginBottom: 0,
    [Mobile]: {
      fontSize: '1.375rem',
    },
  });

export const formNote = css({
  fontSize: '1rem',
  marginBottom: '3.125em',
});

export const accountFormWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  '& label': {
    fontSize: '0.8125rem !important',
    fontWeight: 500,
  },
  '& .ant-input::placeholder, .ant-select-selection-placeholder': {
    fontSize: '0.75rem',
  },
  '& .ant-form-item-label': {
    paddingBottom: '0 !important',
  },
});

export const formSectionWrapper = theme =>
  css({
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.375em 1.75em',
      marginBottom: '1.4375em',
    },
  });

export const formSubTitle = css({
  fontSize: '1.125rem',
  fontWeight: 700,
  marginBottom: 0,
});

export const formSubtitleInfo = theme =>
  css({
    color: theme.gray100,
  });

export const tabs = theme =>
  css({
    '&.ant-tabs': {
      overflow: 'visible',
    },
    '& .ant-tabs-tab': {
      background: 'none !important',
      border: 'none !important',
    },
    '& .ant-tabs-tab:first-child': {
      paddingLeft: '0px !important',
    },
    '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
      borderBottom: `2px solid ${theme.primary_yellow} !important`,
    },
    '& .ant-tabs-nav::before': {
      borderBottom: 'none !important',
    },
  });

export const languagesFormWrapper = css({
  marginBottom: '0px !important',
  marginTop: '1em !important',
});

export const merchantTypeTags = theme =>
  css({
    marginBottom: '1.25em !important',
    '.ant-tag.ant-tag-checkable': {
      background: theme.white,
      color: theme.gray100,
      border: `1px solid ${theme.gray400}`,
      borderRadius: 4,
      padding: '0.25em 0.5em',
      marginBottom: '0.5em',
    },
    '.ant-tag.ant-tag-checkable.ant-tag-checkable-checked': {
      background: theme.skyblue,
      border: `1px solid ${theme.primary_blue}`,
    },
    [Mobile]: {
      '.ant-form-item-control-input-content': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '.ant-tag.ant-tag-checkable': {
        width: '48%',
        marginRight: 0,
        textAlign: 'center',
      },
    },
  });

export const areaInput = css({
  marginBottom: '1em',
  '& .ant-form-item': {
    marginBottom: 0,
  },
  '& .area-description': {
    fontSize: '0.75rem',
  },
});

export const optionalNote = theme =>
  css({
    marginTop: '0.5em',
    color: theme.gray200,
    borderBottom: `1px solid ${theme.gray500}`,
  });

export const currencyDropdown = css({
  width: '25%',
  [Mobile]: {
    width: '45%',
  },
  [Desktop]: {
    marginBottom: '0.625em !important',
  },
});

export const agreementCheckboxWrapper = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.25em',
    '& .ant-checkbox-wrapper': {
      marginRight: '0.5em',
      [Desktop]: {
        marginLeft: '0.9375em',
      },
    },
    '& p': {
      color: theme.gray200,
      margin: 0,
    },
  });

export const acceptLabel = theme =>
  css({
    color: theme.gray200,
    fontWeight: '400 !important',
    [Desktop]: {
      marginLeft: '0.9375em',
    },
  });

export const formButtons = css({
  marginTop: '1.25em',
  marginBottom: '4.875em',
  display: 'flex !important',
  justifyContent: 'space-between',
  '& span': {
    margin: 'auto',
  },
  [Mobile]: {
    flexDirection: 'column-reverse',
    marginBottom: '3em',
  },
});

export const mandatoryStar = css({
  marginRight: '0.25em',
  color: '#ff4d4f',
  fontSize: '0.875rem',
  fontFamily: 'SimSun, sans-serif',
  lineHeight: 1,
});

export const rulesModal = css({
  textDecoration: 'underline',
  padding: 0,
  marginLeft: '0.25em',
});

export const more = theme =>
  css({
    cursor: 'pointer',
    padding: '0.3125em',
    '&:hover': {
      background: theme.ui_hover_rgba,
    },
  });

export const merchantBecomeFree = css({
  fontWeight: 500,
});

export const selectLangDropdown = css({
  minWidth: '12.5rem',
  '& div:nth-child(2)': {
    maxHeight: '12.1875rem',
  },
});
