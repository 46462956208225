import React, { useState, useRef, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { isNil } from '@veraio/core';
import { SearchInput } from 'components/ui';
import { useUrlParams, Skeleton } from 'components/UIExternal';
import { MerchantAddressMap } from 'components/shared';
import { BusinessCard, BusinessCardSkeleton } from 'components/screens';
import { getAllNearByBusinesses } from 'services';
import useError from 'services/errorHandling/useError';
import { mapWrapper, merchantsListContainer, searchBarStyles } from './styles';

const filterKeys = {
  name: 'name',
};

const animateConfig = { animate: true, duration: 1.4 };

export const NearbyMerchants = () => {
  const { getText } = useTranslations();
  const mapRef = useRef();
  const { setError } = useError();
  const { queryParams } = useUrlParams();
  const [merchantAddressesList, setMerchantAddressesList] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [userCoords, setUserCoords] = useState(null);

  useEffect(() => {
    fetchBusinessAddresses();
  }, []);

  const fetchBusinessAddresses = async search => {
    const userCoordinates = await getUserCoordinates();

    const [addresses, err] = await getAllNearByBusinesses({
      ...(search && { name: search }),
      latitude: userCoordinates?.lat,
      longitude: userCoordinates?.lng,
    });

    if (err) return setError(err);
    setMerchantAddressesList(addresses);

    flyToAddresses(addresses, userCoordinates);
  };

  const getUserCoordinates = () =>
    new Promise(resolve => {
      if (userCoords) return resolve(userCoords);

      navigator.geolocation.getCurrentPosition(
        event => {
          const coords = { lat: event.coords.latitude, lng: event.coords.longitude };
          setUserCoords(coords);
          resolve(coords);
        },
        error => {
          resolve();
          setUserCoords(undefined);
          setError(error);
        },
      );
    });

  const flyToMerchantAddress = merchant => {
    if (merchant?.address === selectedBusiness?.address) return;
    const coords = { lat: merchant?.latitude, lng: merchant?.longitude };
    mapRef.current?.flyTo(coords, 17, animateConfig);
    setSelectedBusiness(merchant);
  };

  const flyToAddresses = (addresses, userCoordinates) => {
    const boundaries = addresses?.map(el => [el.latitude, el.longitude]);
    boundaries?.length
      ? mapRef.current?.flyToBounds(boundaries, animateConfig)
      : mapRef.current?.flyTo(userCoordinates ?? { lat: 0, lng: 0 }, 17, animateConfig);
  };

  return (
    <section className={mapWrapper}>
      <aside className={merchantsListContainer}>
        {isNil(merchantAddressesList) ? (
          <>
            <Skeleton height="2rem" />
            <Skeleton height="1.5rem" margin="1.5em 0" />
            <BusinessCardSkeleton />
          </>
        ) : (
          <>
            <h2>{merchantAddressesList.length} Merchants</h2>
            <SearchInput
              className={searchBarStyles}
              placeholder={getText('searchByMerchantUserName')}
              defaultValue={queryParams[filterKeys.name]}
              onPressEnter={search => fetchBusinessAddresses(search)}
            />
            {!merchantAddressesList.length ? (
              <h2>{getText('noMerchantNearby')}</h2>
            ) : (
              merchantAddressesList.map((merchant, index) => (
                <BusinessCard
                  key={index}
                  merchant={merchant}
                  onClick={flyToMerchantAddress}
                  isSelected={merchant.address === selectedBusiness?.address}
                />
              ))
            )}
          </>
        )}
      </aside>
      <MerchantAddressMap mapRef={mapRef} userPosition={userCoords} merchantAddressesList={merchantAddressesList} />
    </section>
  );
};
