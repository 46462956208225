import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from '@veraio/core';
import { getDynamicTranslation, getText } from '@veraio/strank';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Marker, Popup } from 'react-leaflet';
import { Map, Button, eyePin } from 'components/UIExternal';
import { merchantAddressPin } from './styles';

export const MerchantAddressMap = ({ merchantAddressesList, mapRef, userPosition }) => (
  <Map ref={mapRef} userPosition={userPosition} className={merchantAddressPin}>
    <MarkerClusterGroup
      chunkedLoading
      showCoverageOnHover={false}
      removeOutsideVisibleBounds={false}
      maxClusterRadius={50}
    >
      {/* map markers */}
      {isArray(merchantAddressesList) &&
        merchantAddressesList?.map((merchant, index) => (
          <Marker key={index} position={{ lat: merchant?.latitude, lng: merchant?.longitude }} icon={eyePin}>
            <Popup>
              <h4 className="business-pin-label">{getText('name')}</h4>
              <Button small type="link" linkTo={`/merchant/${merchant?.id}`}>
                {getDynamicTranslation(merchant?.name)?.name}
              </Button>
              <h4 className="business-pin-label">{getText('address')}</h4>
              <Button
                small
                type="link"
                linkTo={`http://maps.google.com/maps?q=${merchant?.latitude}+${merchant?.longitude}`}
              >
                {merchant?.address}
              </Button>
            </Popup>
          </Marker>
        ))}
    </MarkerClusterGroup>
  </Map>
);

MerchantAddressMap.propTypes = {
  merchantAddressesList: PropTypes.array,
  mapRef: PropTypes.object,
  userPosition: PropTypes.object,
};
