import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isPlainObject } from '@veraio/core';
import { getText } from '@veraio/strank';
import { DEFAULT_FIAT_CURRENCY, printPrice } from '@oneecosystem/dealshaker-core';
import { paymentMethodWalletContainer } from './styles';

const PaymentMethodWallet = ({ label, balanceLabel, insufficientFunds, price }) => {
  const theme = useTheme();

  return (
    <div className={paymentMethodWalletContainer(theme)}>
      <h5 className="title">{getText(label)}</h5>
      <p className="subtitle">
        {getText('yourBalance')} {balanceLabel}
      </p>
      {isPlainObject(price) && (
        <p>
          {getText('payAmountApproxWithCash', {
            totalBasePrice: printPrice({
              fiat: price.totalBasePrice,
              fiatDesiredCurrency: DEFAULT_FIAT_CURRENCY.code,
            }),
            totalLocalPrice: printPrice({ fiat: price.totalLocalPrice, fiatOriginCurrency: price.originCurrency }),
          })}
        </p>
      )}
      {insufficientFunds && <p className="insufficientFunds">({getText('yourBalanceNotEnough')})</p>}
    </div>
  );
};

PaymentMethodWallet.propTypes = {
  label: PropTypes.string,
  balanceLabel: PropTypes.string,
  insufficientFunds: PropTypes.bool,
  price: PropTypes.object,
};

export default PaymentMethodWallet;
