import { css } from 'emotion';

export const bodyContainer = css({
  margin: '2.5em 0 3.125em 0',
  '& > div': {
    gap: '1em',
    flexWrap: 'wrap',
    marginBottom: '1em',
    overflowX: 'scroll',
    '@media (max-width: 530px)': {
      '& > div': {
        flexWrap: 'wrap',
      },
    },
  },
});

export const header = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.25em',

  '& .promo-code-filters': {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '1em',
    '@media (max-width: 450px)': {
      flexWrap: 'wrap',
    },
  },
});
