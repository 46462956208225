import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import InformationalPopover from 'components/popups/InformationalPopover';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import useError from 'services/errorHandling/useError';
import { getPromotionsDealsInfo } from 'services/api/promotionService';
import { getAvailableCredits } from 'services/api/creditsService';
import starBoxImage from 'assets/images/addDeal/star-box.svg';
import {
  availableCreditsBox,
  promoteTitle,
  creditsLeftWrapper,
  promotedDealsWrapper,
  noCreditsBox,
  noVipDealsWrapper,
  buyMore,
} from './styles';

const PromoteDealBox = ({ classAvalable, classEmpty }) => {
  const theme = useTheme();
  const { setError } = useError();
  const { getText } = useTranslations();
  const [promotedDeals, setPromotedDeals] = useState();
  const [availableCredits, setAvailableCredits] = useState();

  useEffect(() => {
    getPromotionsDealsInfo(setError).then(res => res && setPromotedDeals(res.promotedDealsCount));
    getAvailableCredits(setError).then(res => res && setAvailableCredits(res.credits));
  }, []);

  return availableCredits && availableCredits > 0 ? (
    <div className={`${availableCreditsBox(theme)} ${classAvalable}`}>
      <div className={promoteTitle(theme)}>
        <span>{getText('promoteDeal')}</span>
        <InformationalPopover text={getText('useCreditsToPromoteDeal')} />
      </div>
      <div className={creditsLeftWrapper(theme)}>
        <span>{availableCredits}</span>
        <p>{getText('creditsLeft')}</p>
        <Button type="link" small linkTo={`${Routes.buyCredits}`} className={buyMore}>
          {getText('buyMore')}
        </Button>
      </div>
      <div className={promotedDealsWrapper(theme)}>
        <span>{getText('currentlyPromotedDeals')}</span>
        <span>{promotedDeals}</span>
      </div>
    </div>
  ) : (
    <div className={`${noCreditsBox(theme)} ${classEmpty}`}>
      <div className={promoteTitle(theme)}>
        <span>{getText('promoteDeal')}</span>
        <InformationalPopover text={getText('promoteDealsAttractVisits')} />
      </div>
      <div className={noVipDealsWrapper}>
        <img src={starBoxImage} alt="Box with star" />
        <div>
          {getText('noVipDealsTry')}
          <Button type="link" small linkTo={Routes.buyCredits} className={buyMore}>
            {getText('buyingHere')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PromoteDealBox.propTypes = {
  classAvalable: PropTypes.string,
  classEmpty: PropTypes.string,
};

export default PromoteDealBox;
