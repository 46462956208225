import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { Table } from 'antd';
import { disablePromoCode, enablePromoCode, generateCodePdf } from 'services/api/promoCodes';
import useError from 'services/errorHandling/useError';
import { codeListColumns } from './config';
import { table } from './styles';

const PromoCodesList = ({ promoCodesList, pagingInfo, setPageNumber, pageSize, changeStatus, isLoading }) => {
  const theme = useTheme();
  const { getText, language } = useTranslations();
  const { setError } = useError();

  const handleCodeStatus = (statusId, promoCodeId) =>
    statusId === 1 ? enablePromoCode(promoCodeId) : disablePromoCode(promoCodeId);

  const handleGeneratePdf = async id => {
    const newWindow = window.open();
    const [res, err] = await generateCodePdf(id, language.code);
    if (res) {
      const file = new Blob([res], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      newWindow.location = fileURL;
    } else setError(err);
  };

  return (
    <Table
      className={table(theme)}
      columns={codeListColumns(getText, theme, handleCodeStatus, handleGeneratePdf, changeStatus)}
      showHeader
      responsive
      loading={isLoading}
      bordered={false}
      dataSource={promoCodesList.length > 0 ? promoCodesList : null}
      pagination={{
        position: ['bottomCenter'],
        pageSize,
        showLessItems: true,
        total: pagingInfo?.count,
        hideOnSinglePage: true,
        onChange: page => setPageNumber(page),
      }}
    />
  );
};

PromoCodesList.propTypes = {
  promoCodesList: PropTypes.array,
  pagingInfo: PropTypes.object,
  setPageNumber: PropTypes.func,
  pageSize: PropTypes.number,
  changeStatus: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PromoCodesList;
