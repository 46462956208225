import React from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { Statistic } from 'antd';
import { TimerContainer } from './styles';

const CountdownTimer = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const utc = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
  const utcTomorrow = new Date(utc);
  utcTomorrow.setHours(23, 59, 59, 0);
  const result = Date.now() + (utcTomorrow - utc);

  return (
    <TimerContainer theme={theme}>
      <label>{getText('endsIn')}</label>
      <Statistic.Countdown value={new Date(result)} />
    </TimerContainer>
  );
};

export default CountdownTimer;
