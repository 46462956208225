import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '@veraio/core';
import { uniqueId, omit, isArray } from 'lodash-es';
import { useTheme } from 'emotion-theming';
import useError from 'services/errorHandling/useError';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { Pagination } from '../Pagination';
import { Skeleton } from '../Loader';
import { NoResults } from './NoResults';
import { tableContainer, containerBody, filterBarContainer } from './styles';

const omitInternal = el => omit(el, '_table');

const Table = forwardRef((props, reference) => {
  const {
    getDataMethod,
    getDataKey = 'items',
    columns,
    pageSize,
    emptyMessage: emptyMessageProp,
    filterBar,
    footerBar,
    className,
    updateOn,
    onSelect,
    hasPagination = true,
    hasSelection: hasSelectionProp,
    showLoader = true,
    renderRow,
    onRowClick,
    rowLink,
  } = props;

  const theme = useTheme();
  const [data, setData] = useState(null);
  const [emptyMessage, setEmptyMessage] = useState(emptyMessageProp);
  const hasSelection = isFunction(onSelect) || hasSelectionProp;
  const loaderRef = useRef();
  const paginationRef = useRef();
  const { setError } = useError();

  useImperativeHandle(reference, () => ({
    getData: params => getData(params),
    selectRow: row => selectRow(row),
    getSelected: () => data.filter(el => el._table.isSelected).map(omitInternal),
    deselectAll: () => setData(prev => prev.map(el => ({ ...el, _table: { ...el._table, isSelected: false } }))),
    changeEmptyMessage: setEmptyMessage,
  }));

  useEffect(
    () => {
      !hasPagination && getData();
    },
    isArray(updateOn) ? updateOn : [updateOn],
  );

  const getData = async options => {
    // Wrap all filters into spinner to indicate that results are coming
    showLoader && loaderRef.current?.showLoading();
    const [res, err] = await getDataMethod(options);

    showLoader && loaderRef.current?.hideLoading();
    if (err) return setError(err);

    setData(
      res
        ? res[getDataKey].map(el => ({
            ...el,
            _table: { isSelected: false, uuid: uniqueId() },
          }))
        : [],
    );

    return res;
  };

  const clearTableData = () => {
    setData([]);
    paginationRef.current?.resetFilters();
  };

  const selectRow = (value, row) => {
    // If row is not passed mark all rows from the table
    const compare = el => !row || el._table.uuid === row._table.uuid;

    setData(prev => {
      const newRows = prev.map(el => (compare(el) ? { ...el, _table: { ...el._table, isSelected: value } } : el));
      isFunction(onSelect) && onSelect(newRows.filter(el => el._table.isSelected).map(omitInternal));

      return newRows;
    });
  };

  const tableDataProps = {
    getData,
    data,
    setData,
    selectRow,
    setEmptyMessage,
    clearTableData,
    paginationRef,
  };

  return (
    <>
      {filterBar && <div className={filterBarContainer}>{filterBar(tableDataProps)}</div>}
      <div className={tableContainer(className, theme)}>
        <TableHead
          hasSelection={hasSelection}
          columns={columns}
          allSelected={data?.every(el => el?._table?.isSelected)}
          {...tableDataProps}
        />
        {data?.length === 0 ? (
          <NoResults emptyMessage={emptyMessage} />
        ) : !data ? (
          <Skeleton count={pageSize ?? 10} height={30} marginBottom={12} />
        ) : (
          <div className={containerBody}>
            <TableBody
              hasSelection={hasSelection}
              data={data}
              columns={columns}
              renderRow={renderRow}
              onRowClick={onRowClick}
              rowLink={rowLink}
              {...tableDataProps}
            />
          </div>
        )}
        {hasPagination && (
          <Pagination
            ref={paginationRef}
            justify="center"
            onChange={getData}
            {...(filterBar && { filterKeys: filterBar.filterKeys, requiredFilterKeys: filterBar.requiredFilterKeys })}
            {...props}
          />
        )}
        {footerBar && footerBar(tableDataProps)}
      </div>
    </>
  );
});

Table.propTypes = {
  getDataMethod: PropTypes.func,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  emptyMessage: PropTypes.string,
  filterBar: PropTypes.func,
  footerBar: PropTypes.func,
  updateOn: PropTypes.any,
  className: PropTypes.string,
  getDataKey: PropTypes.string,
  onSelect: PropTypes.func,
  hasPagination: PropTypes.bool,
  hasSelection: PropTypes.bool,
  pageSizes: PropTypes.array,
  showLoader: PropTypes.bool,
  renderRow: PropTypes.func,
  onRowClick: PropTypes.func,
  rowLink: PropTypes.any,
  withPageSize: PropTypes.bool,
  filterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  requiredFilterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Table;
