import React from 'react';
import PropTypes from 'prop-types';
import { useCurrencies, printPrice, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Page, Document, Image, Text, View } from '@react-pdf/renderer';
import { useTranslations } from '@veraio/strank';
import moment from 'moment';
import { styles } from './styles';

const StatisticsTemplate = ({
  imageSrc,
  totalSales,
  totalRevenue,
  soldCoupons,
  redeemedCoupons,
  fromDateSales,
  toDateSales,
}) => {
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const { getText } = useTranslations();

  const { container, header, overviewRow, overviewBox, imageStyles, footer } = styles;

  return (
    <Document>
      <Page style={container}>
        <View style={header}>
          <Text style={header.text}>{getText('statisticsOverview')}</Text>
          <Text style={header.date}>
            {fromDateSales} - {toDateSales}
          </Text>
        </View>
        <View style={overviewRow}>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{totalSales}</Text>
            <Text style={overviewBox.text}>{getText('totalSales')}</Text>
          </View>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>
              {printPrice({ fiat: totalRevenue, fiatDesiredCurrency: DEFAULT_FIAT_CURRENCY.code })} /{' '}
              {printPrice({ fiat: totalRevenue })}
            </Text>
            <Text style={overviewBox.text}>{getText('totalRevenue')}</Text>
          </View>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{soldCoupons}</Text>
            <Text style={overviewBox.text}>{getText('couponsSold')}</Text>
          </View>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{redeemedCoupons}</Text>
            <Text style={overviewBox.text}>{getText('couponsRedeemed')}</Text>
          </View>
        </View>
        <View>
          <Image style={imageStyles} src={imageSrc} />
        </View>
        <View>
          <Text style={footer.date}>{moment().format('DD-MM-yyyy')}</Text>
        </View>
      </Page>
    </Document>
  );
};

StatisticsTemplate.propTypes = {
  imageSrc: PropTypes.any,
  totalSales: PropTypes.number,
  totalRevenue: PropTypes.number,
  soldCoupons: PropTypes.number,
  redeemedCoupons: PropTypes.number,
  currency: PropTypes.any,
  fromDateSales: PropTypes.string,
  toDateSales: PropTypes.string,
};

export default StatisticsTemplate;
