import React from 'react';
import { useTranslations } from '@veraio/strank';
import { cryptoSign } from '@oneecosystem/dealshaker-core';
import { useTheme } from 'emotion-theming';
import { mainContainer } from './styles';

const PrivacyPolicy = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <>
      <div className={`content-container ${mainContainer(theme)}`}>
        <div className="terms-text-wrap">
          <p>{getText('privacyPolicyText', { isCrypto: cryptoSign() })}</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
