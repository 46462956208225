import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { isArray, isFunction, pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { deepEqual } from '@veraio/core';
import { calculateShoppingCart, useCurrencies } from '@oneecosystem/dealshaker-core';
import { useDeepEffect } from 'components/UIExternal';
import { useUser } from 'stores/User';
import { getCacheVal, setCacheVal } from 'utils';
import {
  getShoppingCart,
  addToShoppingCart,
  addPromoCodeToShoppingCart,
  updateShoppingCartDealQuantity,
  updateShoppingCartDelivery,
  updateShoppingCartPaymentMethods,
  removeDealFromShoppingCart,
  removeAllDealsFromShoppingCart,
  removePromoCodeFromShoppingCart,
} from 'services/api/shoppingCart';
import useError from 'services/errorHandling/useError';

const storageKey = 'shopping-cart';
const defaultState = {
  cashPaymentMethodId: null,
  cryptoPaymentMethodId: null,
  businesses: [],
};

export const ShoppingCartContext = createContext({});

export const useShoppingCart = () => useContext(ShoppingCartContext);

export const ShoppingCartProvider = ({ children }) => {
  const { setError } = useError();
  const { isAuthenticated } = useUser();
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies(currenciesState => currenciesState.selectedCurrency);
  const [state, setState] = useState(null);

  const setShoppingCart = data => {
    const newState = calculateShoppingCart(isFunction(data) ? data(state) : data);
    !deepEqual(newState, state) && setState(newState);
  };
  const emptyShoppingCart = () => setShoppingCart(defaultState);

  useDeepEffect(() => {
    if (selectedCurrency?.rates) isAuthenticated ? fetchShoppingCart() : loadFromLocalStorage();
  }, [isAuthenticated, selectedCurrency]);

  const fetchShoppingCart = async () => {
    const storage = getCacheVal(storageKey);
    const { businesses } = storage ?? {};

    // If the user is not logged in and have items to cart, after login we add those items to his cart and empty the local storage
    if (businesses?.length && businesses.every(business => business.items.length)) {
      const [res, err] = await addToShoppingCart(businesses.flatMap(el => el.items));
      err ? setError(err) : setShoppingCart(res);
      setCacheVal(storageKey, defaultState);
      return;
    }

    // User is authenticated and there is no items into local storage which should be transferred
    // Fetch the cart from API and fill the state with response data
    const [res, err] = await getShoppingCart();
    err ? setError(err) : setShoppingCart(res);
  };

  // If user is not authenticated we should check if there is some items into local storage
  // Shopping cart should be filled with those items
  const loadFromLocalStorage = () => {
    if (!getCacheVal(storageKey)?.businesses?.every(business => business?.items?.length))
      setCacheVal(storageKey, defaultState);

    setShoppingCart(getCacheVal(storageKey));
  };

  const addDeal = async item => {
    if (isAuthenticated) {
      // Add request require an array of id's, if user is logged pass hole deal object on normal flow
      // When you have items as not logged in user, after login you have to insert all items from anonymous cart to profile cart
      // This is a lot easier when you have request items as array
      const [res, err] = await addToShoppingCart(isArray(item) ? item : [item]);
      if (err) return setError(err);
      setShoppingCart(res);
    } else {
      const itemToAdd = {
        ...pick(item, ['id', 'name', 'dealDetails', 'deliveryMethodId', 'dealEndDate', 'price', 'currencyCode']),
        ...pick(item, ['percentRatio', 'discount', 'availableCoupons', 'maximumCouponsPerCustomer']),
        media: item?.media?.at(0),
        quantity: 1,
        selectedBusinessAddressId: null,
        selectedUserAddressId: null,
      };

      const businessToAdd = {
        businessId: item.businessId,
        businessName: item.businessName,
        items: [itemToAdd],
        businessAddresses: 'item.addresses',
        promoCode: null,
      };

      const newState = {
        ...state,
        businesses: state.businesses.find(el => el.businessId === item.businessId)
          ? state.businesses.map(business =>
              business.businessId === item.businessId
                ? {
                    ...business,
                    items: business.items.find(el => el.id === item.id)
                      ? business.items.map(el => (el.id === item.id ? { ...el, quantity: el.quantity + 1 } : el))
                      : business.items.concat(itemToAdd),
                  }
                : business,
            )
          : state.businesses.concat(businessToAdd),
      };

      setShoppingCart(newState);
      setCacheVal(storageKey, newState);
    }

    toast.success(getText('dealSuccessfullyAdded'), item);
  };

  const addPromoCode = async (businessId, code) => {
    const [res, err] = await addPromoCodeToShoppingCart({ businessId, code });
    if (err) return setError(err);
    setShoppingCart(res);
    toast.loading(getText('promoCodeSuccessfullyAdded'), { variant: 'success' });
  };

  const updateDealQuantity = async item => {
    if (isAuthenticated) {
      const [res, err] = await updateShoppingCartDealQuantity(item);
      if (err) return setError(err);
      setShoppingCart(res);
    } else {
      const newState = {
        ...state,
        businesses: state.businesses.map(business => ({
          ...business,
          items: business.items.map(deal => (deal.id === item.id ? { ...deal, quantity: item.quantity } : deal)),
        })),
      };
      setCacheVal(storageKey, newState);
      setShoppingCart(newState);
    }

    toast.success(getText('dealQuantityUpdated'), item);
  };

  const updateDeliveryAddress = async newBusinesses => {
    const [res, err] = await updateShoppingCartDelivery(newBusinesses.flatMap(el => el.items));
    err ? setError(err) : setShoppingCart(res);
    return res;
  };

  const resetDeliveryAddress = () =>
    setShoppingCart(prev => ({
      ...prev,
      businesses: prev.businesses.map(business => ({
        ...business,
        items: business.items.map(item => ({ ...item, selectedBusinessAddressId: null, selectedUserAddressId: null })),
      })),
    }));

  const updatePaymentMethods = async payments => {
    const [res, err] = await updateShoppingCartPaymentMethods({
      cashPaymentMethod: state.cashPaymentMethodId,
      cryptoPaymentMethod: state.cryptoPaymentMethodId,
      ...payments,
    });
    err ? setError(err) : setShoppingCart(res);
  };

  const removeDeal = async id => {
    if (isAuthenticated) {
      const [res, err] = await removeDealFromShoppingCart(id);
      if (err) return setError(err);

      setShoppingCart(res);
      toast.loading(getText('dealSuccessfullyRemoved'), { variant: 'success' });
      return res;
    }

    const newState = {
      ...state,
      businesses: state.businesses
        .map(business => ({
          ...business,
          items: business.items.filter(deal => deal.id !== id),
        }))
        .filter(business => business.items.length),
    };
    setCacheVal(storageKey, newState);
    setShoppingCart(newState.businesses.length ? newState : defaultState);

    toast.loading(getText('dealSuccessfullyRemoved'), { variant: 'success' });
  };

  const removeAllDeals = async () => {
    if (isAuthenticated) {
      const [, err] = await removeAllDealsFromShoppingCart();
      if (err) return setError(err);
    } else setCacheVal(storageKey, defaultState);

    emptyShoppingCart();

    toast.loading(getText('allDealsSuccessfullyRemoved'), { variant: 'success' });
  };

  const removePromoCode = async business => {
    const [, err] = await removePromoCodeFromShoppingCart({
      businessId: business.businessId,
      code: business.promoCode.code,
    });
    if (err) return setError(err);
    setShoppingCart(prev => ({
      ...prev,
      businesses: prev.businesses.map(el => (el.businessId !== business.businessId ? el : { ...el, promoCode: null })),
    }));
    toast.loading(getText('promoCodeSuccessfullyRemoved'), { variant: 'success' });
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart: state,
        addDeal,
        addPromoCode,
        updateDealQuantity,
        updateDeliveryAddress,
        resetDeliveryAddress,
        updatePaymentMethods,
        removeDeal,
        removeAllDeals,
        removePromoCode,
        emptyShoppingCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};
