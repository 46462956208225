import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isNil } from '@veraio/core';
import { findFiatCurrency, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { InputNumber } from 'antd';
import { Button, Dropdown } from 'components/UIExternal';
import { useUser } from 'stores';
import { promoCodeType, promoCodeTypeSelect } from 'enums';
import { createPromoCode } from 'services/api/promoCodes';
import useError from 'services/errorHandling/useError';
import PromoCodeSuccess from 'assets/images/promoCodeSuccess.svg';
import { container, header, valueBlock, discount, titleLabel, successBlock, backBtn, validateError } from './styles';

const CreatePromoCode = ({ goBack }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const { currentAccountBusinesses } = useUser();
  const [success, setSuccess] = useState(false);
  const [createData, setCreateData] = useState({ typeId: 1, value: null, businessId: null });

  const selectedBusiness = currentAccountBusinesses?.find(el => el.id === createData?.businessId)?.currencyCode
    ?.currentValue;
  const businessCurrency = findFiatCurrency(selectedBusiness) ?? DEFAULT_FIAT_CURRENCY;
  const registeredBusinessesOptions = currentAccountBusinesses.map(business => ({
    label: getDynamicTranslation(business.name)?.name?.currentValue,
    value: business?.id,
  }));

  const handleCreatePromoCode = async () => {
    const data =
      createData?.typeId === promoCodeType.percent
        ? { ...createData, value: Number(createData?.value).toFixed(0) }
        : createData;

    const [, err] = await createPromoCode(data);
    err ? setError(err) : setSuccess(true);
  };

  return (
    <div className={container}>
      {!success ? (
        <>
          <div className={header(theme)}>{getText('generatePromoCode')}</div>
          <div className={valueBlock(theme)}>
            <label className={titleLabel}>{getText('business')}</label>
            <Dropdown
              placeholder={getText('selectHere')}
              options={registeredBusinessesOptions}
              mapValue={data => data?.value}
              onChange={value => setCreateData(prev => ({ ...prev, businessId: value }))}
            />
          </div>
          <div className={valueBlock(theme)}>
            <label className={titleLabel}>{getText('value')}</label>
            <Dropdown
              noClear
              options={promoCodeTypeSelect(getText)}
              value={createData?.typeId}
              onChange={({ value }) => setCreateData(prev => ({ ...prev, value: '', typeId: value }))}
            />
          </div>
          <div className={discount}>
            <label className={titleLabel}>{getText('discount')}</label>
            <InputNumber
              changeOnWheel
              type="number"
              controls={false}
              prefix={createData?.typeId === promoCodeType.percent ? '%' : businessCurrency.symbol}
              value={createData?.value}
              precision={createData?.typeId === promoCodeType.percent ? 0 : 2}
              onChange={value => setCreateData(prev => ({ ...prev, value: value ? Number(value)?.toFixed(2) : '' }))}
              {...(createData?.typeId === promoCodeType.percent && { min: 1 })}
              {...(createData?.typeId === promoCodeType.percent && { max: 100 })}
            />
            {createData?.typeId === promoCodeType.percent && (isNil(createData?.value) || createData?.value === '') && (
              <span className={validateError(theme)}>
                {getText('percentShouldBeBetweenStartEnd', { start: 1, end: 100 })}
              </span>
            )}
          </div>
          <Button
            disabled={
              !createData?.businessId ||
              !createData?.typeId ||
              !createData?.value ||
              (createData?.value > 100 && createData?.typeId === 2) ||
              (createData?.typeId === 1 && createData?.value < 1) ||
              createData?.value < 0
            }
            type="primary"
            onClick={handleCreatePromoCode}
          >
            {getText('createPromoCode')}
          </Button>
          <Button type="link" onClick={() => goBack('newCodeBlock', false)}>
            {getText('back')}
          </Button>
        </>
      ) : (
        <div className={successBlock(theme)}>
          <div className="successMsg">
            <div className="img">
              <img src={PromoCodeSuccess} alt="success" />
            </div>
            <p className="message">{getText('successCreatePromoCode')}</p>
          </div>
          <Button
            type="primary"
            className={backBtn}
            onClick={() => goBack(prev => ({ ...prev, newCodeBlock: false, applyCode: !prev.applyCode }))}
          >
            {getText('back')}
          </Button>
        </div>
      )}
    </div>
  );
};

CreatePromoCode.propTypes = {
  goBack: PropTypes.func,
};

export default CreatePromoCode;
