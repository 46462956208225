import React from 'react';
import { pick, concatStrings } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { printPrice, DEFAULT_FIAT_CURRENCY, DEFAULT_CRYPTO_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Tag, ButtonArrow, Row, Col } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { changeOrderCashPaymentStatus, useOrders, selectedOrderPaymentDetails } from 'stores';
import {
  orderStatus,
  paymentStatus,
  paymentStatusColor,
  paymentStatusInverse,
  paymentStatusSelect,
  PAYMENT_METHODS_CURRENCIES,
} from 'enums';
import { displayProperty } from 'utils';
import { paymentTypeBoxesConfig, bankDetailsInfo } from './config';
import { orderDetailsPaymentContainer } from './styles';

export const OrderDetailsPayment = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const {
    id: orderId,
    payment,
    totalPriceCrypto,
    totalPriceFiat,
    editable,
    bankDetails,
    statusId: statusOrder,
    currencyCode,
  } = useOrders(ordersState => ({
    bankDetails: ordersState?.selectedOrder?.business?.bankDetails,
    ...pick(ordersState?.selectedOrder, [
      'totalPriceCrypto',
      'totalPriceFiat',
      'id',
      'statusId',
      'payment',
      'currencyCode',
    ]),
    editable: ordersState?.editable,
  }));
  const {
    isFiatManualPayment,
    isFiatPaymentBank,
    isAutomaticPaymentsCompleted,
    isFiatPaymentPending,
    isFiatPaymentCompleted,
  } = selectedOrderPaymentDetails();
  const cashConfig = paymentTypeBoxesConfig[payment?.cashPaymentMethodId];
  const cryptoConfig = paymentTypeBoxesConfig[payment?.cryptoPaymentMethodId];
  const isEditable = editable && statusOrder === orderStatus.new;
  const printPriceProps = {
    fiatOriginCurrency: currencyCode,
    cryptoDesiredCurrency: (PAYMENT_METHODS_CURRENCIES[payment?.cryptoPaymentMethodId] ?? DEFAULT_CRYPTO_CURRENCY)
      ?.code,
  };

  const paymentOptionsConfig = paymentStatusSelect
    .filter(el => el.value !== paymentStatus.declined)
    .map(option => ({
      ...option,
      color: paymentStatusColor[option.value],
      label: getText(option.label),
      disabled: option.value === paymentStatus.completed || !isAutomaticPaymentsCompleted,
    }));

  return (
    <>
      <h3 className="order-details-section-title">{getText('payment')}</h3>
      <section
        className={concatStrings('order-details-section', orderDetailsPaymentContainer(theme))}
        id="order-details-payment-section"
      >
        <div className="order-details-payment-info-row">
          <h5 className="gray order-details-payment-info-label">{getText('type')}</h5>
          <span>
            {getText(cashConfig?.title)} + {getText(cryptoConfig?.title)}
          </span>
        </div>

        <div className="order-details-payment-info-row">
          <h5 className="gray order-details-payment-info-label">{getText('totalAmount')}</h5>
          <div>
            <p>
              {printPrice({
                fiat: totalPriceFiat,
                fiatDesiredCurrency: currencyCode,
                crypto: totalPriceCrypto,
                ...printPriceProps,
              })}
            </p>
            {currencyCode !== DEFAULT_FIAT_CURRENCY.code && !isFiatManualPayment && (
              <p>
                {printPrice({
                  fiat: totalPriceFiat,
                  fiatDesiredCurrency: DEFAULT_FIAT_CURRENCY.code,
                  crypto: totalPriceCrypto,
                  ...printPriceProps,
                })}
              </p>
            )}
          </div>
        </div>

        <h4>{getText('paymentStatus')}</h4>
        <div className="order-details-payment-method-card">
          <Icon iconName={cryptoConfig?.icon} className="order-details-payment-method-avatar" />
          <div className="order-details-payment-method">
            <h5>{getText(cryptoConfig?.title)}</h5>
            <p className="gray order-details-payment-sub-label">{getText(cryptoConfig?.label)}</p>
          </div>
          <div className="order-details-payment-price-container">
            <h5 className="order-details-payment-price">
              {printPrice({ ...printPriceProps, crypto: totalPriceCrypto })}
            </h5>
            <Tag type={paymentStatusColor[payment?.cryptoPaymentStatus]} fontSize="0.8rem">
              {paymentStatusInverse[payment?.cryptoPaymentStatus]}
            </Tag>
          </div>
        </div>

        {!!totalPriceFiat && (
          <div className="order-details-payment-method-card">
            <Icon iconName={cashConfig?.icon} className="order-details-payment-method-avatar" />
            <div className="order-details-payment-method">
              <h5>{getText(cashConfig?.title)}</h5>
              <p className="gray order-details-payment-sub-label">{getText(cashConfig?.label)}</p>
            </div>
            <div className="order-details-payment-price-container">
              <h5 className="order-details-payment-price">
                {printPrice({
                  fiat: totalPriceFiat,
                  ...printPriceProps,
                  fiatDesiredCurrency: isFiatManualPayment ? currencyCode : DEFAULT_FIAT_CURRENCY.code,
                })}
              </h5>
              {isEditable && isFiatManualPayment && isAutomaticPaymentsCompleted && !isFiatPaymentCompleted ? (
                <ButtonArrow
                  value={payment?.cashPaymentStatus}
                  onChange={statusId => changeOrderCashPaymentStatus(orderId, statusId)}
                  options={paymentOptionsConfig}
                />
              ) : (
                <Tag type={paymentStatusColor[payment?.cashPaymentStatus]} fontSize="0.8rem">
                  {paymentStatusInverse[payment?.cashPaymentStatus]}
                </Tag>
              )}
            </div>
          </div>
        )}
        {!editable && isFiatPaymentBank && !!totalPriceFiat && isFiatPaymentPending && (
          <section className="order-details-payment-bank-details">
            <h4 className="order-details-payment-bank-details-title">{getText('bankDetails')}</h4>

            <Row gap={12}>
              {bankDetailsInfo.map((el, ind) => (
                <Col key={ind} lg={4} md={4} sm={6} xs={12}>
                  <h5>{getText(`${el.label}`)}</h5>
                  <span className="bankDetailsProperty">{displayProperty(el, bankDetails)}</span>
                </Col>
              ))}
            </Row>
          </section>
        )}
      </section>
    </>
  );
};
