import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import {
  printPrice,
  hasCryptoAmountForCart,
  useCurrencies,
  DEFAULT_FIAT_CURRENCY,
} from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { useUser } from 'stores';
import { promoCodeType, PAYMENT_METHODS_CURRENCIES } from 'enums';
import { mb } from 'assets/css/globalCSS';
import { summaryBox, spaceRowSection, rightText, totalSection, regularTextStyles, goToCheckoutButton } from './styles';

const SummaryPricesCard = ({ shoppingCart, linkTo }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { currentAccount } = useUser();
  const selectedCurrency = useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const isFiatDiscount = promoCode => promoCode.typeId === promoCodeType.fiat;
  const promoCodePrice = promoCode =>
    isFiatDiscount(promoCode)
      ? printPrice({ fiat: promoCode.value, fiatOriginCurrency: promoCode.currencyCode })
      : `${promoCode.value}%`;
  const hasPromoCodes = shoppingCart.businesses?.some(el => el.promoCode);
  const insufficientFundsError = hasCryptoAmountForCart({
    shoppingCart,
    coinWalletBalance: currentAccount.coinWalletBalance,
    oespWalletBalance: currentAccount.oespWalletBalance,
  });
  const cartTotal = shoppingCart.discountedTotalLocalPrice ?? shoppingCart.totalLocalPrice;
  const cartFiatTotal = shoppingCart.discountedTotalLocalPriceFiat ?? shoppingCart.totalLocalPriceFiat;
  const cartCryptoTotal = shoppingCart.discountedTotalPriceCrypto ?? shoppingCart.totalPriceCrypto;
  const cryptoDesiredCurrency = PAYMENT_METHODS_CURRENCIES[shoppingCart.cryptoPaymentMethodId]?.code;
  const businessesFiatCurrency = selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code;

  return (
    <div className={summaryBox}>
      {hasPromoCodes && (
        <>
          <div className={spaceRowSection(theme.gray300)}>
            <p className="subtotal">{getText('subtotal')}</p>
            <p
              className={rightText}
              data-id="shopping-cart-subtotal"
              data-fiat={shoppingCart.totalLocalPriceFiat}
              data-fiat-base={shoppingCart.totalLocalPriceFiat}
              data-fiat-currency={businessesFiatCurrency}
              data-crypto={shoppingCart.totalPriceCrypto}
              data-crypto-currency={cryptoDesiredCurrency}
            >
              {printPrice({
                fiat: shoppingCart.totalLocalPriceFiat,
                fiatOriginCurrency: businessesFiatCurrency,
                crypto: shoppingCart.totalPriceCrypto,
              })}
            </p>
          </div>
          {shoppingCart.businesses
            ?.filter(business => business?.promoCode)
            .map(({ promoCode }) => (
              <div className={spaceRowSection(theme.green2)} key={promoCode.code}>
                <span data-id="promo-code-text">{promoCode.code}</span>
                <span
                  data-id="promo-code-discount"
                  data-fiat={promoCode.discountedPriceFiat}
                  data-fiat-currency={businessesFiatCurrency}
                  data-crypto={promoCode.discountedPriceCrypto}
                  data-crypto-currency={cryptoDesiredCurrency}
                  data-type={isFiatDiscount(promoCode) ? 'fiat' : 'percent'}
                  data-value={promoCode.value}
                >{`- ${promoCodePrice(promoCode)} (${printPrice({
                  fiat: promoCode.discountedPriceFiat,
                  fiatOriginCurrency: promoCode.currencyCode,
                  fiatCrypto: promoCode.discountedPriceCrypto,
                })})`}</span>
              </div>
            ))}
          <hr className={mb({ lg: 16, span: 16 })} />
        </>
      )}
      <div className={totalSection}>
        <label>{getText('total')}:</label>
        <p
          className={rightText}
          data-id="shopping-cart-total"
          data-fiat={cartFiatTotal}
          data-fiat-currency={businessesFiatCurrency}
          data-crypto={cartCryptoTotal}
          data-crypto-currency={cryptoDesiredCurrency}
        >
          {printPrice({
            fiat: cartFiatTotal,
            fiatOriginCurrency: businessesFiatCurrency,
            crypto: cartCryptoTotal,
            cryptoDesiredCurrency,
          })}
        </p>
      </div>
      <p
        className={regularTextStyles(theme)}
        data-id="shopping-cart-regular-price"
        data-fiat={cartTotal}
        data-fiat-currency={businessesFiatCurrency}
      >{`${getText('regular-price')}: ${printPrice({
        fiat: cartTotal,
        fiatOriginCurrency: businessesFiatCurrency,
      })}`}</p>
      {linkTo && (
        <Button className={goToCheckoutButton(theme)} linkTo={linkTo} disabled={!!insufficientFundsError}>
          {getText('goToCheckout')}
          {insufficientFundsError && <span className="insufficientFunds">({getText(insufficientFundsError)})</span>}
        </Button>
      )}
    </div>
  );
};

SummaryPricesCard.propTypes = {
  shoppingCart: PropTypes.object,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SummaryPricesCard;
