import { css } from 'emotion';

export const paginationContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
});

export const shownResultsIndicator = theme =>
  css({
    color: theme.gray200,
    textAlign: 'center',
    fontSize: '0.6875rem',
    marginRight: '0.75em',
  });

export const paginationButton = (disabled, isActive, theme) =>
  css(
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      width: '2rem',
      height: '2rem',
      margin: '0 0.125em',
      borderRadius: '50%',

      '&:hover': !disabled &&
        !isActive && {
          backgroundColor: theme.gray400,
        },
    },
    isActive && {
      backgroundColor: theme.gray600,
    },
    disabled && {
      cursor: 'not-allowed',
      color: theme.gray300,
    },
  );

export const pageSizeDropdown = css({
  width: '5rem',
});
