import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { Button, Modal, Icon, MerchantAddressMap } from 'components';
import { setError } from 'services';
import { seeMeOnMap, modalContainer } from './styles';

export const SeeOnMapAddresses = ({ merchantAddressesList, label, type, className, fullWidth }) => {
  const theme = useTheme();
  const [userCoords, setUserCoords] = useState(null);
  const mapRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    mapRef.current?._containerId &&
      mapRef.current.flyToBounds(
        merchantAddressesList.map(el => [el.latitude, el.longitude]),
        { animate: true, duration: 1.4 },
      );
  }, [mapRef.current?._containerId]);

  const handleOpenModal = () => {
    modalRef.current?.open();
    getUserCoordinates();
  };

  const getUserCoordinates = () =>
    new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(
        event => {
          const coords = { lat: event.coords.latitude, lng: event.coords.longitude };
          setUserCoords(coords);
          resolve(coords);
        },
        error => {
          resolve();
          setUserCoords(undefined);
          setError(error);
        },
      );
    });

  return (
    <div className={className}>
      <Button small fullWidth={fullWidth} type={type} onClick={handleOpenModal} className={seeMeOnMap(theme)}>
        <Icon iconName="las la-map" />
        {getText(label)}
      </Button>
      <Modal large ref={modalRef} className={modalContainer} positioned={isMobile && 'bottom'}>
        <MerchantAddressMap mapRef={mapRef} merchantAddressesList={merchantAddressesList} userPosition={userCoords} />
      </Modal>
    </div>
  );
};

SeeOnMapAddresses.propTypes = {
  merchantAddressesList: PropTypes.array,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};
