import React from 'react';
import { useTheme } from 'emotion-theming';
import { OrderDetailsHeader } from './Header';
import { OrderDetailsFulfill } from './Fulfill';
import { OrderDetailsUser } from './User';
import { OrderDetailsDeals } from './Deals';
import { OrderDetailsDelivery } from './Delivery';
import { OrderDetailsPayment } from './Payment';
import { orderDetailsContainer } from './styles';

export const OrderDetails = () => {
  const theme = useTheme();

  return (
    <section className={orderDetailsContainer(theme)}>
      <OrderDetailsHeader />
      <hr />
      <OrderDetailsFulfill />
      <OrderDetailsUser />
      <OrderDetailsDeals />
      <OrderDetailsDelivery />
      <OrderDetailsPayment />
    </section>
  );
};
