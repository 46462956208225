import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const headingPanel = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1.125em',
  gap: '1.25em',
  [Mobile]: {
    flexDirection: 'column',
    alignItems: 'flexStart',
  },
});

export const title = css({
  fontSize: '1.125rem',
  [Mobile]: { fontSize: '0.875rem' },
  fontWeight: 700,
});

export const flex = css({
  display: 'flex !important',
});

export const wrapper = hideClones =>
  css({
    '& .slick-cloned': { display: hideClones && 'none !important' },
    '& .slick-track': { margin: hideClones && '0 !important' },
    '& .slick-list': {
      margin: '0 -0.9375em',
      padding: '0px !important',
    },
    '& .slick-slide > div': {
      padding: '0 0.9375em',
    },
    '& .slick-arrow:before': {
      display: 'none',
    },
  });

export const arrowButton = position =>
  css({
    position: 'absolute',
    top: '50%',
    cursor: 'pointer',
    zIndex: 900,
    margin: position === 'right' ? '0 0 0 1.25em' : '0 0 0 -1.25em',
  });

export const userProfileImage = css({
  height: '1.6875rem',
  marginRight: '0.4375em',
  borderRadius: '20px',
});

export const horizontalLine = theme =>
  css({
    flex: 1,
    height: 2,
    width: '100%',
    [Mobile]: {
      marginTop: '0.3125em',
    },
    backgroundColor: theme.primary_orange,
  });

export const userInfoContainer = css({
  display: 'flex',
  alignItems: 'center',
  flex: 2,
  gap: '1.25em',
});
