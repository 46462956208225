import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { usedByCode, codeValue } from '../styles';

const CodeTag = ({ usedBy, code }) => (
  <div className={usedByCode}>
    <p>{usedBy}</p>
    <div className={codeValue}>
      <span className="bolder-text">{code}</span>
      <Button className="clipboard-btn" onClick={() => navigator.clipboard.writeText(code)}>
        <FeatherIcon icon="copy" size={16} />
      </Button>
    </div>
  </div>
);

CodeTag.propTypes = { usedBy: PropTypes.string, code: PropTypes.string };

export default CodeTag;
