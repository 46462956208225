import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isNil, isNumber } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import {
  useLocations,
  printPrice,
  CRYPTO_CURRENCIES,
  useCurrencies,
  DEFAULT_CRYPTO_CURRENCY,
} from '@oneecosystem/dealshaker-core';
import { Button, CheckButton } from 'components/UIExternal';
import { PaymentMethodWallet } from 'components/screens';
import { paymentMethods, PAYMENT_METHODS_CURRENCIES } from 'enums';
import { useUser } from 'stores';
import { actionButtons } from '../styles';
import { paymentMethodsContainer, paymentMethodTitle, paymentMethodContent, paymentMethodCheckbox } from './styles';

const basicCheckboxConfig = {
  size: '0.8rem',
  colorChecked: 'primary_blue',
  colorUnChecked: 'skyblue',
};

const CheckoutPaymentMethods = props => {
  const { shoppingCart, onBack, onSubmit, goNext } = props;
  const { getText } = useTranslations();
  const { currentAccount } = useUser();
  const selectedCurrency = useCurrencies(currenciesStore => currenciesStore?.selectedCurrency);
  const geoLocation = useLocations(locationsState => locationsState.geoLocation);
  const theme = useTheme();
  const isRestricted = geoLocation?.isCryptoRestricted;
  const { cashWalletBalance, coinWalletBalance, oespWalletBalance } = currentAccount;
  const hasEuro = isNumber(shoppingCart?.totalBasePriceFiat);
  const totalBasePriceFiat = shoppingCart?.discountedTotalBasePriceFiat ?? shoppingCart?.totalBasePriceFiat;
  const totalLocalPriceFiat = shoppingCart?.discountedTotalLocalPriceFiat ?? shoppingCart?.totalLocalPriceFiat;
  const totalPriceCrypto = shoppingCart?.discountedTotalPriceCrypto ?? shoppingCart?.totalPriceCrypto;
  const insufficientEuro = totalBasePriceFiat > cashWalletBalance;
  const insufficientOne = totalPriceCrypto > coinWalletBalance;
  const insufficientOesp = totalPriceCrypto > oespWalletBalance;

  useEffect(() => {
    isNil(shoppingCart?.cashPaymentMethodId) && isNil(shoppingCart?.cryptoPaymentMethodId) && selectDefaultPayments();
  }, []);

  const cashPaymentsProps = type => ({
    ...basicCheckboxConfig,
    checked: shoppingCart?.cashPaymentMethodId === type,
    disabled: shoppingCart?.cashPaymentMethodId === type,
    onChange: () => onSubmit({ cashPaymentMethod: type }),
    className: paymentMethodCheckbox(type === paymentMethods.cashWallet),
  });

  const cryptoPaymentsProps = type => ({
    ...basicCheckboxConfig,
    checked: shoppingCart?.cryptoPaymentMethodId === type,
    onChange: () => onSubmit({ cryptoPaymentMethod: type }),
    className: paymentMethodCheckbox(true),
  });

  const selectDefaultPayments = () => {
    const defaultPaymentMethods = {
      cashPaymentMethod: paymentMethods.cashWallet,
      cryptoPaymentMethod: paymentMethods.oneWallet,
    };

    if (insufficientEuro) defaultPaymentMethods.cashPaymentMethod = paymentMethods.cashOnDelivery;
    if (isRestricted || insufficientOne) defaultPaymentMethods.cryptoPaymentMethod = paymentMethods.oespWallet;

    onSubmit(defaultPaymentMethods);
  };

  return (
    <>
      {hasEuro && (
        <>
          <h4 className={paymentMethodTitle}>
            {getText('payWithAmount', {
              amount: printPrice({ fiat: totalLocalPriceFiat, fiatOriginCurrency: selectedCurrency?.code }),
            })}
          </h4>
          <section className={paymentMethodsContainer(theme)}>
            <div className={paymentMethodContent}>
              <CheckButton
                {...cashPaymentsProps(paymentMethods.cashWallet)}
                disabled={shoppingCart?.cashPaymentMethodId === paymentMethods.cashWallet || insufficientEuro}
              >
                <PaymentMethodWallet
                  label={getText('cashWallet')}
                  price={{
                    totalBasePrice: totalBasePriceFiat,
                    totalLocalPrice: totalLocalPriceFiat,
                    originCurrency: selectedCurrency?.code,
                  }}
                  balanceLabel={printPrice({ fiat: cashWalletBalance })}
                  insufficientFunds={insufficientEuro}
                />
              </CheckButton>
              <hr />
              <CheckButton {...cashPaymentsProps(paymentMethods.cashOnDelivery)}>
                <h5 className="title">{getText('cashAtDelivery')}</h5>
              </CheckButton>
              <hr />
              <CheckButton {...cashPaymentsProps(paymentMethods.bankTransfer)}>
                <h5 className="title">{getText('bankTransfer')}</h5>
              </CheckButton>
            </div>
          </section>
        </>
      )}

      <h4 className={paymentMethodTitle}>
        {getText('payWithAmount', {
          amount: printPrice({
            crypto: totalPriceCrypto,
            cryptoDesiredCurrency:
              PAYMENT_METHODS_CURRENCIES[shoppingCart.cryptoPaymentMethodId]?.symbol ?? DEFAULT_CRYPTO_CURRENCY?.symbol,
          }),
        })}
      </h4>
      <section className={paymentMethodsContainer(theme)}>
        <div className={paymentMethodContent}>
          {!isRestricted && (
            <>
              <CheckButton
                {...cryptoPaymentsProps(paymentMethods.oneWallet)}
                disabled={shoppingCart?.cryptoPaymentMethodId === paymentMethods.oneWallet || insufficientOne}
              >
                <PaymentMethodWallet
                  label={getText('oneWallet')}
                  balanceLabel={printPrice({
                    crypto: coinWalletBalance,
                    cryptoDesiredCurrency: CRYPTO_CURRENCIES.ONE.code,
                  })}
                  insufficientFunds={insufficientOne}
                />
              </CheckButton>
              <hr />
            </>
          )}
          <CheckButton
            {...cryptoPaymentsProps(paymentMethods.oespWallet)}
            disabled={shoppingCart?.cryptoPaymentMethodId === paymentMethods.oespWallet || insufficientOesp}
          >
            <PaymentMethodWallet
              label={getText('oespWallet')}
              balanceLabel={printPrice({
                crypto: oespWalletBalance,
                cryptoDesiredCurrency: CRYPTO_CURRENCIES.OESP.code,
              })}
              insufficientFunds={insufficientOesp}
            />
          </CheckButton>
        </div>
      </section>

      <div className={actionButtons}>
        <Button type="default" small onClick={onBack}>
          {getText('back')}
        </Button>
        <Button
          small
          onClick={() => goNext()}
          disabled={!shoppingCart?.cashPaymentMethodId || !shoppingCart?.cryptoPaymentMethodId}
        >
          {getText('next')}
        </Button>
      </div>
    </>
  );
};

CheckoutPaymentMethods.propTypes = {
  onBack: PropTypes.func,
  shoppingCart: PropTypes.object,
  onSubmit: PropTypes.func,
  goNext: PropTypes.func,
};

export default CheckoutPaymentMethods;
