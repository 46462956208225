import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, printPrice } from '@oneecosystem/dealshaker-core';
import { useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Row, Col, Spin, Modal } from 'antd';
import { toast } from 'react-hot-toast';
import { Button } from 'components/UIExternal';
import InformationalPopover from 'components/popups/InformationalPopover';
import { useUser } from 'stores';
import useError from 'services/errorHandling/useError';
import { formatDefaultDate } from 'utils/valueFormatter';
import { getCreditPacks, getAvailableCredits, buyCredits } from 'services/api/creditsService';
import { getPromotionsDealsInfo } from 'services/api/promotionService';
import starBoxImg from 'assets/images/addDeal/star-box.svg';
import emptyBoxImg from 'assets/images/noNotifications.svg';
import { mb, mt } from 'assets/css/globalCSS';
import {
  titleWrapper,
  creditPackBox,
  selectedCreditPackBox,
  creditCard,
  creditTitleWrapper,
  bestDealLabel,
  selectBtn,
  availableCreditsBox,
  noCreditsBox,
  creditsTitle,
  creditsLeftWrapper,
  promotedDealsWrapper,
  noVipDealsWrapper,
  cashWalletWrapper,
  amountWrapper,
  amountDetails,
  modalSummary,
} from './styles';

const BuyCreditsPage = () => {
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const theme = useTheme();
  const history = useHistory();
  const redirectUrl = useLocation()?.state?.redirectUrl || false;
  const { getText } = useTranslations();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currentAccount, updateCurrentAccount } = useUser();
  const { setError } = useError();
  const [creditPacks, setCreditPacks] = useState();
  const [promotedDeals, setPromotedDeals] = useState();
  const [availableCredits, setAvailableCredits] = useState();
  const [selectedPack, setSelectedPack] = useState();
  const showHideModal = () => setIsModalVisible(!isModalVisible);

  useEffect(() => {
    getCreditPacks(setError).then(res => res && setCreditPacks(res));
    getPromotionsDealsInfo(setError).then(res => res && setPromotedDeals(res.promotedDealsCount));
    getAvailableCredits(setError).then(res => res && setAvailableCredits(res));
  }, []);

  const handleBuying = () => {
    selectedPack &&
      buyCredits(selectedPack.id, setError).then(resCode => {
        if (resCode === 200) {
          getAvailableCredits(setError).then(res => {
            if (res) {
              setAvailableCredits(res);
              toast.success(getText('successfullyBought'));
              redirectUrl && history.push(redirectUrl);
            }
          });
          setSelectedPack(null);
          updateCurrentAccount();
        }
      });
  };

  return (
    <div className="content-container">
      <div className={titleWrapper(theme)}>
        <h2>{getText('buyCredits')}</h2>
        <p>{getText('selectRightPackage')}</p>
      </div>
      {creditPacks && availableCredits ? (
        <>
          <Row className={mb({ lg: 30, span: 30 })} gutter={[24, 24]}>
            {isMobile && (
              <Col className="gutter-row" span={24}>
                {availableCredits?.credits > 0 ? (
                  <div className={availableCreditsBox(theme)}>
                    <div className={creditsTitle(theme)}>
                      <div>
                        <label>{getText('availableCredits')}</label>
                        <p>
                          {getText('boughtOnDate', {
                            date: availableCredits?.lastPurchaseDate
                              ? formatDefaultDate(availableCredits.lastPurchaseDate)
                              : 'N/A',
                          })}
                        </p>
                      </div>
                      <InformationalPopover text={getText('useCreditsToPromoteDeal')} />
                    </div>
                    <div className={creditsLeftWrapper(theme)}>
                      <label>{availableCredits?.credits}</label>
                      <p>{getText('creditsLeft')}</p>
                    </div>
                    <div className={promotedDealsWrapper(theme)}>
                      <label>{getText('currentlyPromotedDeals')}</label>
                      <label>{promotedDeals}</label>
                    </div>
                  </div>
                ) : (
                  <div className={noCreditsBox}>
                    <div className={creditsTitle(theme)}>
                      <label>{getText('noAvailableCredits')}</label>
                      <InformationalPopover text={getText('promoteDealsAttractVisits')} />
                    </div>
                    <div className={noVipDealsWrapper(theme)}>
                      <img src={emptyBoxImg} alt="Empty box" />
                      <label>{getText('noVipDeal')}</label>
                    </div>
                  </div>
                )}
              </Col>
            )}
            {creditPacks?.map(creditPack => (
              <Col className="gutter-row" lg={6} span={24}>
                <div
                  className={`${creditCard(theme)} ${
                    selectedPack?.name === creditPack.name ? selectedCreditPackBox(theme) : creditPackBox(theme)
                  }`}
                >
                  <img src={starBoxImg} alt={creditPack.name} />
                  <div className={creditTitleWrapper}>
                    <h6>{creditPack.name}</h6>
                    <label className={bestDealLabel(theme, creditPack.isBestDeal)}>{getText('bestDeal')}</label>
                  </div>
                  <p>
                    {`${printPrice({ fiat: creditPack.price })} ${getText('for').toLowerCase()} ${
                      creditPack.creditCount
                    } ${getText('credits')}`}
                  </p>
                  <Button
                    type="secondary"
                    small
                    className={selectBtn}
                    onClick={() => {
                      selectedPack?.name === creditPack.name
                        ? setSelectedPack(null)
                        : setSelectedPack({ name: creditPack.name, price: creditPack.price, id: creditPack.id });
                    }}
                  >
                    {selectedPack?.name === creditPack.name ? getText('deselect') : getText('select')}
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
          <Row className={mb({ lg: 58, span: 30 })} gutter={[24, 24]}>
            {!isMobile && (
              <Col className="gutter-row" lg={{ offset: 12, span: 6 }} span={24}>
                {availableCredits?.credits > 0 ? (
                  <div className={availableCreditsBox(theme)}>
                    <div className={creditsTitle(theme)}>
                      <div>
                        <label>{getText('availableCredits')}</label>
                        <p>
                          {getText('boughtOnDate', {
                            date: availableCredits?.lastPurchaseDate
                              ? formatDefaultDate(availableCredits.lastPurchaseDate)
                              : 'N/A',
                          })}
                        </p>
                      </div>
                      <InformationalPopover text={getText('useCreditsToPromoteDeal')} />
                    </div>
                    <div className={creditsLeftWrapper(theme)}>
                      <label>{availableCredits?.credits}</label>
                      <p>{getText('creditsLeft')}</p>
                    </div>
                    <div className={promotedDealsWrapper(theme)}>
                      <label>{getText('currentlyPromotedDeals')}</label>
                      <label>{promotedDeals}</label>
                    </div>
                  </div>
                ) : (
                  <div className={noCreditsBox}>
                    <div className={creditsTitle(theme)}>
                      <label>{getText('noAvailableCredits')}</label>
                      <InformationalPopover text={getText('promoteDealsAttractVisits')} />
                    </div>
                    <div className={noVipDealsWrapper(theme)}>
                      <img src={emptyBoxImg} alt="Empty box" />
                      <label>{getText('noVipDeal')}</label>
                    </div>
                  </div>
                )}
              </Col>
            )}
            <Col className="gutter-row flex-column" lg={6} span={24} style={{ display: 'flex' }}>
              <div className={amountWrapper(theme)}>
                <div className={amountDetails(theme)}>
                  <h5>{getText('amountToBePaid')}</h5>
                  <label className="amount">{printPrice({ fiat: selectedPack?.price ?? 0 })}</label>
                </div>
                {Number(currentAccount?.cashWalletBalance) - Number(selectedPack?.price) < 0 && (
                  <div className={amountDetails(theme)}>
                    <label className="insufficient-balance">{getText('notEnoughBalance')}</label>
                    <Button type="secondary" small>
                      {getText('topUpWallet')}
                    </Button>
                  </div>
                )}
              </div>
              <div className={cashWalletWrapper(theme)}>
                <p>{getText('yourCashWalletBalance')}</p>
                <label>{printPrice({ fiat: currentAccount.cashWalletBalance })}</label>
              </div>
              <Button
                type="secondary"
                small
                className={mt({ lg: 'auto' })}
                disabled={!selectedPack || Number(currentAccount?.cashWalletBalance) - Number(selectedPack?.price) < 0}
                onClick={showHideModal}
              >
                {getText('payNow')}
              </Button>
            </Col>
          </Row>
          <Modal
            visible={selectedPack && isModalVisible}
            onCancel={showHideModal}
            centered
            footer={
              <div className="flex flex-center">
                <Button type="link" small onClick={showHideModal}>
                  {getText('cancel')}
                </Button>
              </div>
            }
          >
            <div className={modalSummary(theme)}>
              <h6>{getText('packName', { name: selectedPack?.name })}</h6>
              <p>{printPrice({ fiat: selectedPack?.price })}</p>
              <Button type="secondary" small onClick={handleBuying}>
                {getText('confirmPurchase')}
              </Button>
            </div>
          </Modal>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default BuyCreditsPage;
