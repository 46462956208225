import { css } from 'emotion';

export const mapWrapper = css({
  height: '82vh',
  display: 'flex',
  marginTop: '1.5em',
  marginBottom: '.5em',
  padding: '0 2em',
  '& .leaflet-container': {
    flex: '4 1 0',
    isolation: 'isolate',
  },
});

export const merchantsListContainer = css({
  flex: '1.5 1 0',
  maxWidth: '30rem',
  overflowY: 'auto',
  padding: '1em 1em 0',
});

export const searchBarStyles = css({
  margin: '1.5em 0',
});
