import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import { Icon } from 'components/ui';
import { CheckButton } from '../Button';
import { tableHead, tableHeadCell, tableHeadIcon, tableCellCheckButton } from './styles';

const TableHead = props => {
  const { columns, getData, theme, hasSelection, selectRow, allSelected } = props;
  const [sort, setSort] = useState(null);

  const changeSort = column => () => {
    const { value } = column;
    const isDesc = sort === value;
    const newSort = isDesc ? `${value}Descending` : sort === `${value}Descending` ? null : value;
    setSort(newSort);
    getData({
      sort: newSort && { sortField: value, sortOrder: isDesc ? 'desc' : 'asc' },
    });
  };

  return (
    <div className={tableHead}>
      {hasSelection && (
        <div className={tableHeadCell({ flex: '0 0 40px', render: true }, theme)}>
          <CheckButton onChange={selectRow} checked={allSelected} className={tableCellCheckButton} />
        </div>
      )}
      {columns.map(column => {
        const { sortable, name, value } = column;
        return sortable ? (
          <div
            key={name}
            className={tableHeadCell(column, theme)}
            role="button"
            tabIndex="-1"
            onClick={changeSort(column)}
          >
            <p>{name}</p>

            <Icon
              material
              iconName="arrow_downward"
              className={tableHeadIcon(sort === value, sort === `${value}Descending`)}
            />
          </div>
        ) : (
          <div key={name} className={tableHeadCell(column, theme)}>
            <p>{name}</p>
          </div>
        );
      })}
    </div>
  );
};

TableHead.propTypes = {
  columns: PropTypes.array,
  getData: PropTypes.func,
  theme: PropTypes.object,
  hasSelection: PropTypes.bool,
  selectRow: PropTypes.func,
  allSelected: PropTypes.bool,
};

export default withTheme(TableHead);
