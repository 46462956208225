import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sub } from 'exact-math';
import { useTranslations } from '@veraio/strank';
import {
  useCurrencies,
  useLocations,
  printPrice,
  convertCurrencies,
  cryptoSign,
  hasCryptoAmountForCart,
} from '@oneecosystem/dealshaker-core';
import { useTheme } from 'emotion-theming';
import FeatherIcon from 'feather-icons-react';
import DiscountStamp from 'components/deal/DiscountStamp';
import { Button } from 'components/UIExternal';
import { DealPrice } from 'components/shared';
import { useUser, useShoppingCart } from 'stores';
import { deliveryMethodSelect, dealStatus } from 'enums';
import { addDealToWishlist, deleteDealFromWishlist } from 'services/api/wishlist';
import useError from 'services/errorHandling/useError';
import { formatDescriptiveDate, isFutureDate } from 'utils/valueFormatter';
import { mb } from 'assets/css/globalCSS';
import {
  cardContainer,
  deliveryInfoContainer,
  mainContainer,
  addWishlistBtn,
  availableOnLabel,
  addToCartButton,
} from './styles';

const PricePanel = ({ deal, onAddToShoppingCart, className }) => {
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const { getText } = useTranslations();
  const theme = useTheme();
  const geoLocation = useLocations(locationsState => locationsState.geoLocation);
  const { isAuthenticated, currentAccount } = useUser();
  const { setError } = useError();
  const { shoppingCart } = useShoppingCart();
  const [addedToWishlist, setAddedToWishlist] = useState(deal?.isInWishList);
  const [wishlistBtnDisabled, setWishlistBtnDisabled] = useState(!isAuthenticated);
  // const insufficientFundsError =
  //   isAuthenticated &&
  //   hasCryptoAmountForCart({
  //     shoppingCart,
  //     coinWalletBalance: currentAccount?.coinWalletBalance,
  //     oespWalletBalance: currentAccount?.oespWalletBalance,
  //     deal,
  //   });
  const insufficientFundsError = false;
  const alreadyAdded =
    deal?.maximumCouponsPerCustomer === 1 &&
    shoppingCart?.businesses?.some(business => business?.items.some(item => item.id === deal?.id));

  const handleDealWishList = async wishListRequest => {
    const [, err] = await wishListRequest(deal?.id);
    err ? setError(err) : setAddedToWishlist(prev => !prev);
    setWishlistBtnDisabled(false);
  };

  const manageWishlist = e => {
    e.preventDefault();
    setWishlistBtnDisabled(true);

    addedToWishlist ? handleDealWishList(deleteDealFromWishlist) : handleDealWishList(addDealToWishlist);
  };

  const handleRemindMeBtn = async () => {
    const [, err] = await addDealToWishlist(deal?.id);
    err ? setError(err) : setAddedToWishlist(!addedToWishlist);
  };

  const isDealSoldOutAnonymousUser = () => {
    if (!shoppingCart?.businesses?.length) return false;

    const matchedDeal = shoppingCart.businesses.flatMap(el => el.items).find(el => el.id === deal?.id);
    return matchedDeal && deal?.availableCoupons - matchedDeal?.quantity <= 0;
  };

  const wishListBtn = (
    <Button
      type="link"
      small
      disabled={wishlistBtnDisabled}
      onClick={e => manageWishlist(e)}
      className={addWishlistBtn(theme)}
    >
      {!addedToWishlist ? (
        <>
          <FeatherIcon icon="heart" size={17} stroke={theme.gray300} />
          {getText('addToWishlist')}
        </>
      ) : (
        <>
          <FeatherIcon icon="heart" size={17} stroke={theme.white} fill={theme.red} />
          {getText('addedToWishlist')}
        </>
      )}
    </Button>
  );

  const actionSection = () => {
    if (deal?.statusId === dealStatus.expired) {
      return (
        <>
          <p className="sold-out">{getText('expired')}</p>
          {wishListBtn}
        </>
      );
    }

    if (deal?.statusId === dealStatus.soldOut || (!isAuthenticated && isDealSoldOutAnonymousUser())) {
      return (
        <>
          <p className="sold-out">{getText('soldOut')}</p>
          {wishListBtn}
        </>
      );
    }

    if (isFutureDate(deal?.dealStartDate)) {
      return (
        <div className="flex-column">
          <div className={availableOnLabel(theme)}>
            <FeatherIcon icon="info" size={15} stroke={theme.primary_orange} />
            {getText('buyDealOn', { date: formatDescriptiveDate(deal?.dealStartDate) })}
          </div>
          <Button
            type="info"
            className={mb({ lg: 10, span: 10 })}
            disabled={wishlistBtnDisabled}
            onClick={handleRemindMeBtn}
          >
            {getText('remindMe')}
          </Button>
          {wishListBtn}
        </div>
      );
    }

    if (deal?.statusId !== dealStatus.closed) {
      return (
        <div className="flex-column">
          <Button
            type="primary"
            className={addToCartButton(theme)}
            onClick={onAddToShoppingCart}
            disabled={deal?.statusId === dealStatus.draft || !!insufficientFundsError || alreadyAdded}
          >
            {getText('addToCart')}
            {insufficientFundsError && <span className="insufficientFunds">({getText(insufficientFundsError)})</span>}
          </Button>
          {alreadyAdded && <p>{getText('onlyOneDealAvailable')}</p>}
          {wishListBtn}
        </div>
      );
    }
  };

  return (
    <div className={mainContainer}>
      <div className={cardContainer(theme)}>
        {!!deal?.discount && (
          <>
            <DiscountStamp deal={deal} position="top" className="price-panel-discount-stamp" />
            <div className="flex">
              <label className="discount-text">{getText('saveApproximatelyPrice', { price: '' })}&nbsp;</label>
              <label className="discount-text">
                {printPrice({
                  fiat: sub(deal?.price, deal?.discountedPrice ?? 0),
                  fiatOriginCurrency: deal?.currencyCode,
                })}
              </label>
            </div>
          </>
        )}
        <DealPrice deal={deal} className={className} dataId="details-deal-price-panel" />
        {!geoLocation?.isCryptoRestricted && (
          <div className="exchange-text">{`${printPrice({ crypto: 1 })} = ${printPrice({
            fiat: convertCurrencies({ crypto: 1 }).fiatBaseCrypto,
          })}`}</div>
        )}
        <span className="helper-text">{getText('fixedRateForDealShakerExclusively', { text: cryptoSign() })}</span>
        {actionSection()}
      </div>
      <div className={deliveryInfoContainer(theme)}>
        <p className="delivery-info">
          <span className="icon-Delivery-1 price-panel-icon" />
          {getText('deliveryInfo')}
        </p>
        <ul>
          <li>{getText(deliveryMethodSelect[deal?.deliveryMethodId]?.label)}</li>
        </ul>
        <p className="coupons-container">
          <span className="icon-Coupons price-panel-icon" />
          {getText('couponsAvailableSold', { available: deal?.availableCoupons, sold: deal?.soldCoupons })}
        </p>
      </div>
    </div>
  );
};

PricePanel.propTypes = {
  deal: PropTypes.object,
  onAddToShoppingCart: PropTypes.func,
  className: PropTypes.string,
};

export default PricePanel;
