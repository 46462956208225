import React, { useState, useCallback, useEffect } from 'react';
import { Upload } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import ImgCrop from 'antd-img-crop';
import DragableUploadListItem from './DragableUploadListItem';
import { imagesPreview } from '../styles';

const DragSortingUpload = ({ items, onPreview, onRemove, changeImagesOrder }) => {
  const [imagesList, setImagesList] = useState(items);

  useEffect(() => changeImagesOrder('images', imagesList), [imagesList]);
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = imagesList[dragIndex];
      dragRow &&
        setImagesList(
          update(imagesList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          }),
        );
    },
    [imagesList],
  );

  const onChange = ({ fileList: newFileList }) => {
    setImagesList(newFileList);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Upload
        fileList={items}
        onChange={onChange}
        onPreview={onPreview}
        className={imagesPreview}
        onRemove={onRemove}
        listType="picture-card"
        itemRender={(originNode, file, currFileList) => (
          <ImgCrop>
            <DragableUploadListItem originNode={originNode} file={file} fileList={currFileList} moveRow={moveRow} />
          </ImgCrop>
        )}
      />
    </DndProvider>
  );
};

DragSortingUpload.propTypes = {
  items: PropTypes.array,
  onPreview: PropTypes.any,
  onRemove: PropTypes.any,
  changeImagesOrder: PropTypes.func,
};

export default DragSortingUpload;
