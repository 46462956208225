import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const myAddressesTab = theme =>
  css({
    backgroundColor: theme.white,
  });

export const myAddressesStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '1em 0',
});

export const titleWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  [Desktop]: {
    alignItems: 'center',
  },
  [Mobile]: {
    gap: '1em',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    '& h5': {
      marginBottom: '0',
    },
  },
});

export const title = css({
  fontWeight: 700,
  fontSize: '1.4375rem',
  margin: '1.5625em 0px 1em 0px',
});

export const noAddressesContainer = css({
  marginTop: '2.5em',
  marginBottom: '5em',
  alignItems: 'center',
  [Mobile]: {
    marginTop: '5em',
  },
});

export const noAddressesText = theme =>
  css({
    color: theme.gray200,
    fontSize: '1.125rem',
    fontWeight: 700,
    [Mobile]: {
      marginTop: '1.25em',
    },
    [Desktop]: {
      marginLeft: '1.5em',
    },
  });

export const addressesList = css({
  marginTop: '1.875em',
  marginBottom: '5em',
  [Mobile]: {
    marginBottom: '2.5em',
    marginTop: '2em',
  },
});

export const addressBox = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1em',
    border: `2px solid ${theme.gray500}`,
    '& .hide': {
      display: 'none',
    },
    '& .show': {
      display: 'block',
    },
  });

export const addressTitle = theme =>
  css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.gray500}`,
    paddingBottom: '0.5em',
    color: theme.gray100,
    fontSize: '1.1875rem',
    fontWeight: 700,
    '& label': {
      textTransform: 'uppercase',
      color: theme.background100,
      background: theme.skyblue,
      fontSize: '0.6875rem',
      letterSpacing: 1,
      marginLeft: '0.625em',
      padding: '0.0625em 0.1875em',
    },
    '& button': {
      border: 'none',
      background: theme.white,
      marginLeft: 'auto',
    },
    '& button:hover': {
      background: theme.ui_hover,
    },
  });

export const popoverWrapper = theme =>
  css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '0px !important',
    backgroundColor: theme.white,
    borderRadius: '4px',
    '& .ant-popover-inner-content': {
      padding: 0,
    },
    '& button': {
      display: 'block',
      border: 'none',
      background: theme.white,
      padding: '0.5625em 1em',
      fontSize: '0.75rem',
    },
  });

export const infoTextWrapper = theme =>
  css({
    marginTop: '0.625em',
    marginBottom: 0,
    color: theme.gray100,
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
  });

export const instructionsParagraph = theme =>
  css({
    marginTop: '0.625em',
    marginBottom: 0,
    padding: '0.5em',
    fontSize: '0.75rem',
    borderRadius: '3px',
    background: theme.ui_hover_rgba,
    '& button': {
      border: 'none',
      background: theme.ui_hover_rgba,
      float: 'right',
      padding: '0.0625em 0.1875em',
    },
  });

export const inputInstructions = theme =>
  css({
    '& > div': {
      marginTop: '0.5em',
    },
    marginTop: 10,
    display: 'none',
    '& input': {
      width: '100%',
      border: `1px solid ${theme.gray500}`,
    },
    '& button': {
      marginTop: '0.3125em',
      padding: '0.0625em 0.625em',
      border: 'none',
      borderRadius: '2px',
      background: theme.skyblue,
      fontSize: '0.8125rem',
      fontWeight: 500,
      '&:hover': {
        background: theme.skyblue_hover,
      },
    },
  });

export const actionButtons = theme =>
  css({
    marginTop: '0.625em',
    display: 'flex',
    '& > div': {
      paddingLeft: '0',
    },
    '& button': {
      border: 'none',
      background: theme.white,
      color: theme.blue,
      textDecoration: 'underline',
      fontSize: '0.75rem',
      padding: 0,
      marginRight: '0.5em',
    },
    '& button:hover': {
      color: theme.blue2,
    },
  });

export const titleForm = theme =>
  css({
    fontWeight: 500,
    fontSize: '2.375rem',
    color: theme.background100,
    [Mobile]: {
      fontSize: '1.375rem',
    },
  });

export const addressFormWrapper = theme =>
  css({
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.375em 1.75em',
    },
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '0.8125rem !important',
      fontWeight: 500,
    },
    '& .ant-input::placeholder, .ant-select-selection-placeholder': {
      fontSize: '0.75rem',
    },
    '& .ant-form-item-label': {
      paddingBottom: '0 !important',
    },
  });

export const nameInput = css({
  marginBottom: '1.375em',
  width: '100%'
});

export const countryStateWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [Mobile]: {
    flexDirection: 'column',
  },
});

export const countryInput = css({
  width: '47%',
  '& .ant-select': {
    width: '100%',
  },
  [Mobile]: {
    width: '100%',
    marginBottom: '1.375em',
  },
});

export const stateInput = css({
  width: '47%',
  [Mobile]: {
    width: '100%',
  },
});

export const cityPostcodeWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

export const cityInput = css({
  width: '70%',
  flexDirection: 'column',
  '@media (max-width: 575px)': {
    flexDirection: 'row !important',
  },
});

export const postCodeInput = css({
  width: '24%',
  [Mobile]: {
    flexDirection: 'row !important',
  },
});

export const addressLineInput = css({
  marginBottom: '1.375em',
  width: '100%',
  [Mobile]: {
    flexDirection: 'column',
  },
});

export const phoneInput = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '1.375em',
  '& .ant-row.ant-form-item:first-child': {
    width: '24%',
    display: 'inline-block',
    marginBottom: 0,
  },
  '& .ant-row.ant-form-item:nth-child(2)': {
    width: '70%',
    display: 'inline-block',
    float: 'right',
    marginBottom: 0,
  },
  '& .phone-number': {
    width: '100%',
    '& .ant-input-number-handler-wrap': { display: 'none' },
    '& .ant-input-number-input': {
      height: '2rem',
      '&::placeholder': {
        fontSize: '0.75rem'
      },
    }
  }
});

export const contactPersonInput = css({
  width: '100%'
});

export const formButtons = css({
  marginTop: '1.4375em',
  marginBottom: '4.875em',
  display: 'flex !important',
  justifyContent: 'space-between',
  '& span': {
    margin: 'auto',
  },
  [Mobile]: {
    flexDirection: 'column-reverse',
    marginBottom: '3em',
  },
});

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });

export const phoneCodeStyles = css({
  lineHeight: '1.5715',
  height: 'auto',
  borderRadius: 4,
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const countryDropdownStyles = css({
  lineHeight: '1.5715',
  height: 'auto',
  borderRadius: 4,

  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
