import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Icon } from 'components/ui';
import { ReactComponent as EmptyIcon } from '../../../assets/images/empty-icon.svg';
import { noResultsContainer, noResultsTitle, noResultsIcon, noResultsSubtitle } from './styles';

export const NoResults = ({ emptyMessage }) => {
  const theme = useTheme();
  return (
    <section className={noResultsContainer(theme)}>
      <EmptyIcon className={noResultsIcon} />
      <p>{emptyMessage ?? 'No Data'}</p>
    </section>
  );
};

NoResults.propTypes = {
  emptyMessage: PropTypes.string,
};

export const Error = () => {
  const theme = useTheme();

  return (
    <section className={noResultsContainer(theme)}>
      <div className={noResultsIcon(theme)}>
        <Icon material iconName="report" size={48} color="white" />
      </div>
      <h2 className={noResultsTitle}>Request failure.</h2>
      <p className={noResultsSubtitle(theme)}>
        There was problem getting the results.
        <br />
        Please try again later.
      </p>
    </section>
  );
};
