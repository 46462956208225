import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { AutoComplete, Avatar, Col, Input, Modal, Popover, Row } from 'antd';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { isEmpty, debounce, isNil } from 'lodash-es';
import { BorderlessShadowedBox } from 'components/ui';
import { Button } from 'components/UIExternal';
import ListingDeal from 'components/deal/ListingDeal';
import { useUser } from 'stores';
import { Routes, businessStatus } from 'enums';
import { delay } from 'utils/queryUtils';
import {
  getChatMetadata,
  getChatModerator,
  getMetaDataForUserAndDeal,
  leaveChat,
  markChatAsArchived,
  renameChat,
  searchUsers,
  startChat,
} from 'services/api/chatService';
import useError from 'services/errorHandling/useError';
import { mt } from 'assets/css/globalCSS';
import Messages from './Messages';
import StartChat from '../StartChat/StartChat';
import { participantSelector, search, searchResultWrapper, blackTextLink, moreBtn, backButton } from './styles';

const OngoingChat = () => {
  const theme = useTheme();
  const history = useHistory();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [searchResult, setSearchResult] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');
  const [metaData, setMetadata] = useState({});
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [isRenameGroupModalOpen, setIsRenameGroupModalOpen] = useState(false);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
  const { dealId, chatId, userId } = queryString.parse(history.location.search);
  const currentUser = useUser()?.userInfo;
  const isGroupChat = Object.keys(metaData?.participants || {})?.length > 1;

  useEffect(() => {
    if (chatId?.length && currentUser) {
      getChatMetadata(chatId, setError).then(metaDataRes => {
        metaDataRes && setMetadata(metaDataRes);
        metaDataRes?.participants && setNewParticipants(Object.values(metaDataRes.participants));
      });
    }
  }, [chatId, currentUser]);

  useEffect(() => {
    participants.length &&
      !chatId &&
      getMetaDataForUserAndDeal(
        participants.map(participant => participant.id),
        dealId || null,
        setError,
      ).then(r => {
        if (r) setMetadata(r);
      });
  }, [participants, dealId, userId]);

  useEffect(() => {
    userId &&
      getMetaDataForUserAndDeal([userId], dealId || null, setError).then(r => {
        if (r) setParticipants(Object.values(r.participants));
      });
  }, [dealId, userId]);

  const onSearch = debounce(e => {
    e?.length > 2
      ? searchUsers(e, setError).then(
          res => res && setSearchResult(res.map(user => ({ label: user.username, value: user.id, ...user }))),
        )
      : setSearchResult('');
  }, 1500);

  const onSelect = participant => {
    if (!newParticipants.find(part => part.id === participant.id)) {
      const temp = [...newParticipants];
      temp.push(participant);
      setNewParticipants(temp);
    }
  };

  const toggleParticipantsModal = () => {
    setModalConfirmLoading(false);
    setIsParticipantsModalOpen(!isParticipantsModalOpen);
  };

  const toggleRenameModal = () => {
    setModalConfirmLoading(false);
    setIsRenameGroupModalOpen(!isRenameGroupModalOpen);
  };

  const onEditParticipants = async () => {
    setModalConfirmLoading(true);
    const [res, err] = await startChat(
      newParticipants.map(participant => participant.id),
      setError,
    );

    err && setError(err);

    setTimeout(() => {
      toggleParticipantsModal();
      history.push(`/chat?chatId=${res}`);
    }, 2000);
  };

  const onEditGroupName = () => {
    setModalConfirmLoading(true);
    renameChat(newGroupName, chatId, setError).then(() =>
      getChatMetadata(chatId, setError).then(metaDataRes => {
        toggleRenameModal();
        metaDataRes && setMetadata(metaDataRes);
      }),
    );
  };

  const mapParticipantsForExistingChat = () => (
    <BorderlessShadowedBox className={participantSelector(theme)} theme={theme}>
      <div className="flex popover">
        <Popover
          trigger="click"
          placement="bottom"
          content={
            <div className="flex-column">
              {!isGroupChat && (
                <Button type="link" small onClick={toggleRenameModal} className={blackTextLink(theme)}>
                  {getText('renameGroup')}
                </Button>
              )}
              <Button type="link" small onClick={toggleParticipantsModal} className={blackTextLink(theme)}>
                {getText('editParticipantList')}
              </Button>
              <Button
                type="link"
                small
                onClick={() => {
                  isGroupChat
                    ? leaveChat(chatId).then(() => history.push('/my-profile/messages'))
                    : markChatAsArchived(chatId).then(() => history.push('/my-profile/messages'));
                }}
                className={blackTextLink(theme)}
              >
                {isGroupChat ? getText('leaveGroup') : getText('markAsArchived')}
              </Button>
            </div>
          }
        >
          <Button type="link" small className={moreBtn(theme)}>
            <FeatherIcon icon="more-vertical" size={20} />
          </Button>
        </Popover>
      </div>
      <Avatar.Group maxCount={2} className="flex flex-center">
        {metaData?.participants &&
          Object.values(metaData.participants)?.map((participant, i) => (
            <Avatar key={i} src={participant.avatarThumbnailUrl}>
              {participant.firstName?.charAt(0)}
            </Avatar>
          ))}
      </Avatar.Group>
      <div className="flex flex-column flex-center">
        <p>{metaData?.name}</p>
        <label>{getText('started')}</label>
        <label>{moment(metaData?.createDate).format('DD/MM/YYYY · hh:mm')}</label>
      </div>
    </BorderlessShadowedBox>
  );

  const existingChatParticipantsSection = (
    <div className="flex-column">
      <Button type="link" className={backButton(theme)} linkTo={`${Routes.myProfile}${Routes.messages}`}>
        <FeatherIcon icon="arrow-left" size={20} />
        {getText('allMessages')}
      </Button>
      {!isNil(metaData.dealDetails) ? (
        <ListingDeal
          mainDealName={metaData.dealDetails.name}
          deal={metaData.dealDetails}
          isVertical
          isMerchant={metaData.isOwner}
          declinedBusiness={metaData.dealDetails.businessStatus === businessStatus.declined}
        />
      ) : (
        mapParticipantsForExistingChat()
      )}
      <Button
        type="link"
        small
        className={mt({ lg: 10 })}
        onClick={() =>
          delay(() => {
            getChatModerator(metaData?.dealDetails?.id || null, setError).then(
              r =>
                r &&
                history.push(
                  `/chat?userId=${r?.id}${metaData?.dealDetails ? `&dealId=${metaData.dealDetails.id}` : ''}`,
                ),
            );
          })
        }
        theme={theme}
      >
        {getText('orMessagemoderator')}
      </Button>
    </div>
  );

  const editParticipantsModalContent = (
    <>
      <AutoComplete
        style={{ width: '100%', marginBottom: 30, marginTop: 30 }}
        className={search}
        dropdownClassName={searchResultWrapper}
        notFoundContent={getText('noResultsFound')}
        options={searchResult}
        onSearch={onSearch}
        onSelect={(val, option) => onSelect(option)}
      />
      {newParticipants.map((participant, i) => (
        <div key={i} className="flex space-between" style={{ marginBottom: 15 }}>
          <div className="flex flex-center">
            <Avatar src={participant?.avatarThumbnailUrl}>{participant?.firstName?.charAt(0)}</Avatar>
            <label style={{ marginLeft: 10 }}>{`${participant?.firstName} ${participant?.lastName}`}</label>
          </div>
          <Button
            type="link"
            small
            onClick={() => setNewParticipants(newParticipants.filter(part => part?.id !== participant?.id))}
          >
            <FeatherIcon icon="trash" size={14} />
          </Button>
        </div>
      ))}
    </>
  );
  const renameGroupModalContent = (
    <Input
      style={{ marginTop: 30 }}
      onChange={e => setNewGroupName(e.target.value)}
      placeholder={getText('pleaseEnterGroupChatName')}
    />
  );
  return (
    <div className="content-container">
      <Row>
        {chatId ? (
          <>
            <Col lg={6} span={24}>
              {existingChatParticipantsSection}
            </Col>
            <Col lg={{ span: 17, offset: 1 }} span={24}>
              {!isEmpty(metaData) && <Messages metaData={metaData} chatId={chatId} dealId={dealId} />}
            </Col>
          </>
        ) : (
          <StartChat />
        )}
      </Row>
      <Modal
        onCancel={toggleParticipantsModal}
        confirmLoading={modalConfirmLoading}
        onOk={onEditParticipants}
        visible={isParticipantsModalOpen}
        okButtonProps={{ disabled: !newParticipants.length }}
      >
        {editParticipantsModalContent}
      </Modal>
      <Modal
        onCancel={toggleRenameModal}
        confirmLoading={modalConfirmLoading}
        onOk={onEditGroupName}
        visible={isRenameGroupModalOpen}
        okButtonProps={{ disabled: !newGroupName.length }}
      >
        {renameGroupModalContent}
      </Modal>
    </div>
  );
};

export default OngoingChat;
