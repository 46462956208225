import { getReq, postReq, putReq, deleteReq, merchantListParams, merchantOverviewResponse } from 'services';
import { merchantListCityFilterBody, getMerchantListCityFilterParams } from 'services/models';
import envConfig from 'config/envConfig';

const baseUrl = `${envConfig.apiAddress}/api/Businesses`;

export const getAllBusinesses = params => getReq(`${baseUrl}/My`, params);

export const getAllBusinessesInfo = params => getReq(`${baseUrl}/ListInfo`, params);

export const getSingleBusiness = businessId => getReq(`${baseUrl}/Get/${businessId}`);

export const getBusinessOverview = async businessId => {
  const [res, err] = await getReq(`${baseUrl}/Overview`, { businessId });
  return [res && merchantOverviewResponse(res), err];
};

export const getBusinessProfile = businessId => getReq(`${baseUrl}/Profile/${businessId}`);

export const addNewBusiness = payload => postReq(`${baseUrl}/Create`, payload);

export const deleteBusiness = businessId => deleteReq(`${baseUrl}/Delete/${businessId}`);

export const updateBusiness = payload => putReq(`${baseUrl}/Update`, payload);

export const resetBusinessBin = id => putReq(`${baseUrl}/ResetBin/${id}`);

export const getAllNearByBusinesses = params => getReq(`${baseUrl}/Nearest`, params);

export const getMerchantList = options => getReq(`${baseUrl}/List?${merchantListParams(options)}`);

export const getCitiesByCountry = async options => {
  const [res, err] = await getReq(`${baseUrl}/BusinessCitiesByCountry?${getMerchantListCityFilterParams(options)}`);
  return [res && merchantListCityFilterBody(res), err];
};
