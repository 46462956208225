import apiRoutes from 'config/apiRoutes';
import { getReq, postReq } from 'services/axios/makeRequest';

export const getAvailableCredits = async setError => {
  const result = await getReq(apiRoutes.CREDITS_AVAILABLE);
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const getCreditPacks = async setError => {
  const result = await getReq(apiRoutes.CREDITS_PACKS_LIST);
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const buyCredits = async (packId, setError) => {
  const result = await postReq(apiRoutes.CREDITS_BUY, { packId });
  result[1] && setError && setError(result[1]);
  return result[2];
};
