import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { useAddresses } from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { CheckoutDeliveryMethod, CheckoutBusiness, ShoppingCartItem } from 'components/screens';
import { AddressesSmallList } from 'components/shared';
import { Routes } from 'enums';
import { actionButtons } from '../styles';
import { deliveryLabel } from './styles';

const CheckoutDelivery = props => {
  const { shoppingCart, onSubmit, onBack, goNext, onResetAddress } = props;
  const userAddresses = useAddresses();
  const { businesses } = shoppingCart;
  const isPickUpAtLocation = businesses?.every(el => el.items.every(item => item?.selectedBusinessAddressId));
  const isDeliveryAtAddress = businesses?.every(el => el.items.every(item => item?.selectedUserAddressId));
  const allHasAddress = businesses?.every(el =>
    el.items.every(item => item?.selectedUserAddressId || item?.selectedBusinessAddressId),
  );
  const firstUserAddressId = userAddresses?.at(0)?.id;

  useEffect(() => {
    !isPickUpAtLocation && !isDeliveryAtAddress
      ? firstUserAddressId && handleUpdateUserAddress(firstUserAddressId)
      : handleSubmit();
  }, []);

  const handleSubmit = async (newBusinesses = businesses) => {
    const shoppingCartNewDelivery = await onSubmit(newBusinesses);
    const cannotDeliverItems = shoppingCartNewDelivery?.businesses
      ?.flatMap(business => business?.items)
      ?.some(item => !item?.canBeDelivered);

    if (cannotDeliverItems) {
      setTimeout(() => {
        const scrollError = document.getElementById('unable-to-deliver');
        window.scrollTo({ top: scrollError.offsetTop - 300, behavior: 'smooth' });
      });
    }
  };

  const handleUpdateUserAddress = selectedUserAddressId =>
    handleSubmit(
      businesses?.map(business => ({
        ...business,
        items: business?.items?.map(item => ({ ...item, selectedUserAddressId, selectedBusinessAddressId: null })),
      })),
    );

  const handleUpdateBusinessAddress = businessId => selectedBusinessAddressId =>
    handleSubmit(
      businesses?.map(business =>
        business?.businessId !== businessId
          ? business
          : {
              ...business,
              items: business?.items?.map(item => ({
                ...item,
                selectedUserAddressId: null,
                selectedBusinessAddressId,
              })),
            },
      ),
    );

  const switchToPickUpAtLocation = () =>
    handleSubmit(
      businesses?.map(business => ({
        ...business,
        items: business?.items?.map(item => ({
          ...item,
          selectedUserAddressId: null,
          selectedBusinessAddressId: business?.businessAddresses.at(0)?.id,
        })),
      })),
    );

  return (
    <>
      <CheckoutDeliveryMethod
        label={getText('deliveryAtAddress')}
        checked={!isPickUpAtLocation}
        onChange={() => (firstUserAddressId ? handleUpdateUserAddress(firstUserAddressId) : onResetAddress())}
      >
        {businesses?.map(business => (
          <CheckoutBusiness key={business?.businessId} business={business}>
            {business?.items?.map(item => (
              <ShoppingCartItem key={item.dealId} item={item} onUnableToDelivery={handleSubmit} />
            ))}
          </CheckoutBusiness>
        ))}

        <h4 className={deliveryLabel}>{getText('myAddresses')}</h4>
        <AddressesSmallList
          active={businesses.at(0)?.items?.at(0)?.selectedUserAddressId}
          addresses={userAddresses}
          addLink={`${Routes.addDeliveryAddress}?redirectUrl=${Routes.shoppingCartCheckoutDelivery}`}
          onChange={handleUpdateUserAddress}
        />
      </CheckoutDeliveryMethod>

      <CheckoutDeliveryMethod
        label={getText('pickUpAtLocation')}
        checked={isPickUpAtLocation}
        onChange={switchToPickUpAtLocation}
      >
        {businesses?.map(business => (
          <CheckoutBusiness key={business?.businessId} business={business}>
            {business.items?.map(item => (
              <ShoppingCartItem item={item} key={item.dealId} />
            ))}
            <h4 className={deliveryLabel}>{getText('merchantAddresses')}</h4>
            <AddressesSmallList
              active={business?.items?.at(0)?.selectedBusinessAddressId}
              addresses={business?.businessAddresses}
              onChange={handleUpdateBusinessAddress(business.businessId)}
            />
          </CheckoutBusiness>
        ))}
      </CheckoutDeliveryMethod>

      <div className={actionButtons}>
        <Button type="default" small onClick={onBack}>
          {getText('back')}
        </Button>
        <Button small onClick={goNext} disabled={!allHasAddress}>
          {getText('next')}
        </Button>
      </div>
    </>
  );
};

CheckoutDelivery.propTypes = {
  shoppingCart: PropTypes.object,
  onSubmit: PropTypes.func,
  goNext: PropTypes.func,
  onBack: PropTypes.func,
  onRemoveDeal: PropTypes.func,
  history: PropTypes.object,
  onResetAddress: PropTypes.func,
};

export default CheckoutDelivery;
