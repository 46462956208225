import { css } from 'emotion';
import { commonStyles } from '../../ui/CommonStyles';

const textColorMap = theme => ({
  primary: theme.gray100,
  secondary: theme.white,
  default: theme.gray100,
  danger: theme.white,
  success: theme.white,
  warning: theme.gray100,
  link: theme.blue2,
});

const backgroundColorMap = theme => ({
  primary: theme.primary_yellow,
  secondary: theme.primary_blue,
  default: theme.white,
  danger: theme.red,
  success: theme.green2,
  warning: theme.warning,
  info: theme.skyblue,
  link: 'transparent',
});

const borderColorMap = theme => ({
  default: theme.gray500,
  link: 'transparent',
});

const hoverBackgroundColorMap = theme => ({
  primary: theme.primary_hover_btn,
  secondary: theme.primary_blue_hover,
  default: theme.default_hover,
  danger: theme.red_dark,
  success: theme.green,
  warning: theme.warningLight,
  info: theme.skyblue_hover,
  link: 'transparent',
});

const hoverBorderColorMap = theme => ({
  default: theme.gray300,
  link: 'transparent',
});

export const buttonContainer = (props, isLoading) => theme => {
  const {
    type = 'primary',
    disabled,
    color,
    borderColor,
    backColor,
    hoverBackColor,
    hoverBorderColor,
    fullWidth,
    small,
    className,
  } = props;

  const backgroundColor = theme[backColor] ?? backgroundColorMap(theme)[type] ?? theme.primary_yellow;
  const hoverBackgroundColor = theme[hoverBackColor] ?? hoverBackgroundColorMap(theme)[type] ?? theme.primary_hover_btn;
  const textColor = theme[color] ?? textColorMap(theme)[type] ?? theme.gray100;
  const borderColorDefault = theme[borderColor] ?? borderColorMap(theme)[type] ?? backgroundColor;
  const borderColorHover = theme[hoverBorderColor] ?? hoverBorderColorMap(theme)[type] ?? hoverBackgroundColor;
  const isLink = type === 'link';

  return css([
    {
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: small ? '0.5em 0.8em' : '1em 1.25em',
      width: fullWidth ? '100%' : 'auto',
      minHeight: small ? '1.875rem' : '2.5rem',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: textColor,
      borderRadius: isLink ? 0 : 6,
      lineHeight: 1,
      fontWeight: isLink ? 400 : 600,
      border: `1px solid ${borderColorDefault}`,
      opacity: isLoading || disabled ? 0.6 : 1,
      backgroundColor,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      transition:
        'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',

      '&:hover': {
        borderColor: borderColorHover,
        backgroundColor: hoverBackgroundColor,
      },

      '& > i': { marginRight: '0.375em' },
    },
    (disabled || isLoading) && {
      '& *': { pointerEvents: 'none' },
    },
    className,
    ...commonStyles(props),
  ]);
};

export const spinnerBtn = css({
  '&.ant-spin': {
    position: 'relative',
    '& .ant-spin-dot': {
      position: 'absolute',
      left: '0.3125em',
      top: '-0.5em',
      bottom: 0,
    },
  },
});

export const checkButtonContainer = (isDisabled, className) =>
  css(
    {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: isDisabled ? 'default' : 'pointer',
    },
    className,
  );

export const iconContainer = css({
  marginRight: '0.4em',
});
