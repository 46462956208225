import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import { Row, Col, Input, Select, Form, Spin, TimePicker, InputNumber, Checkbox } from 'antd';
import { useTranslations } from '@veraio/strank';
import { pick } from '@veraio/core';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Button, useDeepEffect, Dropdown } from 'components/UIExternal';
import { CountryDropdown } from 'components/ui';
import { InputsWithTranslations } from 'components/shared';
import BusinessStatus from 'enums/BusinessStatus';
import useError from 'services/errorHandling/useError';
import { formatTime } from 'utils/valueFormatter';
import { getCountryPhoneCodeOptions } from 'utils';
import { getAllBusinessesInfo } from 'services/api/businessesService';
import { addNewBusinessAddress, getSingleBusinessAddress, updateBusinessAddress } from 'services/api/businessAddress';
import { mb } from 'assets/css/globalCSS';
import { inlineInputsContainer } from 'styles';
import {
  titleForm,
  subtitle,
  addressFormWrapper,
  formSectionWrapper,
  merchantProfileTags,
  languagesFormWrapper,
  mobileLabel,
  dayTimeWrapper,
  dayTimeInput,
  mandatoryStar,
  formButtons,
  blackTextLink,
  countryDropdownStyles,
} from './addressesStyles';

const BusinessAddressForm = ({ isInModal, toggleIsModalVisible, setStepData }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation, language } = useTranslations();
  const countries = useLocations(locationsState => locationsState.countries);
  const { addressId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { setError } = useError();
  const { Option } = Select;
  const [form] = Form.useForm();

  const [businesses, setBusinesses] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  const isInDealWizard = location.pathname.includes('/edit-deal');
  const translationsSectionRef = useRef();
  const phoneCodeRef = useRef(address?.phoneNumber?.split(' ')[0]);
  const phoneCodes = getCountryPhoneCodeOptions(countries);

  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  useEffect(() => {
    language?.code && !address && fetchSingleBusinessAddress();
  }, [language?.code]);

  useDeepEffect(() => {
    fetchAllBusinessesInfo();
  }, [language]);

  useEffect(() => {
    handleSetSelectedTags(businesses, address?.businessNames);
  }, [businesses, address]);

  const fetchAllBusinessesInfo = async () => {
    const [res, err] = await getAllBusinessesInfo({
      statusIds: [BusinessStatus.Approved.id, BusinessStatus.Edited.id, BusinessStatus.Pending.id],
    });
    err && setError(err);

    const data = res?.map(x => {
      const label = getDynamicTranslation(x.name)?.name;
      return { id: x.id, label, value: label };
    });
    setBusinesses(data);
  };

  const fetchSingleBusinessAddress = async () => {
    if (location.pathname === '/add-business-address' || isInDealWizard) return setAddress({});

    const [res, err] = await getSingleBusinessAddress(addressId);
    err && setError(err);
    setAddress(res);
  };

  const handleSetSelectedTags = (businesses, businessNames) =>
    setSelectedTags(businesses?.filter(el => businessNames?.some(x => el.value === x.name)));

  const submitForm = async values => {
    const payload = {
      ...pick(values, ['street', 'city', 'state', 'contactPerson', 'postcode', 'countryId', 'businessAddressDetails']),
      businessIds: businesses
        ?.filter(business => selectedTags.some(selected => business.value === selected.value))
        .flatMap(el => el.id),
      phoneNumber: `${values.phoneCode.value} ${values.phone}`,
      workingDays: values.fromDay && values.toDay ? `${values.fromDay} – ${values.toDay}` : null,
      workingHours:
        values.fromTime && values.toTime ? `${formatTime(values.fromTime)} – ${formatTime(values.toTime)}` : null,
    };

    // Wait for validation of all translation tabs and see if there is error or not
    // If there is error quit from this method
    const validateTranslationError = translationsSectionRef.current?.validate();
    if (validateTranslationError || !payload?.businessIds?.length) return;

    if (location.pathname === '/add-business-address' || isInDealWizard) {
      setLoading(true);
      const [, err] = await addNewBusinessAddress(payload);
      err && setError(err);
      setLoading(false);

      if (isInDealWizard) {
        toggleIsModalVisible();
        setStepData(4);
        toast.success(getText('addressAddedSuccessfully'));
      } else history.push('/merchant-office/address-book');
    } else {
      const [, err] = await updateBusinessAddress({ ...payload, id: addressId });
      if (err) return setError(err);

      history.push('/merchant-office/address-book');
    }
  };

  const onChange = val => {
    setSelectedTags(businesses?.filter(x => val.includes(x.value)));
  };

  const handleCountryChange = countryId => {
    const foundCountryData = countries?.find(country => country.id === countryId);
    const foundPhoneCode = phoneCodes?.find(code => code.value === foundCountryData.phoneCode);

    phoneCodeRef.current.changeValue(foundPhoneCode);
    form.setFieldsValue({ phoneCode: foundPhoneCode.value });
  };

  return (
    <div className="content-container">
      <h2 className={titleForm(theme)}>
        {location.pathname === '/add-business-address' || isInDealWizard
          ? getText('addNewAddress')
          : getText('editAddress')}
      </h2>
      {location.pathname === '/add-business-address' && (
        <p className={subtitle(theme)}>{getText('pleaseAddYourBusinessAddress')}</p>
      )}
      {address && countries && businesses && phoneCodes ? (
        <>
          <Row>
            <Col lg={isInModal ? 24 : 12} span={24} className={addressFormWrapper(theme)}>
              <Form
                form={form}
                onFinish={submitForm}
                layout="vertical"
                initialValues={{
                  businessAddressDetails: address.businessAddressDetails,
                  countryId: countries?.find(country => country.name === address.country)?.id,
                  state: address.state,
                  city: address.city,
                  postcode: address.postcode,
                  street: address.street,
                  phoneCode: phoneCodes?.find(
                    code => Number(code.value) === Number(address.phoneNumber?.split(' ')[0]),
                  ),
                  phone: address.phoneNumber?.substring(address.phoneNumber.indexOf(' ') + 1),
                  contactPerson: address.contactPerson,
                  fromDay: address.workingDays?.split(' – ')[0],
                  toDay: address.workingDays?.split(' – ')[1],
                  fromTime: address.workingHours && moment(address.workingHours.split(' – ')[0], 'HH:mm'),
                  toTime: address.workingHours && moment(address.workingHours.split(' – ')[1], 'HH:mm'),
                }}
              >
                <div className={formSectionWrapper(theme)}>
                  <Form.Item
                    name="businessAddressDetails"
                    className={languagesFormWrapper}
                    data-id="businessAddressDetails"
                  >
                    <InputsWithTranslations
                      ref={translationsSectionRef}
                      formInstance={form}
                      inputs={[
                        {
                          inputComponent: Input,
                          inputProps: { placeholder: getText('enterAddressName') },
                          formItemProps: { name: 'name', label: getText('name') },
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={getText('assignMerchantAccounts')}
                    name="businessIds"
                    valuePropName="checked"
                    className={merchantProfileTags(theme)}
                    rules={!selectedTags?.length && [mandatoryRule]}
                    data-id="merchantProfiles"
                  >
                    <Checkbox.Group
                      defaultValue={address?.businessNames?.flatMap(el => el.name)}
                      options={businesses}
                      onChange={onChange}
                    />
                  </Form.Item>
                </div>
                <div className={formSectionWrapper(theme)}>
                  <p className={`mobile-only ${mobileLabel}`}>{getText('BusinessAddress')}</p>
                  <div className={inlineInputsContainer}>
                    <Form.Item label={getText('country')} name="countryId" rules={[mandatoryRule]} data-id="countryId">
                      <CountryDropdown
                        noClear
                        placeholder={getText('selectCountry')}
                        className={countryDropdownStyles}
                        onChange={handleCountryChange}
                      />
                    </Form.Item>
                    <Form.Item label={getText('stateProvince')} name="state" data-id="country">
                      <Input type="text" placeholder={getText('selectProvince')} />
                    </Form.Item>
                  </div>
                  <div className={inlineInputsContainer}>
                    <Form.Item
                      label={getText('city')}
                      name="city"
                      data-id="city"
                      rules={[
                        mandatoryRule,
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || !/\d/.test(getFieldValue('city'))) return Promise.resolve();

                            return Promise.reject(new Error(getText('enterValidCity')));
                          },
                        }),
                      ]}
                    >
                      <Input type="text" placeholder={getText('enterCity')} />
                    </Form.Item>
                    <Form.Item label={getText('postcode')} name="postcode" className="small-input" data-id="postcode">
                      <Input type="text" placeholder={getText('postcode')} />
                    </Form.Item>
                  </div>
                  <div className={inlineInputsContainer}>
                    <Form.Item
                      label={getText('addressLine')}
                      name="street"
                      data-id="street"
                      rules={[
                        mandatoryRule,
                        {
                          max: 100,
                          message: getText('FieldShouldNotExceedSymbolsCount', { count: 100 }),
                        },
                      ]}
                    >
                      <Input type="text" placeholder={getText('merchantAddress')} />
                    </Form.Item>
                  </div>
                  <label>
                    <span className={mandatoryStar}>*</span>
                    {getText('phoneNumber')}
                  </label>
                  <div className={inlineInputsContainer}>
                    <Form.Item name="phoneCode" rules={[mandatoryRule]} className="small-input" data-id="phoneCode">
                      <Dropdown
                        withSearch
                        noClear
                        ref={phoneCodeRef}
                        placeholder={getText('phoneCode')}
                        uniqueKey="value"
                        displayKey="label"
                        options={phoneCodes}
                        renderSelected={selected => selected && `+ ${selected.value}`}
                      />
                    </Form.Item>
                    <Form.Item name="phone" rules={[mandatoryRule]} data-id="phone">
                      <InputNumber className="phone-number" placeholder={getText('enterPhoneNumber')} />
                    </Form.Item>
                  </div>
                  <div className={inlineInputsContainer}>
                    <Form.Item label={getText('contactPerson')} name="contactPerson" data-id="contactPerson">
                      <Input type="text" placeholder={getText('enterFirstLastName')} />
                    </Form.Item>
                  </div>
                </div>
                <div className={formSectionWrapper(theme)}>
                  <div className={dayTimeWrapper}>
                    <Form.Item label={getText('workingFrom')} name="fromDay" className={dayTimeInput} data-id="fromDay">
                      <Select
                        placeholder={getText('day')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {weekDays.map(day => (
                          <Option value={day} key={day}>
                            {day}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label={getText('openFrom')} name="fromTime" className={dayTimeInput} data-id="fromTime">
                      <TimePicker
                        format="HH:mm"
                        onSelect={value => {
                          form.setFieldsValue({
                            fromTime: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className={dayTimeWrapper}>
                    <Form.Item label={getText('to')} name="toDay" className={dayTimeInput} data-id="toDay">
                      <Select
                        placeholder={getText('Day')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {weekDays.map(day => (
                          <Option value={day} key={day}>
                            {day}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label={getText('until')} name="toTime" className={dayTimeInput} data-id="toTime">
                      <TimePicker
                        format="HH:mm"
                        onSelect={value => {
                          form.setFieldsValue({
                            toTime: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg={isInModal ? 24 : 12} span={24} className={formButtons(isInModal)}>
              {!isInModal && (
                <Button type="link" small className={blackTextLink(theme)} onClick={() => history.goBack()}>
                  {getText('goBack')}
                </Button>
              )}
              <Button
                type="primary"
                small
                loading={loading}
                className="confirm-btn"
                data-id="confirm-btn"
                onClick={() => form.submit()}
              >
                {location.pathname === '/add-business-address' || isInDealWizard
                  ? getText('addAddress')
                  : getText('editAddress')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};
BusinessAddressForm.propTypes = {
  isInModal: PropTypes.bool,
  toggleIsModalVisible: PropTypes.bool,
  setStepData: PropTypes.func,
};
export default BusinessAddressForm;
